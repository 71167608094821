import { modelSlice } from "./slice";

export const {

  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setErrorMessage,
  setPage,
  
} = modelSlice.actions;
