import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

import {
  addUploadFileArrayStoreFactoryReducer,
  uploadFileArrayStoreFactory,
} from "../_stores/uploadFileArrayStore";
import { ExamsAPI } from "../../apis/ExamsAPI";

export const uploadFileArrayThunk = uploadFileArrayStoreFactory(
  "upload-exam/upload",
  ExamsAPI.uploadFile,
  "uploadExam",
);

export const uploadExamSlice = createSlice({
  name: "uploadExam",
  initialState: initialState,
  reducers: {
    setModel: (state: any, action: PayloadAction<any>) => {
      state.push({
        file: action.payload,
        status: "CARREGANDO",
        name: "Prova " + parseInt(state.length + 1),
        pct: 0,
      });
    },
    setPercent: (state: any, action: PayloadAction<any>) => {
      console.log(action.payload)
      state[action.payload.ref]["pct"] = action.payload.pct;
    },
  },
  extraReducers: (builder) => {
    addUploadFileArrayStoreFactoryReducer(builder, uploadFileArrayThunk);
  },
});
