import { useEffect } from "react";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { fetchList } from "../../../services/stores/teacherDemandStore/async/fetchListThunk";
import { List } from "../../../component/list/list";
import {
  setSearch,
  setPage,
} from "../../../services/stores/teacherDemandStore/actions";


export function TeacherDemandsView() {
  const demands = useAppSelector((store) => store.teacherDemands.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.teacherDemands.screen
  );
  const { pagination } = useAppSelector((store) => store.teacherDemands);
  const { search } = useAppSelector((store) => store.teacherDemands.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchList({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        hideEdit
        hideDelete
        search_input_value={search}
        search_text="Pesquise pelo nome da demanda"
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchList({ force: true }));
        }}
        title={"Demandas do Professor"}
        not_found_message={"Nenhuma demanda encontrada"}

        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchList({ force: true }));
        }}
        onManagerActionClick={(id) => {
          navigate("/teacher-demands-manager/" + id);
        }}
        pronoun="a"
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={demands}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "description", type: "text", header: "Descrição" },
          {
            name: "end_datetime",
            type: "date",
            header: "Data de encerramento",
          },
        ]}
      ></List>
    </div>
  );
}
