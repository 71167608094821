import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";

export const save = createAsyncThunk(
  "demand-manager/save",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await AssignmentsAPI.save(token, state.demandsManager.model);
    } catch (e: any) {
      console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addSaveReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(save.pending, (state, _action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: true,
        },
      };
    })
    .addCase(save.fulfilled, (state, action) => {
      var all = [...state.list.all];
      all.push(action.payload.data);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: false,
        },
        list: { ...state.list, all: all },
      };
    })
    .addCase(save.rejected, (state: any, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorMessages: action.payload?.message,
          loadingSave: false,
        },
      };
    });
};