import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Class } from "../../../model/Class";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Class,
};
