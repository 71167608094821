import { useEffect } from "react";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setSearch,
  setWarning,
  setSelectedId,
} from "../../../services/stores/organizationStore/actions";
import {
  fetchListThunk,
  deleteThunk,
} from "../../../services/stores/organizationStore/slice";

export function OrganizationView() {
  const organizations = useAppSelector((store) => store.organizations.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.organizations.screen
  );
  const { pagination } = useAppSelector((store) => store.organizations);
  const { search } = useAppSelector((store) => store.organizations.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        hideManager
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Organizações"}
        not_found_message={"Nenhuma organização encontrada"}
        button_text="Adicionar organização"
        button_link="/organizations/create"
        search_text="Pesquise pelo nome da organização"
        deleting_message={
          "Ao deletar esta organização, ela não estará mais disponível no sistema"
        }
        onSearchButtonClick={() => {
          dispatch(fetchListThunk({ force: true }));
        }}
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/organizations/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={organizations}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "description", type: "text", header: "Descrição" },
        ]}
      ></List>
    </div>
  );
}
