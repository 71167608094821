import { useEffect } from "react";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { save } from "../../../services/stores/studentExamsStore/async/saveThunk";
import {
  Col,
  Container,
  Form,
  FormControl,
  FormControl as Input,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { update } from "../../../services/stores/studentExamsStore/async/updateThunk";
import { fetchId } from "../../../services/stores/studentExamsStore/async/fetchIdThunk";
import {
  clearWindowState,
  setErrorMessage,
} from "../../../services/stores/studentExamsStore/actions";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Attention } from "../../../component/base/Attention";
import DefaultButton from "../../../component/base/DefaultButton";
import { STORAGE_URL } from "../../../constants/consts";
import {
  setComment,
  setScore,
  setReply,
} from "../../../services/stores/correctionStore/actions";

export function StudentExamManager(props: { editMode?: boolean }) {
  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.studentExams.screen
  );
  const { model } = useAppSelector((store) => store.studentExams);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    if (props.editMode) {
      dispatch(update()).then((result) => {
        if (result.type.includes("fulfilled")) history("/answers");
      });
    } else {
      dispatch(save()).then((result) => {
        if (result.type.includes("fulfilled")) history("/answers");
      });
    }
  };

  useEffect(() => {
    dispatch(fetchId({ id: params.id, sid: params.sid, force: true }));
    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {model.description}
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Visualize a prova
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen == false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error, id) => (
                        <p key={id} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                {model.answers &&
                  model.answers.map((answer: any, i: any) => (
                    <Form key={i} style={{ position: "relative" }}>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            <h2>Questão {i + 1}</h2>
                          </Form.Label>
                          <div
                            style={{ fontSize: 12, color: "black" }}
                            dangerouslySetInnerHTML={{
                              __html: answer.question,
                            }}
                          ></div>
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>Resposta</Form.Label>
                          {answer.type == "text" && (
                            <Row>
                              <img
                                src={
                                  STORAGE_URL + "/" + answer.dissertative_answer
                                }
                              ></img>
                            </Row>
                          )}
                          {answer.type == "objective" &&
                            answer.alternatives.map(
                              (alternative: any, i: any) => (
                                <Container>
                                  <Row
                                    style={{
                                      color: alternative.correct
                                        ? "green"
                                        : alternative._id ==
                                          answer?.objective_answer?._id
                                        ? "red"
                                        : "black",
                                    }}
                                  >
                                    <Col md="auto">
                                      {String.fromCharCode(i + 97) + ")"}
                                    </Col>
                                    <Col width="auto">
                                      <div
                                        color="darkorchid"
                                        dangerouslySetInnerHTML={{
                                          __html: alternative.alternative,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Container>
                              )
                            )}
                          {answer.type == "objective" && (
                            <Row>
                              <Col>
                                {answer?.objective_answer && (
                                  <p>
                                    O aluno respondeu {" "}
                                    {String.fromCharCode(
                                      65 +
                                        answer.alternatives.findIndex(
                                          (x: any) => {
                                            console.log(x);
                                            console.log(
                                              answer?.objective_answer
                                            );
                                            return (
                                              x._id ==
                                              answer?.objective_answer._id
                                            );
                                          }
                                        )
                                    )}
                                    <div
                                      color="darkorchid"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          answer?.objective_answer?.alternative,
                                      }}
                                    />
                                  </p>
                                )}
                                {(!answer?.objective_answer ||
                                  answer?.objective_answer?.alternative
                                    ?.length == 0) && (
                                  <p>O aluno deixou a questão em branco.</p>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>Justificativa</Form.Label>
                          <Input
                            disabled={true}
                            as="textarea"
                            placeholder="Comente a resposta do aluno"
                            value={answer.justify_answer_text}
                            onChange={(e) =>
                              dispatch(setComment(e.target.value))
                            }
                          />
                        </div>
                      </InputGroup>

                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>Comentário</Form.Label>
                          <Input
                            disabled
                            as="textarea"
                            placeholder="Comente a resposta do aluno"
                            value={answer?.corrections[0]?.comment}
                            onChange={(e) =>
                              dispatch(setComment(e.target.value))
                            }
                          />
                        </div>
                      </InputGroup>
                      {answer.type == "text" && (
                        <InputGroup>
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Nota (0-100)</Form.Label>
                            <FormControl
                              disabled
                              pattern="[0-9]*"
                              placeholder="Nota"
                              type="number"
                              value={answer?.corrections[0]?.score}
                              onChange={(e) =>
                                dispatch(setScore(e.target.value))
                              }
                              // required
                              // title="Coloque o nome completo do usuário"
                            />
                          </div>
                        </InputGroup>
                      )}
                      {answer.corrections && answer?.corrections[0]?.claim && (
                        <div>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Questionamento do Aluno</Form.Label>
                              <FormControl
                                as="textarea"
                                disabled
                                value={answer?.corrections[0]?.claim}
                                // required
                                // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Resposta do Professor</Form.Label>
                              <FormControl
                                as="textarea"
                                disabled={true}
                                value={answer.correction.reply}
                                // required
                                // title="Coloque o nome completo do usuário"
                                onChange={(e) =>
                                  dispatch(setReply(e.target.value))
                                }
                              />
                            </div>
                          </InputGroup>
                        </div>
                      )}

                      {loadingSave == true && (
                        <div className="row">
                          {" "}
                          <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          </div>
                        </div>
                      )}
                    </Form>
                  ))}
              </div>
            )}
            <DefaultButton
              bg="secondary"
              text="Voltar"
              icon={<RiArrowGoBackLine />}
              onClick={() => {
                history(-1);
                return undefined;
              }}
            />
            {loadingScreen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
