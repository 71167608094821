import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { DisciplinesAPI } from "../../../apis/DisciplinesAPI";
import { handleThunkRejection } from "../../utils";

export const fetchDisciplines = createAsyncThunk(
  "demand-manager/fetch-disciplines",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    if (
      !params.force &&
      state.demandsManager.disciplines != null &&
      state.demandsManager.disciplines.length > 0
    )
      return state.demandsManager.disciplines;
    try {
      return await DisciplinesAPI.getAll(
        token,
        1,
        params.search ?? null,
        false
      );
    } catch (e: any) {
      console.log(
        `Error fetchDisciplines::createAsyncThunk ${e.status} ${e.message}`
      );

      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchDisciplines = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(
      fetchDisciplines.pending,
      (state: { loading_screen: boolean }, _action: any) => {
        state.loading_screen = true;
      }
    )
    .addCase(fetchDisciplines.fulfilled, (state, action) => {
      state.disciplines = action.payload;
    })
    .addCase(fetchDisciplines.rejected, (state: any, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
