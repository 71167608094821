import { useEffect } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Form,
  FormControl as Input,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/demandManagerStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setErrorMessage,
  clearState,
  setState,
  setId,
} from "../../../services/stores/testStore/actions";
import { Searchbar } from "../../../component/list/searchbar";
import { FaUsers } from "react-icons/fa";
import { fetchCourses } from "../../../services/stores/layoutExamStore/async/fetchCourseThunk";
import { fetchUnits } from "../../../services/stores/layoutExamStore/async/fetchUnitThunk";
import { fetchStudent } from "../../../services/stores/testStore/async/fetchStudentThunk";
import { Actions } from "../../../component/list/actions";
import { updateClasses } from "../../../services/stores/testStore/async/updateClassesThunk";
import { fetchClassListThunk } from "../../../services/stores/userStudentStore/slice";
import { fetchIdRegistration } from "../../../services/stores/testStore/async/fetchRegistrationsIdThunk";

var debounce = require("lodash.debounce");

export function ExamApplicationManagerForm(props: { editMode?: boolean }) {
  const {
    error_message,
    loading_save,
    search_form,
    loading_screen,
    loading_student,
    students,
    course_text,
    unit_text,
    unit,
    classSelected,
    course,
    class_text,
  } = useAppSelector((store:any) => store.tests);
  
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    dispatch(updateClasses()).then((result: any) => {
      if (result?.payload?.status === "success") {
        history(`/tests/view/${params.id}`);
      }
    });
  };

  const searchClasses = (query: any, callback: any) => {
    dispatch(fetchClassListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchUnits = (query: any, callback: any) => {
    dispatch(fetchUnits({ force: true, search: query })).then((result) =>
      callback(result.payload.docs)
    );
  };

  const searchCourse = (query: any, callback: any) => {
    dispatch(fetchCourses({ force: true, search: query })).then((result) =>
      callback(result.payload.docs)
    );
  };

  useEffect(() => {
    dispatch(setId(params.id));
    dispatch(fetchIdRegistration({ id: params.id, force: true }));
    

    return () => {
      dispatch(clearState());
    };
  }, [dispatch, props, params]);
  console.log(students);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {"Adicionar/Remover"} Alunos{" "}
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {"Cadastre/Edite os alunos da aplicação"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen === false && (
              <div>
                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          dispatch(setErrorMessage(null));
                          return undefined;
                        }}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Turma
                        <Attention />
                      </Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome da turma..."
                        noOptionsMessage={
                          class_text.length == 0
                            ? () => "Digite parte do nome da turma..."
                            : () => "Nenhuma opção encontrada"
                        }
                        onChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ classSelected: e }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ class_text: e }));
                          return undefined;
                        }}
                        cacheOptions
                        value={classSelected}
                        loadOptions={debounce(searchClasses, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup>
                  {/* <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Unidade <Attention /></Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome da unidade..."
                        noOptionsMessage={unit_text.length == 0 ? (() => "Digite parte do nome da unidade...") : (() => "Nenhuma opção encontrada")}
                        onChange={(e: any) => {
                          console.log(e)
                          dispatch(setState({ unit: e }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ unit_text: e }));
                          return undefined
                        }}
                        cacheOptions
                        value={unit}
                        loadOptions={debounce(searchUnits, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Curso/Grau <Attention /></Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome do curso/grau..."
                        noOptionsMessage={course_text.length == 0 ? (() => "Digite parte do nome do curso/grau...") : (() => "Nenhuma opção encontrada")}
                        onChange={(e: any) => {
                          console.log(e)
                          dispatch(setState({ course: e }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ course_text: e }));
                          return undefined
                        }}
                        cacheOptions
                        value={course}
                        loadOptions={debounce(searchCourse, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup> */}
                  <div className="w100 inline-flex justify-content-end margin-top-1">
                    {loading_student == false && (
                      <Button
                        onClick={(e: any) => {
                          e.preventDefault();
                          let class_: any = { ...classSelected };
                          console.log(class_);
                          if (class_?._id != undefined) {
                            dispatch(fetchStudent({ class_id: class_?._id }));
                          }

                          return undefined;
                        }}
                        variant="success"
                        className="flex align-center jcc"
                      >
                        <FaUsers style={{ marginRight: 10 }} />{" "}
                        <p style={{ margin: 0 }}>
                          {"Adicionar alunos da turma"}
                        </p>
                      </Button>
                    )}
                    {loading_student === true && (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    )}
                  </div>

                  <Form.Label>
                    Alunos <Attention />
                  </Form.Label>
                  <div className="row">
                    <div className="col-12">
                      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
                      <Searchbar
                        search_input_value={search_form}
                        search_text={"Encontre um aluno"}
                        onChangeSearchInput={function (value: any): void {
                          dispatch(setState({ search_form: value }));
                        }}
                        onSearchButtonClick={() => {
                          //   props.onSearchButtonClick();
                        }}
                      ></Searchbar>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Unidade</th>
                                <th>Curso</th>
                                <th>Período</th>
                                {/* <th>Ações</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(students)
                                .filter((item: any, id: any) =>
                                  item.student.name
                                    .toLowerCase()
                                    .includes(search_form.toLowerCase())
                                )
                                ?.map((item: any, id: any) => (
                                  <tr key={id}>
                                    <td>{item.student?.name}</td>
                                    <td>{item.student?.email}</td>
                                    <td>{item.class?.unit?.name}</td>
                                    <td>{item.class?.course?.name ?? "-"}</td>
                                    <td>{item.class?.period ?? "-"}</td>
                                    <td>
                                      <Actions
                                        actionItem={item}
                                        onDeleteClick={() => {
                                          let students_: any = { ...students };
                                          delete students_[item._id];
                                          dispatch(
                                            setState({ students: students_ })
                                          );
                                        }}
                                        hideManager={true}
                                        hideEdit={true}
                                        hideDelete={true}
                                      ></Actions>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {Object.values(students).filter((item: any, id: any) =>
                        item.student.name
                          .toLowerCase()
                          .includes(search_form.toLowerCase())
                      )?.length == 0 && (
                        <div className="row w-100 mt-3">
                          <div className="col-12">
                            <p style={{ textAlign: "center" }}>
                              Nenhum aluno vinculado/encontrado
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {loading_save === false && (
                    <div className="w100 inline-flex jcsb mt-2">
                      <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={`/tests/view/${params.id}`}
                      />

                      <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar alunos"
                        disabled={loading_save}
                        loadingtext="Salvando alunos"
                        loading={loading_save}
                      />
                    </div>
                  )}

                  {loading_save === true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center mt-2">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen === true && (
              <div className="row mt-2">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
