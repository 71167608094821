import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";

export const removeCorrector = createAsyncThunk(
  "layouts/remove-correctors",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return LayoutsExamsAPI.removeCorrector(token, params.id, null, false, {
        corrector: state.layouts.corrector_id,
      });
    } catch (e: any) {
      console.log(
        `Error fetchParent::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addRemoveCorrector = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(removeCorrector.pending, (state: any, _action: any) => {
    })
    .addCase(removeCorrector.fulfilled, (state, action) => {
      state.show_corrector_warning = false;
    })
    .addCase(removeCorrector.rejected, (state, action) => {
      state.show_corrector_warning = false;
    });
};
