import { useEffect, useState } from "react";

// unidades
import { fetchList } from "../../../services/stores/layoutExamStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setId,
  setWarning,
  setSearch,
  setLoadingScreen,
} from "../../../services/stores/layoutExamStore/actions";
import { deleteItem } from "../../../services/stores/layoutExamStore/async/deleteThunk";
import { fetchDefault } from "../../../services/stores/layoutExamStore/async/fetchDefaultIdThunk";
import { fetchDefaultList } from "../../../services/stores/layoutExamStore/async/fetchDefaultListThunk";
import { UserRole } from "../../../constants/enum/UserRole";

export function LayoutsExamsDefaultView() {
  const layouts = useAppSelector((store) => store.layouts.data);
  const { search, loading_screen } = useAppSelector(
    (store) => store.layouts
  );
  const { me } = useAppSelector((store) => store.auth);

  const show_warning = useAppSelector(
    (store) => store.layouts.show_warning
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [loading_screen, setLoadingScreen] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);
  console.log("layouts", layouts);
  useEffect(() => {
    dispatch(setLoadingScreen(true));
    dispatch(fetchDefaultList({ force: true })).then((result) => {
      console.log("result", result);
    });
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Padrões de diagramações"}
        pronoun='a'
        not_found_message={"Nenhum padrão encontrada"}
        button_text={!(me.role === UserRole.SUPER_USER ||
          me.role === UserRole.ADMIN_USER ||
          me.role === UserRole.COLABORATOR_USER)?undefined: "Adicionar padrão"}
        button_link="/layouts_exams/create"
        search_text="Pesquise pelo nome do padrão de diagramação"
        deleting_message={
          'Ao deletar desmarcar essa diagramação como padrão, ela aparecerá mais nessa lista'
        }
        hideManager
        hideDelete={!(me.role === UserRole.SUPER_USER ||
          me.role === UserRole.ADMIN_USER ||
          me.role === UserRole.COLABORATOR_USER)}
        hideEdit={!(me.role === UserRole.SUPER_USER ||
            me.role === UserRole.ADMIN_USER ||
            me.role === UserRole.COLABORATOR_USER)}
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onSearchButtonClick={() => {
        dispatch(setLoadingScreen(true));
          dispatch(fetchDefaultList({ force: true }));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem({default_layout:true}));
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/layouts_exams/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        onManagerActionClick={(id)=>{
          navigate("/layouts_exams/create/" + id);
        }}
        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={layouts}
        pagination={{ last_page: last_page, current_page: current_page }}
        fields={[
          { name: "name_default", type: "text", header: "Nome" },
        { name: "owner.name", type: "text", header: "Cadastrado por" },
        { name: "created_at", type: "date", header: "Criado em" },

      ]}
      ></List>
    </div>
  );
}
