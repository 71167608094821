import { useEffect } from "react";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { Button, Card, Form, FormControl, InputGroup } from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import DefaultButton from "../../../component/base/DefaultButton";
import {
  fetchClassListThunk,
  saveThunk,
  saveAndRegisterThunk,
} from "../../../services/stores/userStudentStore/slice";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setName,
  setEmail,
  setPassword,
  setConfirmPassword,
  setOrganization,
  setErrorMessage,
  clearWindowState,
  setClass,
  setAddClass,
} from "../../../services/stores/userStudentStore/actions";
import { updateThunk } from "../../../services/stores/userStudentStore/slice";
import { fetchIdThunk } from "../../../services/stores/userStudentStore/slice";
import PasswordInput from "../../../component/base/PasswordInput";
import { UserRole } from "../../../constants/enum/UserRole";
import { fetchOrganizationListThunk } from "../../../services/stores/userStudentStore/slice";
import { RiArrowGoBackLine } from "react-icons/ri";
import { SaveRounded } from "@material-ui/icons";
var debounce = require("lodash.debounce");

export function UsersStudentForm(props: { editMode?: boolean }) {
  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.userStudent.screen);
  const { name, email, password, password_confirmation } = useAppSelector(
    (store) => store.userStudent.model
  );

  const role = useAppSelector((store) => store.auth.me.role);

  const selectedOrganization = useAppSelector(
    (store) => store.userStudent.model.organization
  );
  const selectedClass = useAppSelector(
    (store) => store.userStudent.model.class
  );
  const addClass = useAppSelector((store) => store.userStudent.model.add_class);

  const dispatch = useAppDispatch();
  const history = useNavigate();
  const params = useParams();

  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type === "user-student/update/fulfilled")
          history("/users-student");
      });
    } else {
      if (addClass) {
        dispatch(saveAndRegisterThunk()).then((result) => {
          if (result.type === "user-student/save-class/fulfilled")
            history("/users-student");
        });
      } else {
        dispatch(saveThunk()).then((result) => {
          if (result.type === "user-student/save/fulfilled")
            history("/users-student");
        });
      }
    }
  };

  const searchOrganizations = (query: any, callback: any) => {
    dispatch(fetchOrganizationListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchClasses = (query: any, callback: any) => {
    dispatch(fetchClassListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true })).then(
        (result: any) => {
          dispatch(setOrganization(result.payload.organization));
        }
      );
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Aluno
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite um aluno do seu sistema"
                : "Cadastre os alunos do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <InputGroup className="col-lg">
                      <PasswordInput
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={password}
                        OnChangeText={(e: any) =>
                          dispatch(setPassword(e.target.value))
                        }
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <PasswordInput
                        label="Confirmação de senha"
                        placeholder="Digite a confirmação de senha"
                        className="flex1"
                        value={password_confirmation}
                        OnChangeText={(e: any) =>
                          dispatch(setConfirmPassword(e.target.value))
                        }

                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                  </div>

                  {!props.editMode && (
                    <Form.Check>
                      <Form.Check.Input
                        checked={addClass}
                        onClick={() => dispatch(setAddClass(null))}
                        id="addClass"
                        type="checkbox"
                      />
                      <Form.Check.Label
                        onClick={() => dispatch(setAddClass(null))}
                      >
                        Fazer inscrição do aluno em uma turma
                      </Form.Check.Label>
                    </Form.Check>
                  )}

                  {!props.editMode && addClass && (
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>Inscrição do Aluno</Card.Title>
                        <Card.Subtitle className="mb-4 text-muted">
                          Escolha a unidade o curso e o período do aluno.
                        </Card.Subtitle>
                        {role === UserRole.SUPER_USER && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Organização
                                <Attention />
                              </Form.Label>
                              <AsyncSelect
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option._id}
                                placeholder="Digite parte do nome do aluno..."
                                onChange={(e: any) => {
                                  dispatch(setOrganization(e));
                                }}
                                cacheOptions
                                value={selectedOrganization}
                                loadOptions={debounce(searchOrganizations, 250)}
                              />
                            </div>
                          </InputGroup>
                        )}

                        <InputGroup>
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>
                              Classe
                              <Attention />
                            </Form.Label>
                            <AsyncSelect
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option._id}
                              placeholder="Digite parte da classe do aluno..."
                              onChange={(e: any) => {
                                dispatch(setClass(e));
                              }}
                              cacheOptions
                              value={selectedClass}
                              loadOptions={debounce(searchClasses, 250)}
                            />
                          </div>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  )}
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users-student"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/users-student")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <DefaultButton
                          type="submit"
                          bg="confirm"
                          text="Salvar"
                          disabled={loadingImage == true}
                          loadingtext="Salvando"
                          loading={loadingSave}
                        /> */}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
