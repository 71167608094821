import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { DemandsAPI } from "../../../apis/DemandsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";

export const fetchList = createAsyncThunk(
  "assignments/list",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return AssignmentsAPI.getAll(
        token,
        state.assignments.pagination.page,
        state.assignments.list.search ?? "",
        `demand=${params.id}`,
        false
      );
    } catch (e: any) {
      console.log(
        `Error fetchParent::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchListReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchList.pending, (state: any, _action: any) => {
      state.screen.loadingScreen = true;
    })
    .addCase(fetchList.fulfilled, (state, action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
        list: {
          ...state.list,
          all: action.payload.docs,
        },
      };
    })
    .addCase(fetchList.rejected, (state, action:any) => {
      return {
        ...state,
        list: {
          ...state.list,
          all: [],
        },
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessage: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
