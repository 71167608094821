import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../services/stores/questionStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../component/base/DefaultButton";
import { save } from "../../services/stores/disciplineStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setName,
  setErrorMessage,
  clearState,
  setId,
} from "../../services/stores/disciplineStore/actions";
import { update } from "../../services/stores/disciplineStore/async/updateThunk";
import { fetchId } from "../../services/stores/disciplineStore/async/fetchIdThunk";

export function DisciplinesCreate() {
  const { error_message, name, loading_save, loading_screen } = useAppSelector(
    (store) => store.disciplines
  );
  const dispatch = useAppDispatch();

  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);

  const [loading_search, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    console.log("entrou");
    if (edit == true) {
      dispatch(update()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/disciplines");
        }
      });
    } else {
      dispatch(save()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/disciplines");
        }
      });
    }
  };

  useEffect(() => {
    // const discipline_id = history.pa
    if (params.id != undefined) {
      dispatch(setId(params.id));
      setEdit(true);
      dispatch(fetchId({ force: true }));
    }

    return () => {
      dispatch(clearState());
    };
  }, []);
  console.log(edit);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {edit == true ? "Editar" : "Cadastrar"} Disciplina
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {edit == false
                ? "Cadastre as disciplinas do seu sistema"
                : "Edite uma disciplina do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da disciplina"
                        value={name}
                        onChange={(e) => dispatch(setName(e.target.value))}
                        required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  {loading_save == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/disciplines"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/disciplines")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar disciplina"
                        disabled={loading_save}
                        loadingtext="Salvando disciplina"
                        loading={loading_save}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loading_save}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
