import { useEffect } from "react";

// cursos
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setSearch,
  setWarning,
  setSelectedId,
} from "../../../services/stores/courseStore/actions";
import {
  fetchListThunk,
  deleteThunk,
} from "../../../services/stores/courseStore/slice";

export function CourseView() {
  const courses = useAppSelector((store) => store.courses.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.courses.screen
  );
  const { pagination } = useAppSelector((store) => store.courses);
  const { search } = useAppSelector((store) => store.courses.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        onManagerActionClick={(value) => {
          navigate(`/courses/${value}/turmas`, {
            state: { filter: value , key: "course_id" },
          });
        }}
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Cursos"}
        not_found_message={"Nenhum curso encontrada"}
        button_text="Adicionar curso"
        button_link="/courses/create"
        search_text="Pesquise pelo nome do curso"
        deleting_message={
          "Ao deletar este curso, ele não estará mais disponível no sistema"
        }
        onSearchButtonClick={() => {
          dispatch(fetchListThunk({ force: true }));
        }}
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/courses/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={courses}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "description", type: "text", header: "Descrição" },
        ]}
      ></List>
    </div>
  );
}
