// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";

const API_PATH = API_URI + "/tests";

export const TestsAPI = {
  getAll: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getFiles: async function (
    token = "",
    id: any,
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/files/${id}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getJobs: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/jobs/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdRegistration: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/registrations/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdAsOther: async function (token = "", id: any, cancel = false, sid:any) {
    return api
      .request({
        url: `${API_PATH}/student-as-other/${sid}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdAsStudent: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/student/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  saveZip: async function (
    token: string,
    data: any,
    callback: any,
    cancel = false
  ) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}/save_zip`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
        },
        onUploadProgress: (e) => {
          callback(e);
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  updateClasses: async function (
    token: string,
    _id: any,
    data: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/classes/${_id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  updateExams: async function (
    token: string,
    _id: any,
    data: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/exams/${_id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  deleteClass: async function (
    token = "",
    id: any,
    class_id: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/classes/${id}/${class_id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
