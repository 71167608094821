import { Demand } from "./Demand";
import { Discipline } from "./Discipline";
import { User } from "./User";

export const Assignment = {
  demand: Demand,
  organization_id: "",
  discipline: Discipline,
  teacher: User,
  description: "",
  multiple_choice_questions_qty: "",
  essay_questions_qty: "",  
};
