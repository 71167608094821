import { createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";
import { UsersAPI } from "../../apis/UsersAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import {
  addFetchListFieldStoreFactoryReducer,
  fetchListFieldStoreFactory,
} from "../_stores/fetchListFieldStore";
import {
  addUploadFileStoreFactoryReducer,
  uploadFileStoreFactory,
} from "../_stores/uploadFileStore";

import { OrganizationsAPI } from "../../apis/OrganizationsAPI";
import { ClassesAPI } from "../../apis/ClassesAPI";

const preSaveValidate = function (model: any) {
  if (model.password === model.password_confirmation) return { error: false };
  return {
    error: true,
    e: {
      errors: [
        {
          code: "ValidationError",
          data: null,
          message: "Senha não confere",
        },
      ],
    },
  };
};


export const fetchOrganizationListThunk = fetchListFieldStoreFactory(
  "user-student/list-organizations",
  OrganizationsAPI.getAll,
  "userStudent",
  "organizations"
);
export const fetchClassListThunk = fetchListFieldStoreFactory(
  "user-student/list-classes",
  ClassesAPI.getAll,
  "userStudent",
  "classes"
);

export const uploadFileThunk = uploadFileStoreFactory(
  "user-student/upload",
  UsersAPI.uploadFile,
  "userStudent"
);

export const fetchListThunk = fetchListStoreFactory(
  "user-student/list",
  UsersAPI.getAllStudent,
  "userStudent"
);

export const fetchIdThunk = fetchIdStoreFactory(
  "user-student/get",
  UsersAPI.getById,
  "userStudent"
);
export const deleteThunk = deleteStoreFactory(
  "user-student/delete",
  UsersAPI.delete,
  "userStudent"
);
export const saveThunk = saveStoreFactory(
  "user-student/save",
  UsersAPI.save,
  "userStudent",
  preSaveValidate
);
export const updateThunk = updateStoreFactory(
  "user-student/update",
  UsersAPI.update,
  "userStudent",
  preSaveValidate
);

export const saveAndRegisterThunk = saveStoreFactory(
  "user-student/save-class",
  UsersAPI.saveAndRegister,
  "userStudent",
  preSaveValidate
);

export const modelSlice = createSlice({
  name: "user-student",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setEmail: (state, action) => {
      return { ...state, model: { ...state.model, email: action.payload } };
    },
    setPassword: (state, action) => {
      return { ...state, model: { ...state.model, password: action.payload } };
    },
    setConfirmPassword: (state, action) => {
      return {
        ...state,
        model: { ...state.model, password_confirmation: action.payload },
      };
    },
    setOrganization: (state, action) => {
      return {
        ...state,
        model: { ...state.model, organization: action.payload },
      };
    },
    setPeriod: (state, action) => {
      return {
        ...state,
        model: {
          ...state.model,
          class: {
            ...state.model.class,
            period: action.payload,
          },
        },
      };
    },
    setClass: (state, action) => {
      return {
        ...state,
        model: {
          ...state.model,
          class: action.payload,
        },
      };
    },
    setCourse: (state, action) => {
      return {
        ...state,
        model: {
          ...state.model,
          class: {
            ...state.model.class,
            course: action.payload,
          },
        },
      };
    },
    setAddClass: (state, _action) => {
      return {
        ...state,
        model: {
          ...state.model,
          add_class: !state.model.add_class,
        },
      };
    },
    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_state) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchOrganizationListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchClassListThunk);
    addSaveStoreFactoryReducer(builder, saveAndRegisterThunk);
    addUploadFileStoreFactoryReducer(builder, uploadFileThunk);
  },
});
