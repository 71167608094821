import { useEffect } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { Form, FormControl as Input, InputGroup } from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/demandManagerStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setMultipleChoiceQuestionsQty,
  setDescription,
  setEssayQuestionsQty,
  setErrorMessage,
  clearWindowState,
  setSelectedId,
  setDemandId,
  setDiscipline,
  setTeacher,
  setDisciplineText,
  setTeacherText
} from "../../../services/stores/demandManagerStore/actions";
import { update } from "../../../services/stores/demandManagerStore/async/updateThunk";
import { fetchId } from "../../../services/stores/demandManagerStore/async/fetchIdThunk";
import { fetchDisciplines } from "../../../services/stores/demandManagerStore/async/fetchDisciplinesThunk";
import { fetchTeachers } from "../../../services/stores/demandManagerStore/async/fetchTeachersThunk";

var debounce = require("lodash.debounce");

export function DemandsManagerForm(props: { editMode?: boolean }) {
  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.demandsManager.screen
  );
  const selectedDiscipline = useAppSelector(
    (store) => store.demandsManager.model.discipline
  );
  const selectedTeacher = useAppSelector(
    (store) => store.demandsManager.model.teacher
  );
  const { disciplineText, teacherText } = useAppSelector(
    (store) => store.demandsManager
  );
  const {
    demand,
    multiple_choice_questions_qty,
    essay_questions_qty,
    description,
  } = useAppSelector((store) => store.demandsManager.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    if (props.editMode) {
      dispatch(update()).then((result: any) => {
        if (result?.payload?.status === "success") {
          history(`/demands/${demand._id}`);
        }
      });
    } else {
      dispatch(save()).then((result: any) => {
        if (result?.payload?.status === "success") {
          history(`/demands/${params.id}`);
        }
      });
    }
  };

  const searchDisciplines = (query: any, callback: any) => {
    dispatch(fetchDisciplines({ force: true, search: query })).then((result) =>
      callback(result.payload)
    );
  };

  const searchTeachers = (query: any, callback: any) => {
    dispatch(fetchTeachers({ force: true, search: query })).then((result) =>
      callback(result.payload.docs)
    );
  };

  useEffect(() => {
    dispatch(fetchTeachers({ force: false }));
    if (props.editMode) {
      dispatch(fetchId({ id:params.id, force: true })).then((result: any) => {
        if (result.type === "demand-manager/fetch-id/fulfilled") {
          dispatch(setTeacher(result.payload.teacher));
          dispatch(setDiscipline(result.payload.discipline));
        }
      });
    } else {
      dispatch(setDemandId(params.id));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, [dispatch, props, params]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Atribuição{" "}
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma atribuição do seu sistema"
                : "Cadastre as atribuições do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen === false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição <Attention/></Form.Label>
                      <Input
                        placeholder="Descrição da atribuição"
                        value={description}
                        onChange={(e) =>
                          dispatch(setDescription(e.target.value))
                        }
                        as="textarea"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Disciplina <Attention/> </Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome da disciplina..."
                        onChange={(e: any) => {
                          dispatch(setDiscipline(e));
                        }}
                        onInputChange={(e:any)=>{
                          console.log(e);
                          dispatch(setDisciplineText(e));
                          return undefined
                        }}
                        noOptionsMessage={disciplineText.length==0? (() => "Digite parte do nome da disciplina..."): (() => "Nenhuma opção encontrada")}
                        cacheOptions
                        value={selectedDiscipline}
                        loadOptions={debounce(searchDisciplines, 250)}
                        defaultOptions={
                          props.editMode ? [selectedDiscipline] : []
                        }
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Professor <Attention/></Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome do professor..."
                        noOptionsMessage={teacherText.length==0? (() => "Digite parte do nome do professor..."): (() => "Nenhuma opção encontrada")}
                        onChange={(e: any) => {
                          console.log(e)
                          dispatch(setTeacher(e));
                        }}
                        onInputChange={(e:any)=>{
                          console.log(e);
                          dispatch(setTeacherText(e));
                          return undefined
                        }}
                        cacheOptions
                        value={selectedTeacher}
                        loadOptions={debounce(searchTeachers, 250)}
                        defaultOptions={props.editMode ? [selectedTeacher] : []}
                      />
                    </div>
                  </InputGroup>

                  {/* <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Professor</Form.Label>
                      <select
                        className="form-select "
                        onChange={(e) => {
                          dispatch(setTeacherId(e.target.value));
                        }}
                        value={teacher}
                      >
                        <option value={""}>{"Selecione um professor"}</option>

                        {teachers.map((item: any, id: number) => (
                          <option key={id} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputGroup> */}
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Quantidade de questões de múltipla escolha
                        <Attention />
                      </Form.Label>
                      <Input
                        type="number"
                        placeholder="Digite a quantidade de questões de múltipla escolha"
                        value={multiple_choice_questions_qty}
                        onChange={(e) =>
                          dispatch(
                            setMultipleChoiceQuestionsQty(e.target.value)
                          )
                        }
                        required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Quantidade de questões dissertativas <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Digite a quantidade de questões dissertativas"
                        type="number"
                        value={essay_questions_qty}
                        onChange={(e) =>
                          dispatch(setEssayQuestionsQty(e.target.value))
                        }
                        required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  {loadingSave === false && (
                    <div className="w100 inline-flex jcsb margin-top-1">
                      <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={`/demands/${
                          props.editMode ? demand._id : params.id
                        }`}
                      />

                      <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar atribuição"
                        disabled={loadingSave}
                        loadingtext="Salvando atribuição"
                        loading={loadingSave}
                      />
                    </div>
                  )}

                  {loadingSave === true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen === true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
