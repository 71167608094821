import { useEffect, useRef, useState } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../../../component/base/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { TextReadMore } from "../../../component/base/TextReadMore";
import moment from "moment";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../../../component/base/TabPanel";
import { Grid } from "../../../component/list/grid";
import { fetchList } from "../../../services/stores/demandManagerStore/async/fetchListThunk";
import { Alert } from "../../../component/list/alert";
import { fetchParent } from "../../../services/stores/demandManagerStore/async/fetchParent";
import { fetchId } from "../../../services/stores/examStore/async/fetchIdThunk";
import { clearState, setId, setPage, setState } from "../../../services/stores/examStore/actions";
import { Button } from "react-bootstrap";
import { MdFileDownload } from "react-icons/md";
import { STORAGE_URL } from "../../../constants/consts";
import ModalAddZip from "./ModalAddZip";
import { fetchFilesId } from "../../../services/stores/examStore/async/fetchFilesThunk";

export function ExamManagerView() {
  const model = useAppSelector((store) => store.exams.model);
  const downloads: any = [];

  const { loading_screen, search_files, loading_files, files, pagination, showModal,questions } = useAppSelector(
    (store) => store.exams
  );
  const { showWarning } = useAppSelector(
    (store) => store.layouts
  );
  console.log(loading_screen)
  const timeout_job = useRef<any>()

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const history: any = useNavigate();
  const params = useParams();

  const checkStatus = (status:any)=>{
    if(!status){
      status='PENDING';
    }
    if(status=='PENDING'){
      return '<p style="color:darkgoldenrod">Na fila de execução</p>'
    }
    if(status=='EXECUTING'){
      return '<p style="color:darkgoldenrod">Executando</p>'
    }
    if(status=='FINISH'){
      return '<p style="color:green">Finalizado</p>'
    }
    if(status=='ERROR'){
      return '<p style="color:red">Erro</p>'
    }
  }

  const getDownload = (job_id = '') => {

  }
  useEffect(() => {
    if (showModal == false) {
      dispatch(fetchFilesId({ id: params.id, force: true, page: 1 }));
    }
  }, [showModal]);

  useEffect(() => {
    dispatch(setId(params.id))

    dispatch(fetchId({ id: params.id, force: true })).then((data:any) => {
      console.log('AQUIIIIIII')
      console.log(data)
      dispatch(fetchFilesId({ id: params.id, force: true, page: 1 }));

    });

    return () => {
      clearTimeout(timeout_job.current)
      dispatch(clearState());

    }
  }, []);
  console.log(model);
  console.log(files)
  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body">
          <Alert
            title="Atenção"
            text={
              "Ao desvincular este aluno, ele não terá mais acesso a esta prova"
            }
            show_warning={showWarning}
            onCancel={() => dispatch(setState({ showWarning: false }))}
            onConfirm={() => console.log('teste')}
          ></Alert>

          <div>
            {model.application_id && <div style={{ float: "right" }}>
              <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to={`/tests/view/`+model.application_id+``}
                search="?tab=2"
              />
            </div>}
            <div className="flex jcsb">
              <h4 className="header">{model.name}</h4>
            </div>



            {model.description && <TextReadMore
              title="Descrição: "
              element="div"
              limit={120}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {model.description ?? ''}
            </TextReadMore>}
            {<TextReadMore
              title="Número de questões: "
              element="div"
              limit={120}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {Object.values(questions).map((item: any, id: any) => {
                                    if (item.type == 'set') {
                                        return item.questions.length;
                                    }
                                    else {
                                        return 1;
                                    }
                                }).reduce((a, b) => a + b, 0)+ ''}
            </TextReadMore>}
            <a id="download_link" target="_blank"></a>
            <br />
            <ModalAddZip />
            <Tabs
              value={activeTab}
              onChange={(_: any, value: any) => {
                setActiveTab(value);
                // history.push({ search: `?tab=${value}` });
              }}
              style={{ backgroundColor: "#ececf1" }}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
            >
              <Tab label={model.type=='objective'? "Questões Objetivas":"Questões Dissertativas"} />

              {/* <Tab label="Atribuições de Redações" /> */}
            </Tabs>
            <TabPanel value={activeTab} index={0}>

              <Grid
                onChangeSearchInput={(value: any) => {
                  console.log('value', value)
                  dispatch(setState({ search_files: value }));
                }}

                search_input_value={search_files}
                loading_search={false}
                fields={[
                  {
                    name: "filename",
                    type: "text",
                    header: "Nome",
                  },
                  {
                    name: "layout_name",
                    type: "text",
                    header: "Diagramação",
                  },
                  {
                    name: "student_name",
                    type: "text",
                    header: "Aluno",
                  },
                  {
                    name: "owner_name",
                    type: "text",
                    header: "Criado por",
                  },
                  {
                    name: "status",
                    type: "text",
                    header: "Status",
                  },
                  {
                    name: "justify",
                    type: "text",
                    header: "Justificativa",
                  },
                ]}
                items={files.map((x: any) => {
                  return {
                    _id: x._id,
                    filename: x.filename,
                    layout_name: x.layout?.codeExam ?? '-',
                    student_name: x.student?.name ?? '-',
                    owner_name: x.owner.name,
                    status: checkStatus(x.status),
                    justify:x.justify?('<p style="text-align:left;white-space:pre-line;">'+x.justify+'</p>') : '-'
                  };
                })}
                onManagerActionClick={(id) => {
                  // navigate(`/layouts_exams/view/${id}`);
                  let file: any = files.filter((item: any) => item._id == id)[0]
                  window.open(STORAGE_URL + '/' + file.path);
                  console.log(id)

                }}
                onDeleteActionClick={function (id: any): void {
                  console.log('delete clicked')
                  dispatch(setState({ class_id: id, showWarning: true }));
                }}
                hideDelete
                hideEdit
                not_found_message={model.type=='objective'?"Nenhuma folha de resposta encontrada.":"Nenhum caderno de resposta encontrado."}
                loading_screen={loading_screen || loading_files}
                button_text={"Adicionar arquivos"}
                onClickButton={() => {
                  console.log('clicou')
                  dispatch(setState({ showModal: true }))
                }}
                hideBtn
                search_text={"Pesquisar pelo nome do arquivo"}
                pagination={{
                  last_page: pagination?.totalPages,
                  current_page: pagination?.page,
                }}
                onChangePagination={function (page: number): void {
                  // dispatch(setPage(page));
                  dispatch(setPage(page));
                  dispatch(fetchFilesId({ id: params.id, force: true, page: page }));
                }}
                top_text={model.type=='objective'?`Aqui ficam as folhas de respostas incluídas nessa prova`:`Aqui ficam os cadernos de resposta incluídos nessa prova`}
                onSearchButtonClick={function (): void {
                  // dispatch(setPage(1));
                  dispatch(setPage(1));
                  dispatch(fetchFilesId({ id: params.id, force: true, page: 1 }));
                }}
              />
            </TabPanel>
           
          </div>
        </div>
      </div>
    </div>
  );
}
