
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchListFiltered = createAsyncThunk(
  "exams/listFiltered",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await QuestionsAPI.getAllFiltered(
        token,
        params.tagSelect?'':state.exams.questionContent,
        "type="+state.exams.model.type+"&discipline_id="+(state.exams.disciplineSelect._id+(params.tagSelect?('&tags='+params.tagSelect):'')),
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchListFilteredReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchListFiltered.pending, (state: { loading_input: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_input = true;
    })
    .addCase(
      fetchListFiltered.fulfilled,
      (state: {questionsTag:any,tagSelect:any,questionContent:any,questionsSearch:any, questions:any,data: any; loading_input: boolean;data_filtered:any }, action: { payload: any }) => {
        clearErrorState(state);
        if(state.questionContent){
          state.questionsSearch = action.payload.filter((item:any)=>state.questions[item._id]==undefined);
        }
        else if(state.tagSelect!=''){
          let questions = state.questionsTag;
          action.payload.map((item:any,id:any)=>{
            questions[item._id]=item;
          })
          state.questionsTag = questions;
          console.log('ENTROU na tag')
        }
        else{
          let questions = state.questions;
          action.payload.map((item:any,id:any)=>{
            questions[item._id]=item;
          })
          state.questions = questions;
        }
        
        state.loading_input = false;
      }
    )
    .addCase(
      fetchListFiltered.rejected,
      (
        state: any,
        action: { payload: any }
      ) => {
        console.log(state)
        state.data = [];
        const error = action.payload.error;
        console.log(error);
        state.error_code = error?.code;
        state.loading_input = false;
      }
    );
};
