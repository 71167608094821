import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { DemandsAPI } from "../../../apis/DemandsAPI";
import { screenState } from "../../../../constants/consts";

export const deleteItem = createAsyncThunk(
  "demands/delete",
  async (_params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await DemandsAPI.delete(token, state.demands.list.selectedId);
    } catch (e: any) {
      console.log(
        `Error deleteItem::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addDeleteItemReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(deleteItem.pending, (state, action) => {
      return { ...state, screen: screenState };
    })
    .addCase(deleteItem.fulfilled, (state, action) => {
      var all = [...state.list.all];
      var id = all.findIndex((x) => x._id === action.payload.data._id);
      if (id >= 0) all.splice(id, 1);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
        list: {
          ...state.list,
          all: all,
        },
      };
    })
    .addCase(deleteItem.rejected, (state, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
