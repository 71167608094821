import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { DemandsAPI } from "../../../apis/DemandsAPI";
import { handleThunkRejection } from "../../utils";

export const fetchList = createAsyncThunk(
  "demands/list",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await DemandsAPI.getAll(
        token,
        state.demands.pagination.page,
        state.demands.list.search ?? "",
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchListReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchList.pending, (state: any, _action: any) => {
      state.screen.loadingScreen = true;
    })
    .addCase(fetchList.fulfilled, (state, action) => {
      state.list.all = action.payload.docs;
      state.screen.loadingScreen = false;

      Object.keys(action.payload)
        .filter((key) => key in state.pagination)
        .forEach((key) => {
          state.pagination[key] = action.payload[key];
        });
    })
    .addCase(fetchList.rejected, (state, action:any) => {
      return {
        ...state,
        list: {
          ...state.list,
          all: [],
        },
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};

