import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchAssignment = createAsyncThunk(
  "questions/fetch-assignment",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    console.log(params)
    try {
      return await AssignmentsAPI.getById(token, params.id, false);
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchAssignmentReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchAssignment.pending, (state, _action) => {
      state.loading_screen = true;
    })
    .addCase(fetchAssignment.fulfilled, (state, action) => {
      console.log(state.assignment)
      return {
        ...state,
        assignment: action.payload,
        loading_screen: false,

      };
    })
    .addCase(fetchAssignment.rejected, (state, action) => {
      return {
        
          ...state,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loading_screen: false,
      };
    });
};
