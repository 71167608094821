import { modelSlice } from "./slice";

export const {
  setName,
  setPassword,
  setPasswordConfirmation,
  setEmail,
  setId,
  clearForm,
  setErrorMessage,
  setModel
} = modelSlice.actions;
