import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Organization } from "../../../model/Organization";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Organization,
};
