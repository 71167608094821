// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

const API_PATH = API_URI + "/users";

export const UsersAPI = {
  getAll: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllAdmin: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${
          search ?? ""
        }&search_query=${encodeURIComponent("role=Admin")}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllColaborator: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${
          search ?? ""
        }&search_query=${encodeURIComponent("role=Colaborator")}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllTeacher: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${
          search ?? ""
        }&search_query=${encodeURIComponent("role=Teacher")}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllTeacherFiltered: async function (
    token = "",
    query: string = "",
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/teachers?search_query=${encodeURIComponent(query)}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllStudent: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${
          search ?? ""
        }&search_query=${encodeURIComponent("role=Student")}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllStudentsFiltered: async function (
    token = "",
    query: string = "",
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/students?search_query=${encodeURIComponent(query)}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  saveAndRegister: async function (
    token: string,
    rawdata: any,
    cancel = false
  ) {
    var data: any = {};
    Object.entries(rawdata).forEach(([key, value], _index) => {
      if (typeof value == "object" && rawdata[key]._id !== undefined)
        data[key] = rawdata[key]._id;
      else data[key] = rawdata[key];
    });
    console.log(data);

    return api
      .request({
        url: `${API_PATH}/create-and-register`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  uploadFile: async function (
    token: string,
    data: any,
    schema: string,
    cancel = false
  ) {
    console.log(data);
    var formData = new FormData();
    formData.append("csv", data);
    return api
      .request({
        url: `${API_PATH}/import-csv/${schema}`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
