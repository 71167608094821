import { useEffect } from "react";

// unidades
import { fetchList } from "../../../services/stores/studentExamsStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate, useParams } from "react-router-dom";
import { setSearch } from "../../../services/stores/studentExamsStore/actions";

export function StudentExamView() {
  const exams = useAppSelector((store) => store.studentExams.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.studentExams.screen
  );
  const { pagination } = useAppSelector((store) => store.studentExams);
  const { search } = useAppSelector((store) => store.studentExams.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchList({ force: true, id: params.id }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        hideDelete
        hideEdit
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Provas do Aluno"}
        not_found_message={"Nenhuma prova encontrada"}
        button_link="/exams/create"
        search_text="Pesquise pelo nome da prova"
        deleting_message={
          "Ao deletar esta prova, ela não estará mais disponível no sistema"
        }
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true }));
        }}
        onManagerActionClick={(id) => {
          navigate(`/student-exams-manager/${params.id}/${id}`);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={exams?.map((x: any) => {
          let corrected = 0;
          let pending = 0;
          let answered = 0;
          let score = 0;
          let total = 0;
          x.answers.forEach((answer: any) => {
            answer.corrections?.forEach((element: any) => {
              score += element.score;
              total += 100;
            });
          });
          return {
            _id: x._id,
            description: x.description,
            score: `${isNaN(score) ? 0 : score}/${isNaN(total) ? 1 : total} (${(
              ((isNaN(score) ? 0 : score) / (isNaN(total) ? 1 : total)) *
              100
            ).toFixed(0)} %)`,
          };
        })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "description", type: "text", header: "Descrição" },
          { name: "score", type: "text", header: "Nota" },
        ]}
      ></List>
    </div>
  );
}
