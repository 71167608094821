import { useEffect } from "react";

import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  setSearch,
  setWarning,
  setSelectedId,
  setQuery,
  setPage,
} from "../../../services/stores/registrationStore/actions";
import {
  fetchListThunk,
  deleteThunk,
} from "../../../services/stores/registrationStore/slice";

export function RegistrationView(props: { title?: string }) {
  const registrations = useAppSelector((store) => store.registrations.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.registrations.screen
  );
  const { pagination } = useAppSelector((store) => store.registrations);
  const { search } = useAppSelector((store) => store.registrations.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    dispatch(setPage(1));
    if (params.id && location.state?.key)
      dispatch(setQuery(`${location.state.key}=${location.state.filter}`));
    dispatch(fetchListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        onManagerActionClick={(_id, params) => {
          navigate("/student-exams/" + params.student_id);
        }}
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={props.title ?? "Matrículas"}
        pronoun={"a"}
        not_found_message={"Nenhuma matrícula encontrada"}
        button_text="Adicionar matrícula"
        button_link="/registrations/create"
        search_text="Pesquise pelo nome da turma, curso, aluno ou unidade"
        deleting_message={
          "Ao deletar esta matrícula, ela não estará mais disponível no sistema"
        }
        onSearchButtonClick={() => {
          dispatch(fetchListThunk({ force: true }));
        }}
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/registrations/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={registrations?.map((x: any) => {
          return {
            _id: x._id,
            student_id: x.student?._id,
            student: x.student?.name,
            class: x.class?.name,
            unit: x.class?.unit?.name,
            course: x.class?.course?.name,
            active: x.active ? "SIM" : "NÃO",
          };
        })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "class", type: "text", header: "Turma" },
          { name: "student", type: "text", header: "Aluno" },
          { name: "unit", type: "text", header: "Unidade" },
          { name: "course", type: "text", header: "Curso" },
          { name: "active", type: "text", header: "Ativo" },
        ]}
      ></List>
    </div>
  );
}
