import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const uploadFileStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (params: any, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      try {
        console.log(state[slice].list.search);
        return await apiFunction(token, params.data, params.schema);
      } catch (e: any) {
        console.log(
          `Error uploadFile::createAsyncThunk ${e.status} ${e.message}`
        );

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};
export const addUploadFileStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  uploadFileStoreFactory: any
) => {
  builder
    .addCase(uploadFileStoreFactory.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(uploadFileStoreFactory.fulfilled, (state, action) => {
      state.screen.loadingScreen = false;
    })
    .addCase(uploadFileStoreFactory.rejected, (state, action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
