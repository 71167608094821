import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { User } from "../../../model/User";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: User,
};
