import { useEffect } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Form,
  FormControl as Input,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/demandManagerStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setErrorMessage,
  clearState,
  setState,
  setId,
} from "../../../services/stores/testStore/actions";
import { Searchbar } from "../../../component/list/searchbar";
import { FaUsers } from "react-icons/fa";
import { fetchCourses } from "../../../services/stores/layoutExamStore/async/fetchCourseThunk";
import { fetchUnits } from "../../../services/stores/layoutExamStore/async/fetchUnitThunk";
import { fetchStudent } from "../../../services/stores/testStore/async/fetchStudentThunk";
import { Actions } from "../../../component/list/actions";
import { updateClasses } from "../../../services/stores/testStore/async/updateClassesThunk";
import { fetchClassListThunk } from "../../../services/stores/userStudentStore/slice";
import { fetchIdRegistration } from "../../../services/stores/testStore/async/fetchRegistrationsIdThunk";
import moment from "moment";
import { fetchList } from "../../../services/stores/examStore/async/fetchListThunk";
import { fetchListExam } from "../../../services/stores/testStore/async/fetchListExamThunk";
import { updateExams } from "../../../services/stores/testStore/async/updateExamsThunk";

var debounce = require("lodash.debounce");

export function ExamApplicationManagerExamForm(props: { editMode?: boolean }) {
  const {
    error_message,
    loading_save,
    search_form,
    loading_screen,
    loading_student,
    course_text,
    unit_text,
    unit,
    examSelected,
    examsSelecteds,
    course,
    exam_text,
    examsSelected
  } = useAppSelector((store:any) => store.tests);

  
  
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    dispatch(updateExams()).then((result: any) => {
      if (result?.payload?.status === "success") {
        history(`/tests/view/${params.id}?tab=2`);
      }
    });
  };

  const searchClasses = (query: any, callback: any) => {
    dispatch(fetchList({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  

  useEffect(() => {
    dispatch(setId(params.id));
    dispatch(fetchListExam({ force: true,test_id:params.id,all:true }));
    

    return () => {
      dispatch(clearState());
    };
  }, [dispatch, props, params]);
  console.log(examsSelecteds);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {"Adicionar/Remover"} Provas{" "}
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {"Cadastre/Edite as provas da aplicação"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen === false && (
              <div>
                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          dispatch(setErrorMessage(null));
                          return undefined;
                        }}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Prova
                        <Attention />
                      </Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Digite parte do nome da prova..."
                        noOptionsMessage={
                          exam_text.length == 0
                            ? () => "Digite parte do nome da prova..."
                            : () => "Nenhuma opção encontrada"
                        }
                        onChange={(e: any) => {
                          console.log(e);
                          let exams_selecteds:any={...examsSelecteds}
                          exams_selecteds[e._id]=e;
                          dispatch(setState({ examsSelecteds: exams_selecteds }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ exam_text: e }));
                          return undefined;
                        }}
                        cacheOptions
                        value={examSelected}
                        loadOptions={debounce(searchClasses, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup>
                  
                 
                  <Form.Label>
                    Provas <Attention />
                  </Form.Label>
                  <div className="row">
                    <div className="col-12">
                      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
                      <Searchbar
                        search_input_value={search_form}
                        search_text={"Encontre uma prova"}
                        onChangeSearchInput={function (value: any): void {
                          dispatch(setState({ search_form: value }));
                        }}
                        onSearchButtonClick={() => {
                          //   props.onSearchButtonClick();
                        }}
                      ></Searchbar>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>Número de Questões</th>
                                <th>Craida em</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(examsSelecteds)
                                .filter((item: any, id: any) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(search_form.toLowerCase())
                                )
                                ?.map((item: any, id: any) => (
                                  <tr key={id}>
                                    <td>{item?.name}</td>
                                    <td>{item?.questions.length}</td>
                                    <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>
                                      <Actions
                                        actionItem={item}
                                        onDeleteClick={() => {
                                          let students_: any = { ...examsSelecteds };
                                          delete students_[item._id];
                                          dispatch(
                                            setState({ examsSelecteds: students_ })
                                          );
                                        }}
                                        hideManager={true}
                                        hideEdit={true}
                                        hideDelete={false}
                                      ></Actions>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {Object.values(examsSelecteds).filter((item: any, id: any) =>
                        item.name
                          .toLowerCase()
                          .includes(search_form.toLowerCase())
                      )?.length == 0 && (
                        <div className="row w-100 mt-3">
                          <div className="col-12">
                            <p style={{ textAlign: "center" }}>
                              Nenhuma prova vinculada/encontrada
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br/>
                  {loading_save === false && (
                    <div className="w100 inline-flex jcsb mt-2">
                      <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        search="?tab=2"
                        icon={<RiArrowGoBackLine />}
                        to={`/tests/view/${params.id}`}
                      />

                      <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar provas"
                        disabled={loading_save}
                        loadingtext="Salvando provas"
                        loading={loading_save}
                      />
                    </div>
                  )}

                  {loading_save === true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center mt-2">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen === true && (
              <div className="row mt-2">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
