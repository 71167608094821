import { modelSlice } from "./slice";

export const {
  setSearch,
  setQuery,
  setWarning,
  setSelectedId,
  clearWindowState,
  clearErrorState,
  setLoading,
  setErrorMessage,
  setPage,
  setClass,
  setClass2,
  setStudent,
  setActive
} = modelSlice.actions;
