import { User } from "./User";
import { Question } from "./Question";
import { Answer } from "./Answer";

export const Correction = {
  _id: "",
  status: "",
  comment: "",
  score: 0,
  corrector: User,
  answer: Answer,
  claimDate: "",
  correctionLimit: "",
  replyLimit: "",
  correctionDate: "",
  replyDate: "",
  viewedByStudent: "",
  claim:"",
  reply:"",
  annotations:[]
};
