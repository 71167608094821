import React, { useState } from "react";

export function TextReadMore(props: any) {
  const [readmore, SetReadMore] = useState(false);

  return (
    <div>
      <props.element className="break-word overflow-hidden" style={props.style}>
        <b style={{ float: "left", marginRight: "2px" }}>{props.title} </b>
        {props.html == true && <br />}
        {props.html == undefined && (
          <div style={{ whiteSpace: "pre-line" }}>
            {readmore
              ? props.children
              : props.children.substring(0, props.limit) +
                (props.children.length > props.limit ? "..." : "")}
          </div>
        )}

        {props.html == true && (
          <div
            className="jodit-wysiwyg"
            dangerouslySetInnerHTML={{ __html: props.children }}
          >
            {/* {(readmore ? props.children : props.children.substring(0, props.limit) + (props.children.length > props.limit ? "..." : ""))} */}
          </div>
        )}
      </props.element>
      <>
        {props.children.length > props.limit ? (
          <a className="text-event" onClick={() => SetReadMore(!readmore)}>
            {readmore ? "Mostrar menos" : "Mostrar mais"}
          </a>
        ) : (
          ""
        )}
      </>
    </div>
  );
}
