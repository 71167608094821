import { useEffect, useState } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../../../component/base/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { TextReadMore } from "../../../component/base/TextReadMore";
import moment from "moment";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import {
  setPage,
  setSearch,
  setSelectedId,
  setWarning,
} from "../../../services/stores/demandManagerStore/actions";
import TabPanel from "../../../component/base/TabPanel";
import { Grid } from "../../../component/list/grid";
import { fetchList } from "../../../services/stores/demandManagerStore/async/fetchListThunk";
import { Alert } from "../../../component/list/alert";
import { deleteItem } from "../../../services/stores/demandManagerStore/async/deleteThunk";
import { fetchParent } from "../../../services/stores/demandManagerStore/async/fetchParent";

export function DemandsManagerView() {
  const assignments = useAppSelector((store) => store.demandsManager.list.all);
  const parent = useAppSelector((store) => store.demandsManager.parent);
  const { search } = useAppSelector(
    (store) => store.demandsManager.list
  );
  const { loadingScreen } = useAppSelector(
    (store) => store.demandsManager.screen
  );
  const { showWarning } = useAppSelector(
    (store) => store.demandsManager.screen
  );
  console.log(loadingScreen)

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const history: any = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchParent({ id: params.id, force: true }));
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body">
          <Alert
            title="Atenção"
            text={
              "Ao deletar esta questão, ela não estará mais disponível no sistema"
            }
            show_warning={showWarning}
            onCancel={() => dispatch(setWarning(false))}
            onConfirm={() => dispatch(deleteItem())}
          ></Alert>

          <div>
            <div style={{ float: "right" }}>
              <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to={`/demands`}
              />
            </div>
            <div className="flex jcsb">
              <h4 className="header">{parent.name}</h4>
            </div>

            <TextReadMore
              title="Encerramento: "
              element="div"
              limit={1000}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {moment(parent.end_datetime).format("DD/MM/YYYY HH:mm")}
            </TextReadMore>

            {parent.description!=undefined && <TextReadMore
              title="Descrição: "
              element="div"
              limit={120}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {parent.description ?? ''}
            </TextReadMore>}

            {parent.keywords.length > 0 && (
              <TextReadMore
                title="Palavras chave: "
                element="div"
                limit={120}
                style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
              >
                {parent.keywords.join(" ")}
              </TextReadMore>
            )}
            <Tabs
              value={activeTab}
              onChange={(_: any, value: any) => {
                setActiveTab(value);
                history.push({ search: `?tab=${value}` });
              }}
              style={{ backgroundColor: "#ececf1" }}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
            >
              <Tab label="Atribuições de Questões" />
              {/* <Tab label="Atribuições de Redações" /> */}
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <Grid
                onChangeSearchInput={(value: any) => {
                  dispatch(setSearch(value));
                }}
                search_input_value={search}
                loading_search={false}
                fields={[
                  { name: "description", type: "text", header: "Descrição" },
                  {
                    name: "teacher_name",
                    type: "text",
                    header: "Professor",
                  },
                  {
                    name: "discipline_name",
                    type: "text",
                    header: "Disciplina",
                  },
                  {
                    name: "question_text",
                    type: "text",
                    header: "Questões Cadastradas",
                  },
                ]}
                items={assignments?.map((x: any) => {
                  return {
                    _id: x._id,
                    discipline_name: x.discipline.name,
                    description: x.description,

                    teacher_name: x.teacher.name,
                    question_text: `Objetivas: ${x.questions.map((item:any)=>{
                      if(item.type=='objective'){
                        return 1;
                      }
                      else if(item.type=='set'){
                        return item.questions.length;
                      }
                      else return 0;
                    }).reduce((partialSum:any, a:any) => partialSum + a, 0)}/${x.multiple_choice_questions_qty}<br/> Dissertativas:${x.questions.map((item:any)=>{
                      if(item.type=='text'){
                        return 1;
                      }
                      else return 0;
                    }).reduce((partialSum:any, a:any) => partialSum + a, 0)}/${x.essay_questions_qty}`,
                  };
                })}
                onManagerActionClick={(id) => {
                  navigate(`/demands/questions/${id}`);
                }}
                onDeleteActionClick={function (id: any): void {
                  dispatch(setSelectedId(id));
                  dispatch(setWarning(true));
                }}
                onEditActionClick={(id) => {
                  navigate(`/demands/${id}/edit`);
                }}
                not_found_message={"Nenhuma demanda encontrada."}
                loading_screen={loadingScreen}
                button_text={"Adicionar atribuições"}
                button_link={`/demands/${params.id}/create`}
                search_text={"Pesquisar pelo nome do professor ou disciplina"}
                pagination={{
                  last_page: 1,
                  current_page: 1,
                }}
                onChangePagination={function (page: number): void {
                  dispatch(setPage(page));
                }}
                top_text={`Aqui ficam as atribuições da demanda ${parent.name}`}
                onSearchButtonClick={function (): void {
                  dispatch(setPage(1));
                  dispatch(fetchList({ id: params.id, force: true }));
                }}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}></TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
