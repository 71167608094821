import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addDeleteItemReducers } from "./async/deleteThunk";
import { addSaveReducers } from "./async/saveThunk";
import { addFetchListReducers } from "./async/fetchListThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";
import { setChildVisible } from "./sync/setChildVisible";
import { addFetchIdReducers } from "./async/fetchIdThunk";
import { addAssignReducers } from "./async/assignThunk";
import { addFetchAssignmentReducers } from "./async/fetchAssignmentThunk";

export const modelSlice = createSlice({
  name: "questionsList",
  initialState: modelInitialState,
  reducers: {
    setChildVisible: (state, action) => setChildVisible(state, action),
    setModel: (state, action: PayloadAction<any>) => {
      return { ...state, model: action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_save: action.payload };
    },
    setState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      return { ...state, search: action.payload };
    },
    setTag: (state, action: PayloadAction<string>) => {
      return { ...state, tag: action.payload };
    },
    setErrorMessage: (state, action: PayloadAction<any>) => {
      return { ...state, error_message: action.payload };
    },
    setId: (state, action: PayloadAction<any>) => {
      return { ...state, question_id: action.payload };
    },
    setWarning: (state, action: PayloadAction<any>) => {
      return { ...state, show_warning: action.payload };
    },
    clearStateModal: (state) => {
      return {
        ...state,
        question_modal: "",
        alternativa_a_modal: "",
        alternativa_b_modal: "",
        alternativa_c_modal: "",
        alternativa_d_modal: "",
        alternativa_e_modal: "",
        file_select_modal: "",

        model_modal: {
          question: "",
          question_content: "",
          type: "objective",
          degree: "",
          difficulty_level: "",
          number_alternative: 5,
          discipline_id: "",
          justify_answer_text: "",
          justify_answer_files: [],
          correct: "",
          alternatives: [
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
          ],
        },
        error_message_modal: null,
      };
    },
    clearState: (state) => {
      return {
        ...state,
        error_message: [],
        discipline_id: null,
        name: "",
        loading_save: false,
        question: "",
        alternativa_a: "",
        alternativa_b: "",
        alternativa_c: "",
        alternativa_d: "",
        alternativa_e: "",
        file_select: "",
        tag: "",
        model: {
          question: "",
          question_content: "",
          type: "objective",
          degree: "",
          difficulty_level: "",
          number_alternative: 5,
          discipline_id: "",
          tags: [],
          justify_answer_text: "",
          justify_answer_files: [],
          correct: "",
          alternatives: [
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
            { alternative: "", alternative_content: "", correct: false },
          ],
          questions: [],
        },
      };
    },
  },
  extraReducers: (builder) => {
    addFetchListReducers(builder);
    addFetchIdReducers(builder);
    addDeleteItemReducers(builder);
    addSaveReducers(builder);
    addUpdateReducers(builder);
    addAssignReducers(builder);
    addFetchAssignmentReducers(builder);
  },
});
