import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const saveStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any,
  preSaveValidate: any = null
) => {
  return createAsyncThunk(
    typePrefix,
    async (_, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      if (preSaveValidate) {
        const preSaveStatus: any = preSaveValidate(state[slice].model);
        if (preSaveStatus.error)
          return handleThunkRejection(preSaveStatus.e, rejectWithValue);
      }
      try {
        return await apiFunction(token, state[slice].model);
      } catch (e: any) {
        console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};

export const addSaveStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  saveStoreFactory: any
) => {
  builder
    .addCase(saveStoreFactory.pending, (state, _action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: true,
        },
      };
    })
    .addCase(saveStoreFactory.fulfilled, (state, action) => {
      var all = [...state.list.all];
      all.push(action.payload.data);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: false,
        },
        list: { ...state.list, all: all },
      };
    })
    .addCase(saveStoreFactory.rejected, (state: any, action: any) => {
      console.log(`${action.payload?.code} - ${action.payload?.message}`);
      return {
        ...state,
        screen: {
          ...state.screen,
          errorMessages: action.payload?.message,
          loadingSave: false,
        },
      };
    });
};
