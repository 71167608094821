
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchId = createAsyncThunk(
  "layouts/get",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state', state);
    const { token } = state.auth;
    try {
      return await LayoutsExamsAPI.getById(
        token,
        state.layouts.layout_id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchId.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchId.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        state.classes = [...action.payload.registrations];
        let students: any = {};
        let disciplines: any = [];
        let disciplines_obj = [];
        for (let i = 0; i < action.payload.exam.questions.length; i++) {
          if (!disciplines.includes(action.payload.exam.questions[i].discipline_id)) {
            disciplines.push(action.payload.exam.questions[i].discipline_id)
            disciplines_obj.push(action.payload.exam.questions[i].discipline)

          }
        }
        action.payload.selected_disciplines = action.payload.selected_disciplines.filter((item: any) => disciplines.includes(item._id));
        disciplines_obj.map((item: any) => {
          let contain = action.payload.selected_disciplines.filter((item2: any) => item2._id == item._id).length == 0 ? false : true;
          if (!contain) {
            action.payload.selected_disciplines.push({
              _id: item._id,
              name: item.name,
              order: action.payload.selected_disciplines.length,
              number: 1
            });
          }
        })
        action.payload.registrations.map((item: any, id: any) => {
          if (item?.student != null)
            students[item._id] = item;
        })
        console.log(action.payload.registrations)
        state.students = students;

        delete action.payload.registrations;
        state.model = { ...state.model, ...action.payload, exam_id: action.payload.exam._id };
        state.rules_answer = action.payload.rules_answer;



        // let questoes_selecionadas: any = {};
        // for (let i = 0; i < action.payload.exam.questions.length; i++) {
        //   questoes_selecionadas['q_' + action.payload.exam.questions[i]._id] = action.payload.exam.questions[i];
        // }
        // state.questions = questoes_selecionadas;
        // state.examSelect = action.payload.exam;

        state.loading_screen = false;
      }
    )
    .addCase(
      fetchId.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
