import { useEffect } from "react";

// classes
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  setSearch,
  setWarning,
  setSelectedId,
  setQuery,
} from "../../../services/stores/classStore/actions";
import {
  fetchListThunk,
  deleteThunk,
} from "../../../services/stores/classStore/slice";

export function ClassView() {
  const classes = useAppSelector((store) => store.classes.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.classes.screen
  );
  const { pagination } = useAppSelector((store) => store.classes);
  const { search } = useAppSelector((store) => store.classes.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (params.id && location.state?.key)
      dispatch(setQuery(`${location.state.key}=${location.state.filter}`));
    dispatch(fetchListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        onManagerActionClick={(value) => {
          navigate(`/turmas/${value}/registrations`, {
            state: { filter: value, key: "class._id_id" },
          });
        }}
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Turmas"}
        not_found_message={"Nenhuma turma encontrada"}
        button_text="Adicionar turma"
        button_link="/turmas/create"
        search_text="Pesquise pelo nome da turma"
        deleting_message={
          "Ao deletar esta turma, ela não estará mais disponível no sistema"
        }
        onSearchButtonClick={() => {
          dispatch(fetchListThunk({ force: true }));
        }}
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/turmas/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={classes?.map((x: any) => {
          return {
            _id: x._id,
            name: x.name,
            course: x.course.name,
            unit: x.unit.name,
            period: x.period,
          };
        })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Turma" },
          { name: "course", type: "text", header: "Curso" },
          { name: "period", type: "text", header: "Período" },
          { name: "unit", type: "text", header: "Unidade" },
        ]}
      ></List>
    </div>
  );
}
