import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";

import { handleThunkRejection } from "../../utils";
import { ExamsAPI } from "../../../apis/ExamsAPI";

export const fetchId = createAsyncThunk(
  "student-exams/get",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await ExamsAPI.getByIdAsOther(
        token,
        params.id ?? state.studentExam.list.selectedId,
        false,
        params.sid
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchId.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(fetchId.fulfilled, (state, action) => {
      state.model = action.payload;
      state.screen.loadingScreen = false;
      state.screen.errorMessages = null;
    })
    .addCase(fetchId.rejected, (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          all: [],
        },
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
