import { Assignment } from "../../../model/Assignment";

export const modelInitialState = {
  redirect: false,
  path: "/questions",
  loading_screen: false,
  data: [],
  pagination: {
    page: 1,
    last_page: 1,
  },
  msgErro: "",
  show_warning: false,
  show_erro: false,
  unidade_to_delete: "",
  search: "",
  loading_search: true,
  showModal: false,
  questionarioSelect: [],
  error_message: [],
  show_modal_register: false,
  loading_save: false,

  loadings_images: [false, false, false, false, false, false],
  question_id: "",
  showModalView: false,
  question: "",
  // alternatives: [
  //   {alternative:'',alternative_content:'',correct:true},
  //   {alternative:'',alternative_content:'',correct:false},
  //   {alternative:'',alternative_content:'',correct:false},
  //   {alternative:'',alternative_content:'',correct:false},
  //   {alternative:'',alternative_content:'',correct:false}
  // ],
  alternativa_a: "",
  alternativa_b: "",
  alternativa_c: "",
  alternativa_d: "",
  alternativa_e: "",
  file_select: "",
  tag: "",
  model: {
    question: "",
    question_content: "",
    type: "objective",
    degree: "",
    difficulty_level: "",
    number_alternative: 5,
    discipline_id: "",
    tags: [],
    justify_answer_text: "",
    justify_answer_files: [],
    correct: "",
    alternatives: [
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
    ],
    questions: [],
  },

  question_modal: "",
  alternativa_a_modal: "",
  alternativa_b_modal: "",
  alternativa_c_modal: "",
  alternativa_d_modal: "",
  alternativa_e_modal: "",
  file_select_modal: "",

  model_modal: {
    question: "",
    question_content: "",
    type: "objective",
    degree: "",
    difficulty_level: "",
    number_alternative: 5,
    discipline_id: "",
    justify_answer_text: "",
    justify_answer_files: [],
    correct: "",
    alternatives: [
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
      { alternative: "", alternative_content: "", correct: false },
    ],
  },
  error_message_modal: null,
  loadings_images_modal: [false, false, false, false, false, false],

  number_alternatives: [1, 2, 3, 4, 5],
  corretas: [
    { id: "a", name: "a)" },
    { id: "b", name: "b)" },
    { id: "c", name: "c)" },
    { id: "d", name: "d)" },
    { id: "e", name: "e)" },
  ],
  degrees: ["Ensino Fundamental", "Ensino Médio", "Ensino Superior"],
  difficulty_levels: ["Fácil", "Médio", "Difícil"],
  questionSelect: null,

  assignment: Assignment,
};
