import { UserRole } from "../constants/enum/UserRole";
import { Organization } from "./Organization";

export const User = {
    _id: "",
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role:  UserRole.ADMIN_USER,
    organization: Organization,
};