import { useEffect } from "react";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { fetchList } from "../../../services/stores/demandStore/async/fetchListThunk";
import { List } from "../../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
} from "../../../services/stores/demandStore/actions";
import { deleteItem } from "../../../services/stores/demandStore/async/deleteThunk";

export function DemandsView() {
  const demands = useAppSelector((store) => store.demands.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.demands.screen
  );
  const { pagination } = useAppSelector((store) => store.demands);
  const { search } = useAppSelector((store) => store.demands.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchList({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        search_input_value={search}
        search_text="Pesquise pelo nome da demanda"
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchList({ force: true }));
        }}
        title={"Demandas"}
        not_found_message={"Nenhuma demanda encontrada"}
        button_text="Adicionar demanda"
        button_link="/demands/create"
        deleting_message={
          'Ao deletar esta demanda, ela não estará mais disponível no sistema'
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/demands/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchList({ force: true }));
        }}
        onManagerActionClick={(id) => {
          navigate("/demands/" + id);
        }}
        pronoun='a'

        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={demands}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "description", type: "text", header: "Descrição" },
          {
            name: "end_datetime",
            type: "date",
            header: "Data de encerramento",
          },
          { name: "createdAt", type: "date", header: "Criado em" },

        ]}
      ></List>
    </div>
  );
}
