import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import { TestsAPI } from "../../../apis/TestsAPI";

export const deleteClassItem = createAsyncThunk(
  "tests/delete-class",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await TestsAPI.deleteClass(token, state.tests.test_id,state.tests.class_id);
    } catch (e: any) {
      console.log(
        `Error deleteItem::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addDeleteClassItemReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(deleteClassItem.pending, (state: any, _action: any) => {
      return { ...clearErrorState(state), loading_screen: true,showWarning:false };
    })
    .addCase(
      deleteClassItem.fulfilled,
      (state: { classes: any }, action: { payload: { data: { _id: any } } }) => {
        console.log(action.payload.data)
        var data = [...state.classes];
        console.log(state.classes)
        var farmId = data.findIndex((x) => x._id === action.payload.data._id);
        console.log(farmId)
        if (farmId >= 0) data.splice(farmId, 1);
        console.log(data)
        return { ...clearErrorState(state), loading_screen: false,classes:data };
      }
    )
    .addCase(deleteClassItem.rejected, (state: any, action: { payload: any }) => {
      const error = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_screen: false,
      };
    });
};
