import { Provider } from "react-redux";
import { store } from "./services/stores";
import AppNav from "./navigation/AppNav";

function App() {
  return (
    <Provider store={store}>
      <AppNav></AppNav>
    </Provider>
  );
}

export default App;
