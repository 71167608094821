import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import moment from "moment";

export const update = createAsyncThunk(
  "layouts/update",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      let form = new FormData();
      Object.keys(state.layouts.model).map((item:any)=>{
        if(item=='selected_disciplines'){
          state.layouts.model.selected_disciplines.map((item2:any)=>{
            form.append('selected_disciplines',JSON.stringify(item2));
          })
        }
        else if(item!='logo_path' && item!='logo_path_bottom'){
          form.append(item,state.layouts.model[item]);
        }
      })
      form.append('_id',state.layouts.layout_id);
      return await LayoutsExamsAPI.update(token, state.layouts.layout_id,form);
    } catch (e: any) {
      console.log(
        `Error update::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addUpdateReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(update.pending, (state, _action) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(update.fulfilled, (state, action) => {
      var data = [...state.data];
      console.log(action.payload)
      var farmId = data.findIndex((x) => x._id === action.payload.data._id);
      if (farmId >= 0) data[farmId] = action.payload.data;
      return { ...clearErrorState(state), loading_save: false, data: data };
    })
    .addCase(update.rejected, (state: any, action: { payload: any }) => {
      const error = action.payload;
      console.log(error)
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_save: false,
      };
    });
};
