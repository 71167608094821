import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setId,
  setTag,
  setState,
  clearState,
  setLoading,
  setModel,
  setErrorMessage,
  clearStateModal,
} = modelSlice.actions;
