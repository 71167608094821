import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";
import moment from "moment";

export const update = createAsyncThunk(
  "demand-manager/update",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await AssignmentsAPI.update(token, state.demandsManager.model);
    } catch (e: any) {
      console.log(`Error update::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addUpdateReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(update.pending, (state, _action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: true,
        },
      };
    })
    .addCase(update.fulfilled, (state, action) => {
      var all = [...state.list.all];
      var id = all.findIndex((x) => x._id === action.payload._id);
      if (id >= 0) all[id] = action.payload;
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: false,
        },
        list: { ...state.list, all: all },
      };
    })
    .addCase(update.rejected, (state, action:any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.payload.code,
          errorMessages: action.payload.message,
          loadingScreen: false,
        },
      };
    });
  };