import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";

export const addCorrector = createAsyncThunk(
  "layouts/add-correctors",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return LayoutsExamsAPI.addCorrector(token, params.id, null, false, {
        corrector: params.corrector_id,
      });
    } catch (e: any) {
      console.log(
        `Error fetchParent::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addAddCorrector = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(addCorrector.pending, (state: any, _action: any) => {
    })
    .addCase(addCorrector.fulfilled, (state, action) => {
      console.log (action.payload)
      state.show_corrector_warning = false;
    })
    .addCase(addCorrector.rejected, (state, action) => {
      console.log (action)
      state.show_corrector_warning = false;
    });
};
