import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addDeleteItemReducers } from "./async/deleteThunk";
import { addSaveReducers } from "./async/saveThunk";
import { addFetchListReducers } from "./async/fetchListThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";
import { addFetchIdReducers } from "./async/fetchIdThunk";
import { listReducers, paginationReducers, screenReducers } from "../../../constants/consts";

export const modelSlice = createSlice({
  name: "studentExams",
  initialState: modelInitialState,
  reducers: {

    setId: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, _id: action.payload } };
    },
    setName: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setDescription: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },
   
    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListReducers(builder);
    addFetchIdReducers(builder);
    addDeleteItemReducers(builder);
    addSaveReducers(builder);
    addUpdateReducers(builder);
  },
});
