import SidebarItem from "../SidebarItem";

// icons
import {
  FaQuestion,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa";
import {
  MdQuestionAnswer,
  MdListAlt,
  MdList,
  MdTask,
  MdDashboard,
  MdPhotoCamera,
  MdTimer,
} from "react-icons/md";

export default function TeacherMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<MdDashboard />}
        title="Dashboard"
        href="/"
        {...{ shrunk }}
      />

      {/* Usuários */}
      <SidebarItem
        icon={<FaUserTie />}
        title="Alunos"
        href="/users-student"
        {...{ shrunk }}
      />

      {/* Gestão de Prova */}
      <SidebarItem
        icon={<MdQuestionAnswer />}
        title="Gestão de Prova"
        {...{ shrunk }}
      >
        <SidebarItem
          icon={<FaQuestion />}
          title="Questões"
          href="/questions"
          {...{ shrunk }}
        />
         <SidebarItem
          icon={<MdPhotoCamera />}
          title="Fotografar Prova"
          href="/upload-exam"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdListAlt />}
          title="Provas"
          href="/exams"
          {...{ shrunk }}
        />
         <SidebarItem
          icon={<MdTimer />}
          title="Aplicações"
          href="/tests"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdList />}
          title="Padrões de Diagramação"
          href="/layouts_default_exams"
          {...{ shrunk }}
        />
        {/* <SidebarItem
          icon={<MdList />}
          title="Diagramações"
          href="/layouts_exams"
          {...{ shrunk }}
        /> */}
        <SidebarItem
          icon={<MdTask />}
          title="Demandas"
          href="/teacher-demands"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Correções"
          href="/correction-exams"
          {...{ shrunk }}
        />
      </SidebarItem>
    </>
  );
}
