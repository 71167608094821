import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";

import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";

import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";

import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";

import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import { ClassesAPI } from "../../apis/ClassesAPI";
import {
  addFetchListFieldStoreFactoryReducer,
  fetchListFieldStoreFactory,
} from "../_stores/fetchListFieldStore";
import { CoursesAPI } from "../../apis/CoursesAPI";
import { UnitsAPI } from "../../apis/UnitsAPI";
import { UsersAPI } from "../../apis/UsersAPI";

export const fetchCourseListThunk = fetchListFieldStoreFactory(
  "classes/list-courses",
  CoursesAPI.getAll,
  "classes",
  "courses"
);

export const fetchUnitListThunk = fetchListFieldStoreFactory(
  "classes/list-units",
  UnitsAPI.getAll,
  "classes",
  "units"
);

export const fetchListThunk = fetchListStoreFactory(
  "classes/list",
  ClassesAPI.getAll,
  "classes"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "classes/get",
  ClassesAPI.getById,
  "classes"
);
export const deleteThunk = deleteStoreFactory(
  "classes/delete",
  ClassesAPI.delete,
  "classes"
);
export const saveThunk = saveStoreFactory(
  "classes/save",
  ClassesAPI.save,
  "classes"
);
export const updateThunk = updateStoreFactory(
  "classes/update",
  ClassesAPI.update,
  "classes"
);

export const modelSlice = createSlice({
  name: "classes",
  initialState: modelInitialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, _id: action.payload } };
    },
    setName: (state, action) => {
      return {
        ...state,
        model: { ...state.model, name: action.payload },
      };
    },
    setPeriod: (state, action) => {
      return {
        ...state,
        model: { ...state.model, period: action.payload },
      };
    },
    setFrom: (state, action) => {
      return {
        ...state,
        model: { ...state.model, from: action.payload },
      };
    },
    setTo: (state, action) => {
      return {
        ...state,
        model: { ...state.model, to: action.payload },
      };
    },
    setUnit: (state, action) => {
      return {
        ...state,
        model: { ...state.model, unit: action.payload },
      };
    },
    setCourse: (state, action) => {
      return {
        ...state,
        model: { ...state.model, course: action.payload },
      };
    },
    setOrganization: (state, action) => {
      return {
        ...state,
        model: { ...state.model, organization: action.payload },
      };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    setQuery: listReducers.setQuery,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    //
    addFetchListFieldStoreFactoryReducer(builder, fetchUnitListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchCourseListThunk);
  },
});
