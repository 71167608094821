
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ExamsAPI } from "../../../apis/ExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchFilesId = createAsyncThunk(
  "exams/get_files",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state', state);
    const { token } = state.auth;
    console.log('search',state.exams.search_files)
    try {
      return await ExamsAPI.getFiles(
        token,
        params.id,
        params.page,
        state.exams.search_files,
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchFilesIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchFilesId.pending, (state: { loading_files: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_files = true
    })
    .addCase(
      fetchFilesId.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);

        state.files = action.payload.docs;
        state.loading_files = false;

        Object.keys(action.payload)
          .filter((key) => key in state.pagination)
          .forEach((key) => {
            state.pagination[key] = action.payload[key];
          });

      }
    )
    .addCase(
      fetchFilesId.rejected,
      (
        state: { data: any[]; error_code: any; loading_files: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_files = false;
      }
    );
};
