import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setErrorMessage,
  setPage,
  setQuery,
  setExam
} = modelSlice.actions;
