import { modelSlice } from "./slice";

export const {
  setName,
  setDescription,
  setOrganization,
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setErrorMessage,
  setPage,
  
} = modelSlice.actions;
