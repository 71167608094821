import { Box, Typography } from "@material-ui/core";

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className="pt-4"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box>
        <Typography component={"span"}>{children}</Typography>
      </Box>
    </div>
  );
}

export default TabPanel;
