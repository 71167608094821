import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";


import { CorrectionsAPI } from "../../apis/CorrectionAPI";
import { addFetchListFromIdStoreFactoryReducer, fetchListFromIdStoreFactory } from "../_stores/fetchListFromIdStore";

export const fetchExamStudentsListThunk = fetchListFromIdStoreFactory(
  "corrections/list-exam-students",
  CorrectionsAPI.getAllStudentsFromExam,
  "correctionStudents",
);

export const modelSlice = createSlice({
  name: "correctionStudents",
  initialState: modelInitialState,
  reducers: {
    setId: (state: any, action: PayloadAction<string>) => {
      return { ...state, list: { ...state.list, selectedId: action.payload } };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListFromIdStoreFactoryReducer(builder, fetchExamStudentsListThunk);
  },
});
