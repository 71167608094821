import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { STORAGE_URL } from "../../../constants/consts";
import user4 from "../../../assets/images/default.png";

export default function SidebarHeader({ shrunk, handleShrunk }) {
  const { me } = useAppSelector((store) => store.auth);
  console.log(me);
  const navigate = useNavigate();

  
  return (
    <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
            padding: "0.8rem",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            borderRadius: "0.5rem",
            ":hover": {
              backgroundColor: "var(--background-color3)",
            },
          }}
          onClick={() => navigate("/edit-profile")}
        >
          <img
            src={me?.image && me?.image !== "" ? STORAGE_URL + me?.image : user4}
            alt="Usuário"
            style={{
              width: shrunk ? "2rem" : "2.8rem",
              borderRadius: "0.6rem",
              transition: "0.3s ease-in-out",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: shrunk ? "0" : "0.8rem",
                transition: "0.3s ease-in-out",
                color: "var(--text-color2)",
                fontWeight: "bold",
              }}
            >
              Meu perfil
            </span>
            <span
              style={{
                width: shrunk ? "0" : "100%",
                overflow: "hidden",
                transition: "0.3s ease-in-out",
                whiteSpace: "nowrap",
              }}
            >
              {me?.name}
            </span>
          </div>
        </div>

        <MdKeyboardDoubleArrowLeft
          style={{
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
            backgroundColor: "transparent",
            padding: "0.5rem",
            borderRadius: "50%",
            fontSize: "2.8rem",
            transform: shrunk ? "scale(-1, 1)" : "scale(1, 1)",
            transition: "0.3s ease-in-out",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleShrunk}
        />
      </div>
  )
}