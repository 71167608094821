import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";

export const deleteItem = createAsyncThunk(
  "questions/delete",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await QuestionsAPI.delete(token, state.questions.question_id);
    } catch (e: any) {
      console.log(
        `Error deleteItem::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addDeleteItemReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(deleteItem.pending, (state: any, _action: any) => {
      return { ...clearErrorState(state), loading_screen: true };
    })
    .addCase(
      deleteItem.fulfilled,
      (state: { data: any }, action: { payload: { data: { _id: any } } }) => {
        var data = [...state.data];
        var farmId = data.findIndex((x) => x._id === action.payload.data._id);
        if (farmId >= 0) data.splice(farmId, 1);
        return { ...clearErrorState(state), loading_screen: false,data };
      }
    )
    .addCase(deleteItem.rejected, (state: any, action: { payload: any }) => {
      const error = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_screen: false,
      };
    });
};
