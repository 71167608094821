import { store } from ".";

export const clearErrorState = (state: any) => {
  return {
    ...state,
    error_code: null,
    error_data: null,
    error_message: null,
  };
};

export const handleThunkRejection = (e: any, rejectWithValue: any) => {
  let erros: any = [];
  let status = e.status;
  if (status === 401 || status === 403) {
    console.log("Loggin out....");
    store.dispatch({
      type: "auth/logout",
      error: "",
    });
  }
  for (let i = 0; i < e.errors.length; i++) erros.push(e.errors[i].message);

  return rejectWithValue({
    code: status === 404 ? "NOT_FOUND" : "FETCH_ERROR",
    message: erros,
  });
};
