import { FormEvent, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import user4 from "../../assets/images/default.png";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  clearForm,
  setEmail,
  setErrorMessage,
  setModel,
  setName,
  setPassword,
  setPasswordConfirmation,
} from "../../services/stores/editProfileStore/actions";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { update } from "../../services/stores/editProfileStore/async/updateThunk";
import { Attention } from "../../component/base/Attention";
import PasswordInput from "../../component/base/PasswordInput";
import DefaultButton from "../../component/base/DefaultButton";
import { STORAGE_URL } from "../../constants/consts";
import { SaveRounded } from "@material-ui/icons";
import { MdLogout } from "react-icons/md";
import { authSlice } from "../../services/stores/auth/slice";
import Swal from "sweetalert2";
export const { logout } = authSlice.actions;

export default function EditProfileView() {
  const state = useAppSelector((store) => store.editProfile);
  const me = useAppSelector((store) => store.auth.me);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(user4);
  const [avatar_base64, setAvatarBase64] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(update());
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    setAvatar(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarBase64(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    dispatch(setModel(me));
  }, []);

  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={`/`} />} */}

      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card">
            {/* <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Editar Perfil
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Edite seu perfil sistema{" "}
            </p>
            <br /> */}

            <div
              style={{
                display: "flex",
                position: "relative",
                marginBottom: "5rem",
              }}
            >
              <img
                src="https://picsum.photos/1400/300"
                alt="Banner do perfil"
                style={{
                  width: "100%",
                  height: "16rem",
                  objectFit: "cover",
                  borderRadius: "0.6rem",
                  border: "0.1rem solid #ccc",
                }}
              />
              <label
                htmlFor="avatar"
                style={{
                  cursor: "pointer",
                  width: "14rem",
                  aspectRatio: "1/1",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  bottom: "-3rem",
                  left: "1.4rem",
                  border: "0.3rem solid white",
                  overflow: "hidden",
                }}
              >
                <img
                  src={
                    me?.image && me?.image !== ""
                      ? STORAGE_URL + me?.image
                      : avatar_base64
                      ? avatar_base64
                      : avatar
                  }
                  alt="Usuário"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <input
                  type="file"
                  name="avatar"
                  id="avatar"
                  style={{ display: "none" }}
                  onChange={handleAvatarChange}
                />
              </label>
            </div>

            {/* <hr /> */}
            {state.loading_screen == false && (
              <div>
                {state.success_message != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearForm())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {state.success_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {state.error_message != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>

                      <p style={{ marginBottom: 0 }}>{state.error_message}</p>
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => onSubmit(e)}
                  style={{
                    position: "relative",
                    gap: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <Input
                          placeholder="Nome"
                          value={state.model.name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <Input
                          disabled
                          placeholder="E-mail"
                          value={state.model.email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <InputGroup className="col-lg">
                      <PasswordInput
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={state.model.password}
                        OnChangeText={(e) =>
                          dispatch(setPassword(e.target.value))
                        }
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <PasswordInput
                        label="Confirmação de senha"
                        placeholder="Digite a confirmação de senha"
                        className="flex1"
                        value={state.password_confirmation}
                        OnChangeText={(e) =>
                          dispatch(setPasswordConfirmation(e.target.value))
                        }

                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="secondary"
                      disabled={state.loading_image == true}
                      className="flex align-center jcc"
                      onClick={() => {
                        Swal.fire({
                          title: "Tem certeza que deseja trocar de conta?",
                          text: "Você será redirecionado para a página de login",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#70747c",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Sim, trocar!",
                          cancelButtonText: "Cancelar",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate("/");
                            return dispatch(logout(""));
                          }
                        });
                      }}
                    >
                      <MdLogout style={{ fontSize: '1.4rem', marginRight: 10 }} /> Trocar de conta
                    </Button>
                    {!state.loading_save && (
                      <Button
                        variant="success"
                        type="submit"
                        disabled={state.loading_image == true}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    )}
                  </div>

                  {state.loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {state.loading_screen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
