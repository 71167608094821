import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import { OrganizationsAPI } from "../../apis/OrganizationsAPI";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";


export const fetchListThunk = fetchListStoreFactory(
  "organizations/list",
  OrganizationsAPI.getAll,
  "organizations"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "organizations/get",
  OrganizationsAPI.getById,
  "organizations"
);
export const deleteThunk = deleteStoreFactory(
  "organizations/delete",
  OrganizationsAPI.delete,
  "organizations"
);
export const saveThunk = saveStoreFactory(
  "organizations/save",
  OrganizationsAPI.save,
  "organizations"
);
export const updateThunk = updateStoreFactory(
  "organizations/update",
  OrganizationsAPI.update,
  "organizations"
);

export const modelSlice = createSlice({
  name: "organizations",
  initialState: modelInitialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, _id: action.payload } };
    },
    setName: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setDescription: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
  },
});
