import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";
import { screenState } from "../../../../constants/consts";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";

export const deleteItem = createAsyncThunk(
  "teacher-demand-manager-question/delete",
  async (_, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await QuestionsAPI.delete(
        token,
        state.teacherDemandManagerQuestion.list.selectedId
      );
    } catch (e: any) {
      console.log(
        `Error deleteItem::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addDeleteItemReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(deleteItem.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(deleteItem.fulfilled, (state, action) => {
      var all = [...state.list.all];
      var id = all.findIndex((x) => x._id === state.list.selectedId);
      if (id >= 0) all.splice(id, 1);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingScreen: false,
          showWarning: false,
        },
        list: {
          ...state.list,
          all: all,
        },
      };
    })
    .addCase(deleteItem.rejected, (state, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
