import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const deleteStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (_, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      try {
        return await apiFunction(token, state[slice].list.selectedId);
      } catch (e: any) {
        console.log(`Error ${typePrefix} ${e.status} ${e.message}`);
        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};

export const addDeleteStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  deleteStoreFactory: any
) => {
  builder
    .addCase(deleteStoreFactory.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(deleteStoreFactory.fulfilled, (state, action) => {
      var all = [...state.list.all];
      if (action.payload._id !== undefined) {
        var id = all.findIndex((x) => x._id === action.payload._id);
        if (id >= 0) all.splice(id, 1);
      }
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
        list: {
          ...state.list,
          all: all,
        },
      };
    })
    .addCase(deleteStoreFactory.rejected, (state, action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
