import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../component/base/DefaultButton";
import { save } from "../../services/stores/questionStore/async/saveThunk";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setModel,
  setErrorMessage,
  clearState,
  setId,
  setTag,
  setState,
} from "../../services/stores/questionStore/actions";
import { update } from "../../services/stores/questionStore/async/updateThunk";
import { fetchId } from "../../services/stores/questionStore/async/fetchIdThunk";
import { fetchList } from "../../services/stores/disciplineStore/async/fetchListThunk";
import { assign } from "../../services/stores/questionStore/async/assignThunk";

import JoditEditor from "jodit-react";
import { FaListAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { QuestionModalCreate } from "../QuestionModalCreate";
import { QuestionModalView } from "../QuestionModalView";
import { API_URI, STORAGE_URL } from "../../constants/consts";
import { fetchAssignment } from "../../services/stores/questionStore/async/fetchAssignmentThunk";

export function QuestionsCreate(props: {
  editMode?: boolean;
  withAssignment?: boolean;
}) {
  const {
    error_message,
    model,
    tag,
    show_modal_register,
    loading_save,
    loading_screen,
    difficulty_levels,
    degrees,
    question,
    loadings_images,
    number_alternatives,
    corretas,
    alternativa_a,
    alternativa_b,
    alternativa_c,
    alternativa_d,
    alternativa_e,
    file_select,
  } = useAppSelector((store) => store.questions);

  const { token } = useAppSelector((store) => store.auth);

  const { assignment } = useAppSelector((store) => store.questions);
  const { data } = useAppSelector((store) => store.disciplines);
  const dispatch = useAppDispatch();
  const editor_question = useRef<any>();
  const editor_alternative_a = useRef<any>();
  const editor_alternative_b = useRef<any>();
  const editor_alternative_c = useRef<any>();
  const editor_alternative_d = useRef<any>();
  const editor_alternative_e = useRef<any>();
  console.log(assignment);

  const configs_array = useRef<any>();
  const config_question = useRef<any>();
  const config_alternative_a = useRef<any>();
  const config_alternative_b = useRef<any>();
  const config_alternative_c = useRef<any>();
  const config_alternative_d = useRef<any>();
  const config_alternative_e = useRef<any>();

  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);

  const [loading_search, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    console.log("entrou");
    let model_: any = { ...model };
    model_.question = question;
    document.getElementById("text_enunciado")!.innerHTML = question;
    model_.question_content =
      document.getElementById("text_enunciado")!.textContent;
    model_.alternatives = [...model_.alternatives];

    model_.alternatives[0] = { ...model_.alternatives[0] };
    model_.alternatives[0].alternative = alternativa_a;
    model_.alternatives[0].correct = model_.correct == "a";
    document.getElementById("text_enunciado")!.innerHTML = alternativa_a;
    model_.alternatives[0].alternative_content =
      document.getElementById("text_enunciado")!.textContent;

    if (model_.number_alternative >= 2) {
      model_.alternatives[1] = { ...model_.alternatives[1] };
      model_.alternatives[1].alternative = alternativa_b;
      model_.alternatives[1].correct = model_.correct == "b";
      document.getElementById("text_enunciado")!.innerHTML = alternativa_b;
      model_.alternatives[1].alternative_content =
        document.getElementById("text_enunciado")!.textContent;
    }

    if (model_.number_alternative >= 3) {
      model_.alternatives[2] = { ...model_.alternatives[2] };
      model_.alternatives[2].alternative = alternativa_c;
      model_.alternatives[2].correct = model_.correct == "c";

      document.getElementById("text_enunciado")!.innerHTML = alternativa_c;
      model_.alternatives[2].alternative_content =
        document.getElementById("text_enunciado")!.textContent;
    }

    if (model_.number_alternative >= 4) {
      model_.alternatives[3] = { ...model_.alternatives[3] };
      model_.alternatives[3].alternative = alternativa_d;
      model_.alternatives[3].correct = model_.correct == "d";

      document.getElementById("text_enunciado")!.innerHTML = alternativa_d;
      model_.alternatives[3].alternative_content =
        document.getElementById("text_enunciado")!.textContent;
    }

    if (model_.number_alternative >= 5) {
      model_.alternatives[4] = { ...model_.alternatives[4] };
      model_.alternatives[4].alternative = alternativa_e;
      model_.alternatives[4].correct = model_.correct == "e";

      document.getElementById("text_enunciado")!.innerHTML = alternativa_e;
      model_.alternatives[4].alternative_content =
        document.getElementById("text_enunciado")!.textContent;
    }

    dispatch(setModel(model_));

    if (edit == true) {
      dispatch(update()).then((result) => {
        if (result.type === "questions/update/fulfilled") {
          if (props.withAssignment) {
            history("/teacher-demands-manager-questions/" + params.parentId);
          } else history("/questions");
        } else window.scrollTo(0, 0);
      });
    } else {
      dispatch(save()).then((result: any) => {
        if (result.type === "questions/save/fulfilled") {
          if (props.withAssignment) {
            if (!props.editMode)
              dispatch(
                assign({
                  id: params.parentId,
                  question_id: result.payload.data._id,
                })
              );
            history("/teacher-demands-manager-questions/" + params.parentId);
          } else {
            history("/questions");
          }
        } else window.scrollTo(0, 0);
      });
    }
  };

  const load_configs = () => {
    configs_array.current = [];
    configs_array.current.push(config_question);
    configs_array.current.push(config_alternative_a);
    configs_array.current.push(config_alternative_b);
    configs_array.current.push(config_alternative_c);
    configs_array.current.push(config_alternative_d);
    configs_array.current.push(config_alternative_e);
    console.log(token);
    for (let i = 0; i < 6; i++) {
      configs_array.current[i] = {
        height: 300,
        allowResizeTags: ["img", "iframe", "table", "jodit"],
        resizer: {
          // useAspectRatio: false, // don't save,
          // useAspectRatio: ['img'], // save only for images (default value)
          // useAspectRatio: true, // save for all
          showSize: true,
          useAspectRatio: true,
        },
        tabIndex: 1,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        defaultActionOnPasteFromWord: "insert_only_text",
        pasteHTMLActionList: [
          { value: "insert_only_text", text: "Insert only Text" },
        ],
        pasteFromWordActionList: [
          { value: "insert_only_text", text: "Insert only Text" },
        ],
        readonly: loadings_images[i],
        language: "pt_br",
        toolbarButtonSize: "large",
        buttons:
          "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
        uploader: {
          url: API_URI + "/questions/file", //your upload api url
          insertImageAsBase64URI: false, //not inster base64
          imagesExtensions: ["jpg", "png", "jpeg", "gif"],
          headers: { accept: `application/json`, Authorization: token },
          filesVariableName: function (t: any) {
            return "file";
          }, //"files",
          withCredentials: false,
          pathVariableName: "path",
          //   format: 'json',
          method: "POST",
          prepareData: (formdata: any) => {
            console.log(formdata);
            let loadings_ = [...loadings_images];
            loadings_[i] = true;
            dispatch(setState({ loadings_images: loadings_ }));
            return formdata;
          },
          isSuccess: (e: any) => {
            console.log(e);

            return "Upload realizado com sucesso";
          },
          getMessage: (e: any) => {
            console.log(e);

            return e;
          },
          process: (resp: any) => {
            //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
            let files: any[] = [];
            console.log(resp);
            files.unshift(STORAGE_URL + "/" + resp.data);
            let loadings_ = [...loadings_images];
            loadings_[i] = false;
            dispatch(setState({ loadings_images: loadings_ }));

            return {
              files: files,
            };
          },
          error: (elem: any, e: any) => {
            // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

            console.log(e);
            console.log(elem);
            console.log("Bearer " + localStorage.getItem("token"));
          },
          defaultHandlerSuccess: function (resp: any, elem: any) {
            // `this` is the editor.
            let t: any = this;
            if (resp.files && resp.files.length) {
              resp.files.forEach((filename: any, index: any) => {
                //edetor insertimg function
                t.selection.insertImage(filename);
              });
            }

            // debugger;
          },
          defaultHandlerError: (elem: any, e: any) => {
            // elem.j.e.fire('errorMessage', e.message);
            console.log(e);
            console.log(elem);
            // this.setState({ loading_image_enunciado: false });
            let loadings_ = [...loadings_images];
            loadings_[i] = false;
            dispatch(setState({ loadings_images: loadings_ }));
          },
          //   contentType: function (e) {
          //     return (

          //       'application/x-www-form-urlencoded; charset=UTF-8'
          //     );
          //   },
        },
      };
    }
  };

  const location = useLocation();

  useEffect(() => {
    console.log("iniciou");
    dispatch(setState({ loading_screen: true }));
    load_configs();
    dispatch(fetchList({ force: true })).then(() => {
      if (params.id == undefined || (props.withAssignment && !props.editMode)) {
        dispatch(setState({ loading_screen: false }));
      }
    });

    if (props.editMode) {
      console.log("INICIOU chamada");
      dispatch(setId(params.id));
      setEdit(true);
      dispatch(fetchId({ force: true }));
    } else {
      console.log(params);
      if (params.parentId != undefined) {
        console.log("entrou na chamada");
        dispatch(fetchAssignment({ id: params.parentId })).then((data: any) => {
          // dispatch(setTag(assignment?.demand?.keywords))
          let model_: any = { ...model };
          model_.discipline_id = data.payload.discipline._id;
          model_.tags = data.payload.demand.keywords;
          if (
            location.state?.dissertative == undefined ||
            location.state?.dissertative > 0
          )
            model_.type = "text";
          if (
            location.state?.objective == undefined ||
            location.state?.objective > 0
          ) {
            model_.type = "objective";
            setState({ number_alternative: 5 });
          }
          dispatch(setModel(model_));
        });
      }

      let model_: any = { ...model };
      model_.alternatives = [
        { alternative: "", alternative_content: "", correct: false },
        { alternative: "", alternative_content: "", correct: false },
        { alternative: "", alternative_content: "", correct: false },
        { alternative: "", alternative_content: "", correct: false },
        { alternative: "", alternative_content: "", correct: false },
      ];

      if (
        location.state?.dissertative == undefined ||
        location.state?.dissertative > 0
      )
        model_.type = "text";
      if (
        location.state?.objective == undefined ||
        location.state?.objective > 0
      ) {
        model_.type = "objective";
        setState({ number_alternative: 5 });
      }
      dispatch(setModel(model_));
    }

    return () => {
      dispatch(clearState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card">
            <div hidden id="text_enunciado"></div>

            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {edit == true ? "Editar" : "Cadastrar"} Questão
            </h4>

            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.withAssignment
                ? `Cadastre as questões da Demanda ${assignment?.demand?.name}, na Disciplina ${assignment?.discipline?.name}`
                : edit == false
                ? "Cadastre suas questões no sistema"
                : "Edite uma questão do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                {error_message && error_message.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>
                      {error_message.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                {<QuestionModalCreate />}
                {<QuestionModalView />}

                {/* <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />
                        <ModalCadastroQuestao
                            salvarQuestao={(e) => {
                                let questoes = [...this.state.questoes];
                                questoes.push(e);
                                this.setState({ questoes, show_modal_register: false });
                            }}
                            setShowModal={(e) => this.setState({ show_modal_register: e })} showModal={this.state.show_modal_register} categoria={this.state.categoria} /> */}
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Tipo de questão <Attention />
                      </Form.Label>
                      <select
                        className="form-select "
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.type = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        value={model.type}
                      >
                        {(location.state?.objective == undefined ||
                          location.state?.objective > 0) && (
                          <option value={"objective"}>
                            {"Questão objetiva"}
                          </option>
                        )}
                        {(location.state?.objective == undefined ||
                          location.state?.objective > 0) && (
                          <option value={"set"}>
                            {
                              "Conjuntos de questões objetivas com enunciado em comum"
                            }
                          </option>
                        )}
                        {(location.state?.dissertative == undefined ||
                          location.state?.dissertative > 0) && (
                          <option value={"text"}>
                            {"Questão dissertativa"}
                          </option>
                        )}
                      </select>
                    </div>
                  </InputGroup>
                  {(assignment?.demand == undefined ||
                    !props.withAssignment) && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Disciplina <Attention />{" "}
                        </Form.Label>
                        <select
                          className="form-select "
                          onChange={(e) => {
                            let model_ = { ...model };
                            model_.discipline_id = e.target.value;
                            dispatch(setModel(model_));
                            // this.setState({ escolaridade: e.target.value })
                          }}
                          value={model.discipline_id}
                        >
                          <option value={""}>
                            {"Selecione uma disciplina"}
                          </option>

                          {data.map((item: any, id: number) => (
                            <option key={id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                          {/* <option
                                            value={'dissertativa'}
                                        >{'Questão dissertativa'}</option> */}
                        </select>
                      </div>
                    </InputGroup>
                  )}
                  <div className="row">
                    {(model.type == "objective" || model.type == "text") && (
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Nível de Escolaridade <Attention />
                          </Form.Label>
                          <select
                            className="form-select "
                            onChange={(e) => {
                              let model_ = { ...model };
                              model_.degree = e.target.value;
                              dispatch(setModel(model_));
                              // this.setState({ escolaridade: e.target.value })
                            }}
                            value={model.degree}
                          >
                            <option value={""}>
                              {"Selecione um nível de escolaridade"}
                            </option>

                            {degrees.map((item: any, id: number) => (
                              <option key={id} value={item}>
                                {item}
                              </option>
                            ))}
                            {/* <option
                                            value={'dissertativa'}
                                        >{'Questão dissertativa'}</option> */}
                          </select>
                        </div>
                      </InputGroup>
                    )}
                    {(model.type == "objective" || model.type == "text") && (
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Nível de Dificuldade <Attention />
                          </Form.Label>
                          <select
                            className="form-select "
                            onChange={(e) => {
                              let model_ = { ...model };
                              model_.difficulty_level = e.target.value;
                              dispatch(setModel(model_));
                            }}
                            value={model.difficulty_level}
                          >
                            <option value={""}>
                              {"Selecione um nível de dificuldade"}
                            </option>

                            {difficulty_levels.map((item: any, id: number) => (
                              <option key={id} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </InputGroup>
                    )}
                  </div>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Tags </Form.Label>
                      <div>
                        <Input
                          placeholder="Tags da questão para facilitar a busca"
                          value={tag}
                          onChange={(e) => dispatch(setTag(e.target.value))}
                        />

                        {
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginRight: 0 }}
                          >
                            {model.tags.map((item: any, id: any) => (
                              <div
                                key={id}
                                style={{
                                  backgroundColor: "#f2f2f2",
                                  borderRadius: 10,
                                  padding: 5,
                                  width: "auto",
                                  marginTop: 5,
                                  display: "flex",
                                  marginRight: 5,
                                }}
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    textAlign: "center",
                                  }}
                                >
                                  {item}
                                </p>
                                <Close
                                  onClick={() => {
                                    let model_ = { ...model };
                                    model_.tags = [...model.tags].filter(
                                      (item2: any, id2: any) => id2 != id
                                    );
                                    dispatch(setModel(model_));
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "0.9rem",
                                    marginLeft: 3,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        }

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 5,
                          }}
                        >
                          <Button
                            variant="outline-primary"
                            onClick={(e) => {
                              if (tag.trim().length == 0) {
                                return;
                              }
                              let model_: any = { ...model };
                              console.log(model_.tags);
                              model_.tags = [
                                ...model_.tags,
                                encodeURIComponent(
                                  tag.toLowerCase().replace(/[^\w\s]/gi, "-")
                                ).replace(/%20/g, "-"),
                              ];
                              dispatch(setModel(model_));
                              dispatch(setTag(""));
                            }}
                            className="flex jcc align-center"
                          >
                            incluir tag
                          </Button>
                        </div>
                      </div>
                    </div>
                  </InputGroup>

                  {configs_array.current?.length >= 1 &&
                    configs_array.current[0] != undefined && (
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Enunciado <Attention />{" "}
                            {loadings_images[0] == true && (
                              <div
                                className="spinner-border text-primary spinner-border-sm"
                                role="status"
                              ></div>
                            )}
                          </Form.Label>
                          <JoditEditor
                            ref={editor_question}
                            value={question}
                            config={configs_array.current[0]}
                            onChange={(newContent) => {
                              dispatch(setState({ question: newContent }));
                            }}
                            // height={400}
                            // tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => {
                              // let model_: any = { ...model };
                              // model_.question = newContent;
                              // dispatch(setModel(model_));
                              dispatch(setState({ question: newContent }));
                            }} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                          />
                        </div>
                      </InputGroup>
                    )}
                  <br />
                  {model.type == "objective" && (
                    <div>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Número de alternativas <Attention />
                          </Form.Label>
                          <select
                            className="form-select "
                            onChange={(e) => {
                              let model_: any = { ...model };
                              model_.correct = "";
                              model_.number_alternative = e.target.value;
                              model_.alternatives = model_.alternatives.filter(
                                (item: any, id: any) =>
                                  id + 1 <= parseInt(e.target.value)
                              );
                              for (
                                let i: number = model_.alternatives.length;
                                i < parseInt(e.target.value);
                                i++
                              ) {
                                model_.alternatives = [
                                  ...model_.alternatives,
                                  {
                                    alternative: "",
                                    alternative_content: "",
                                    correct: false,
                                  },
                                ];
                              }
                              dispatch(setModel(model_));
                              // this.setState({ numero_alternativa: e.target.value, correta: '' })
                            }}
                            value={model.number_alternative}
                          >
                            {number_alternatives.map((item, id) => (
                              <option key={id} value={id + 1}>
                                {id + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </InputGroup>
                      {configs_array.current?.length >= 2 &&
                        configs_array.current[1] != undefined && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Alternativa a) <Attention />{" "}
                                {loadings_images[1] == true && (
                                  <div
                                    className="spinner-border text-primary spinner-border-sm"
                                    role="status"
                                  ></div>
                                )}
                              </Form.Label>
                              <JoditEditor
                                ref={editor_alternative_a}
                                value={alternativa_a ?? ""}
                                config={configs_array.current[1]}
                                onChange={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })

                                  dispatch(
                                    setState({ alternativa_a: newContent })
                                  );
                                }}
                                onBlur={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })

                                  dispatch(
                                    setState({ alternativa_a: newContent })
                                  );
                                }} // preferred to use only this option to update the content for performance reasons
                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                              />
                            </div>
                          </InputGroup>
                        )}

                      {configs_array.current?.length >= 3 &&
                        configs_array.current[2] != undefined &&
                        model.number_alternative >= 2 && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Alternativa b) <Attention />{" "}
                                {loadings_images[2] == true && (
                                  <div
                                    className="spinner-border text-primary spinner-border-sm"
                                    role="status"
                                  ></div>
                                )}
                              </Form.Label>
                              <JoditEditor
                                ref={editor_alternative_b}
                                value={alternativa_b ?? ""}
                                config={configs_array.current[2]}
                                onChange={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_b: newContent })
                                  );
                                }}
                                onBlur={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_b: newContent })
                                  );
                                }}
                              />
                            </div>
                          </InputGroup>
                        )}

                      {configs_array.current?.length >= 4 &&
                        configs_array.current[3] != undefined &&
                        model.number_alternative >= 3 && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Alternativa c) <Attention />{" "}
                                {loadings_images[3] == true && (
                                  <div
                                    className="spinner-border text-primary spinner-border-sm"
                                    role="status"
                                  ></div>
                                )}
                              </Form.Label>
                              <JoditEditor
                                ref={editor_alternative_c}
                                value={alternativa_c ?? ""}
                                config={configs_array.current[3]}
                                onChange={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_c: newContent })
                                  );
                                }}
                                onBlur={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_c: newContent })
                                  );
                                }}
                              />
                            </div>
                          </InputGroup>
                        )}

                      {configs_array.current?.length >= 5 &&
                        configs_array.current[4] != undefined &&
                        model.number_alternative >= 4 && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Alternativa d) <Attention />{" "}
                                {loadings_images[4] == true && (
                                  <div
                                    className="spinner-border text-primary spinner-border-sm"
                                    role="status"
                                  ></div>
                                )}
                              </Form.Label>
                              <JoditEditor
                                ref={editor_alternative_d}
                                value={alternativa_d ?? ""}
                                config={configs_array.current[4]}
                                onChange={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_d: newContent })
                                  );
                                }}
                                onBlur={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_d: newContent })
                                  );
                                }}
                              />
                            </div>
                          </InputGroup>
                        )}

                      {configs_array.current?.length >= 5 &&
                        configs_array.current[5] != undefined &&
                        model.number_alternative >= 5 && (
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Alternativa e) <Attention />{" "}
                                {loadings_images[5] == true && (
                                  <div
                                    className="spinner-border text-primary spinner-border-sm"
                                    role="status"
                                  ></div>
                                )}
                              </Form.Label>
                              <JoditEditor
                                ref={editor_alternative_e}
                                value={alternativa_e ?? ""}
                                config={configs_array.current[5]}
                                onChange={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_e: newContent })
                                  );
                                }}
                                onBlur={(newContent) => {
                                  // this.setState({ alternativa_a: newContent })
                                  dispatch(
                                    setState({ alternativa_e: newContent })
                                  );
                                }}
                              />
                            </div>
                          </InputGroup>
                        )}

                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Qual a alternativa correta? <Attention />
                          </Form.Label>
                          <select
                            className="form-select "
                            onChange={(e) => {
                              let model_: any = { ...model };
                              model_.correct = e.target.value;
                              dispatch(setModel(model_));
                            }}
                            value={model.correct}
                          >
                            <option value="">
                              Selecione uma anternativa correta
                            </option>
                            {corretas &&
                              corretas.length != 0 &&
                              corretas.map(
                                (value, index) =>
                                  index <= model.number_alternative - 1 && (
                                    <option key={index} value={value["id"]}>
                                      {value["name"]}
                                    </option>
                                  )
                              )}
                          </select>
                        </div>
                      </InputGroup>
                    </div>
                  )}

                  {model.type == "set" && (
                    <div>
                      <br />
                      <InputGroup>
                        <div
                          className="flex justify-content-end flex-wrap mb-4"
                          style={{ width: "100%" }}
                        >
                          <Button
                            onClick={() => {
                              dispatch(setState({ show_modal_register: true }));
                            }}
                            variant="success"
                            className="flex align-center jcc"
                          >
                            <FaListAlt style={{ marginRight: 10 }} />{" "}
                            <p style={{ margin: 0 }}>Adicionar questão</p>
                          </Button>
                        </div>
                      </InputGroup>
                    </div>
                  )}
                  {model.type == "set" && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom col-12">
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Enunciado</th>
                                  {/* <th>Categoria</th> */}
                                  <th>Alternativa Correta</th>

                                  <th data-priority="3">Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {model.questions.map((item: any, id: any) => (
                                  <tr key={id}>
                                    <td>
                                      <p className="text_overflow">
                                        {item.question_content}
                                      </p>
                                    </td>
                                    {/* <td >{item.categoria.name}</td> */}

                                    <td>{item.correct})</td>
                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                    <td>
                                      <span
                                        onClick={() => {
                                          dispatch(
                                            setState({
                                              showModalView: true,
                                              questionSelect: item,
                                            })
                                          );
                                          // this.setState({ questaoSelect: { ...item }, showModal: true });
                                        }}
                                        className="inline-flex align-center jcc bg-warning icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                        }}
                                        title="Gerenciar questão"
                                      >
                                        <BsFillEyeFill
                                          size={17}
                                          color="white"
                                        />
                                      </span>
                                      <span
                                        onClick={(e) => {
                                          let model_ = { ...model };
                                          model_.questions =
                                            model.questions.filter(
                                              (item2, id2) => id != id2
                                            );
                                          dispatch(setModel(model_));
                                        }}
                                        className="inline-flex align-center jcc bg-danger icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                        }}
                                        title="Deletar questão"
                                      >
                                        <MdDelete size={17} color="white" />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {model.questions.length == 0 && (
                          <div className="row w-100">
                            <div className="col-12">
                              <p style={{ textAlign: "center" }}>
                                Nenhuma questão adicionada
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </InputGroup>
                  )}

                  {(model.type == "objective" || model.type == "text") && (
                    <>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Justificativa explicando a resposta <Attention />
                          </Form.Label>
                          <Input
                            placeholder="Justificativa explicando a resposta da questão"
                            value={model.justify_answer_text}
                            onChange={(e) => {
                              let model_ = { ...model };
                              model_.justify_answer_text = e.target.value;
                              dispatch(setModel(model_));
                            }}
                            as="textarea"
                          />
                        </div>
                      </InputGroup>

                      {/* <InputGroup>
                                            <div style={{ width: '100%' }}>

                                                <div className="flex fdcolumn flex1 margin-bottom">
                                                    <Form.Label>Arquivos anexados com justificativa <Attention /></Form.Label>
                                                    <Input
                                                        placeholder="Justificativa explicando a resposta da questão"
                                                        value={file_select}
                                                        multiple
                                                        // accept='.pdf,.doc,.docx,image/*'
                                                        type="file"
                                                        onChange={(e: any) => {
                                                            let event_target: any = e.target;
                                                            let model_: any = { ...model };
                                                            model_.justify_answer_files = [...model_.justify_answer_files, ...event_target.files];
                                                            console.log(model_);

                                                            dispatch(setModel(model_));
                                                        }}
                                                    />
                                                </div>
                                                {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                                                    {model.justify_answer_files.map((item: any, id: any) => (
                                                        <div key={id} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                                                            <p style={{ marginBottom: 0, textAlign: 'center' }}>{item.name}</p>
                                                            <Close onClick={() => {
                                                                let model_ = { ...model };
                                                                model_.justify_answer_files = [...model.justify_answer_files].filter((item2: any, id2: any) => id2 != id);
                                                                dispatch(setModel(model_));
                                                            }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>

                                        </InputGroup> */}
                    </>
                  )}
                  <br />
                  {loading_save == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <Link to={`/accounts/list`}>
                            <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                        </Link> */}
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={
                          props.withAssignment
                            ? "/teacher-demands-manager-questions/" +
                              params.parentId
                            : "/questions"
                        }
                      /> */}
                      <Button
                        variant="secondary"
                        onClick={() => {
                          if (props.withAssignment) {
                            history(
                              "/teacher-demands-manager-questions/" +
                                params.parentId
                            );
                          } else history("/questions");
                        }}
                        className="flex align-center jcc"
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                            {this.state.loading ? <Spinner /> : ""}
                            Registrar usuário
                        </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar questão"
                        disabled={loadings_images.includes(true)}
                        loadingtext="Salvando questão"
                        loading={loading_save}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loadings_images.includes(true)}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
