import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import { LogsAPI } from "../../apis/LogsAPI";

export const fetchListThunk = fetchListStoreFactory(
  "logs/list",
  LogsAPI.getAll,
  "logs"
);

export const modelSlice = createSlice({
  name: "courses",
  initialState: modelInitialState,
  reducers: {

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {

    addFetchListStoreFactoryReducer(builder, fetchListThunk);
  },
});
