export const initialState = {
  me: {
    id: null,
    role: null,
    image: null,
    empresa: {
      hide_questionario: null,
      owner_id: null,
    },
  },
  loading: false,
  logged: undefined,
  status: undefined,
  errorMessage: "",
  errors:[],
  token: null,
  blank:false
};
