import moment from "moment";

export const Demand = {
  _id: "",
  name: "",
  description: "",
  keywords: [],
  keyword: "",
  end_datetime: "",
  endDate: moment().add(1, "day"),
  endTime: moment().add(1, "hour"),
  assignments: [],
};
