
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchDefault = createAsyncThunk(
  "layouts/get_default_id",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state', state);
    const { token } = state.auth;
    try {
      return await LayoutsExamsAPI.getDefaultLayoutId(
        token,
        params.id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchDefaultReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchDefault.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchDefault.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        delete action.payload.registrations;
        delete action.payload._id;
        delete action.payload.updated_at;
        delete action.payload.created_at;
        delete action.payload.organization;
        delete action.payload.owner;
        delete action.payload.selected_disciplines;
        delete action.payload.__v;
        delete action.payload.number_select_questions;
        delete action.payload.default_layout;

        state.model = { ...state.model, 
          ...action.payload
        
        };


        // let questoes_selecionadas: any = {};
        // for (let i = 0; i < action.payload.exam.questions.length; i++) {
        //   questoes_selecionadas['q_' + action.payload.exam.questions[i]._id] = action.payload.exam.questions[i];
        // }
        // state.questions = questoes_selecionadas;
        // state.examSelect = action.payload.exam;

        state.loading_screen = false;
      }
    )
    .addCase(
      fetchDefault.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
