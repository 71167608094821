export const Question = {
  type: "",
  question: "",
  question_content: "",
  number_alternative: "",
  user_id: "",
  degree: "",
  difficulty_level: "",
  discipline_id: "",
  question_id: "",
  correct: "",
  tags: "",
  justify_answer_text: "",
  justify_answer_files: "",
  organization_id: "",
  created_at: "",
  updated_at: "",
  question_father: "",
  owner: "",
  organization: "",
  discipline: "",
  demand: "",
  questions: [],
  alternatives: [],
  exams: [],
};
