import { Navigate, Route, Routes } from "react-router-dom";
import { FirstLoginView } from "../screens/FirstLoginView";

export default function FirstLoginStack() {
  const token = localStorage.getItem("token");
  return (
    <Routes>
      <Route path="/" element={<FirstLoginView />} />
      {<Route path="/*" element={<Navigate to="/" />} />}
    </Routes>
  );
}
