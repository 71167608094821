import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { AssignmentsAPI } from "../../../apis/AssignmentsAPI";
import moment from "moment";
import { modelInitialState } from "../initialState";

export const assign = createAsyncThunk(
  "questions/assign",
  async (params:{id: any, question_id: any}, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await AssignmentsAPI.assign(token, params.question_id, params.id);
    } catch (e: any) {
      console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);
      // return rejectWithValue({
      //   code: "FETCH_ERROR",
      //   message: JSON.stringify(e),
      // });
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addAssignReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(assign.pending, (state: any, _action: any) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(
      assign.fulfilled,
      (state: any, action: { payload: { data: any } }) => {
        var data = [...state.data];
        data.push(action.payload.data);
        return { ...clearErrorState(state), loading_save: false, data: data };
      }
    )
    .addCase(assign.rejected, (state: any, action: { payload: any }) => {
      const error: any = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_save: false,
      };
    });
};
