import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { UsersAPI } from "../../../apis/UsersAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchTeachers = createAsyncThunk(
  "demand-manager/fetch-teachers",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    if (
      !params.force &&
      state.demandsManager.teachers != null &&
      state.demandsManager.teachers.length > 0
    )
      return state.demandsManager.teachers;
    try {
      return await UsersAPI.getAllTeacher(
        token,
        1,
        params.search ?? null,
        false
      );
    } catch (e: any) {
      console.log(
        `Error fetchTeachers::createAsyncThunk ${e.status} ${e.message}`
      );

      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchTeachers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(
      fetchTeachers.pending,
      (state: { loading_screen: boolean }, _action: any) => {
        state.loading_screen = true;
      }
    )
    .addCase(fetchTeachers.fulfilled, (state, action) => {
      state.teachers = action.payload.docs;
    })
    .addCase(fetchTeachers.rejected, (state: any, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
