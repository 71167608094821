import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthAPI } from "../../../apis/AuthAPI";

export const me = createAsyncThunk(
  "auth/me",
  async (_, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    var response = { me: null };
    try {
      const responseMe = await AuthAPI.me(token);
      response = { me: responseMe };
    } catch (e) {
      console.log(`Error me::createAsyncThunk ${e}`);
      return rejectWithValue({
        code: "FETCH_ERROR",
        message: JSON.stringify(e),
      });
    }
    return response;
  }
);

export const addMeReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(me.pending, (state, _action) => {
      state.me = null;
      state.loading = true;
      state.errorMessage = null;
    })

    .addCase(me.fulfilled, (state, action) => {
      state.me = action.payload.me;
      state.loading = false;
      state.errorMessage = null;
    })

    .addCase(me.rejected, (state, action) => {
      state.me = null;
      state.loading = false;
      state.errorMessage = action.error.code ?? "UNDEFINED_REJECTION";
    });
};
