import { useCallback, useEffect, useState } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../../../component/base/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { TextReadMore } from "../../../component/base/TextReadMore";
import moment from "moment";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import {
  setPage,
  setSearch,
  setSelectedId,
  setWarning,
} from "../../../services/stores/teacherDemandManagerQuestionStore/actions";
import TabPanel from "../../../component/base/TabPanel";
import { Grid } from "../../../component/list/grid";
import { fetchList } from "../../../services/stores/teacherDemandManagerQuestionStore/async/fetchListThunk";
import { Alert } from "../../../component/list/alert";
import { deleteItem } from "../../../services/stores/teacherDemandManagerQuestionStore/async/deleteThunk";
import { fetchParent } from "../../../services/stores/teacherDemandManagerQuestionStore/async/fetchParent";
import { setState } from "../../../services/stores/questionStore/actions";
import { QuestionModalView } from "../../QuestionModalView";
import Swal from "sweetalert2";

export function TeacherDemandsManagerQuestions(props: any) {
  const questions = useAppSelector(
    (store) => store.teacherDemandManagerQuestion.list.all
  );
  const parent = useAppSelector(
    (store) => store.teacherDemandManagerQuestion.parent
  );
  const { search, loading } = useAppSelector(
    (store) => store.teacherDemandManagerQuestion.list
  );
  const { showWarning, loadingScreen } = useAppSelector(
    (store) => store.teacherDemandManagerQuestion.screen
  );

  const set_type = useCallback(
    (questions_old: any) => {
      let questions_new: any = [];
      questions_old?.map((item: any, id: any) => {
        let new_item = { ...item };
        new_item.type_formatted =
          new_item.type == "set"
            ? "Conjunto"
            : new_item.type == "text"
            ? "Dissertativa"
            : "Objetiva";
        questions_new.push(new_item);
      });
      return questions_new;
    },
    [questions]
  );

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const history: any = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchParent({ id: params.id, force: true })).then((result) => {});
  }, []);

  const openDemandsManagerQuestionCreate = async (id: any) => {
    const objectiveCount = questions
      ?.map((item: any) => {
        if (item.type == "objective") {
          return 1;
        } else if (item.type == "set") {
          return item.questions.length;
        } else return 0;
      })
      ?.reduce((partialSum: any, a: any) => partialSum + a, 0);
    const dissertativeCount = questions?.filter(
      (data: any) => data.type == "text"
    ).length;
    const objectiveLeft =
      parseInt(parent?.multiple_choice_questions_qty) - objectiveCount;
    const dissertativeLeft =
      parseInt(parent?.essay_questions_qty) - dissertativeCount;
    //
    if (objectiveLeft <=0 && dissertativeLeft <=0) {
      await Swal.fire({
        title: "Demanda completa",
        text: "A demanda já foi atendida. Não é preciso adicionar mais questões.",
        confirmButtonText: "Ok",
        customClass: {
          title: "sweet-alert-title",
          confirmButton: "sweet-alert-confirm-button",
          cancelButton: "sweet-alert-cancel-button",
        },
      });
    } else
      navigate(`/teacher-demands-manager-questions/${id}/create`, {
        state: {
          objective: objectiveLeft,
          dissertative: dissertativeLeft,
        },
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body">
          <Alert
            title="Atenção"
            text={
              "Ao deletar esta questão, ela não estará mais disponível no sistema"
            }
            show_warning={showWarning}
            onCancel={() => dispatch(setWarning(false))}
            onConfirm={() => dispatch(deleteItem())}
          ></Alert>

          <div>
            <div style={{ float: "right" }}>
              <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to={
                  props.withAdmin
                    ? `/demands/${parent.demand?._id}`
                    : `/teacher-demands-manager/${parent.demand?._id}`
                }
              />
            </div>
            <div className="flex jcsb">
              <h4 className="header">
                Questões do professor para {parent.demand?.name}
              </h4>
            </div>

            <TextReadMore
              title="Encerramento: "
              element="div"
              limit={1000}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {moment(parent.demand?.end_datetime).format("DD/MM/YYYY HH:mm")}
            </TextReadMore>
            <p>
              {" "}
              <b>Questões objetivas:</b>{" "}
              {questions
                ?.map((item: any) => {
                  if (item.type == "objective") {
                    return 1;
                  } else if (item.type == "set") {
                    return item.questions.length;
                  } else return 0;
                })
                ?.reduce((partialSum: any, a: any) => partialSum + a, 0)}
              /{parent?.multiple_choice_questions_qty}
            </p>
            <p>
              <b>Questões dissertativas:</b>{" "}
              {questions?.filter((data: any) => data.type == "text").length}/
              {parent?.essay_questions_qty}
            </p>
            {parent.description != undefined && (
              <TextReadMore
                title="Descrição: "
                element="div"
                limit={120}
                style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
              >
                {parent.description ?? ""}
              </TextReadMore>
            )}

            <Tabs
              value={activeTab}
              onChange={(_: any, value: any) => {
                setActiveTab(value);
                history.push({ search: `?tab=${value}` });
              }}
              style={{ backgroundColor: "#ececf1" }}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
            >
              <Tab label="Atribuições" />
              {/* <Tab label="Atribuições de Redações" /> */}
            </Tabs>
            <QuestionModalView />

            <TabPanel value={activeTab} index={0}>
              <Grid
                onChangeSearchInput={(value: any) => {
                  dispatch(setSearch(value));
                }}
                button_text={
                  props.withAdmin == undefined ? "Adicionar questão" : undefined
                }
                hideBtn
                onClickButton={() =>
                  openDemandsManagerQuestionCreate(params.id)
                }
                // button_link={`/teacher-demands-manager-questions/${params.id}/create`}
                search_input_value={search}
                loading_search={false}
                fields={[
                  {
                    name: "question_content",
                    type: "text",
                    header: "Conteúdo",
                  },
                  {
                    name: "type_formatted",
                    type: "text",
                    header: "Tipo de questão",
                  },
                ]}
                items={set_type(questions)}
                onDeleteActionClick={function (id: any): void {
                  dispatch(setSelectedId(id));
                  dispatch(setWarning(true));
                }}
                onEditActionClick={(id) => {
                  navigate(
                    `/teacher-demands-manager-questions/${params.id}/edit/${id}`
                  );
                }}
                not_found_message={"Nenhuma demanda encontrada."}
                loading_screen={loadingScreen}
                search_text={"Pesquisar pela questão ou conteúdo"}
                pagination={{
                  last_page: 1,
                  current_page: 1,
                }}
                onManagerActionClick={(id) => {
                  let item = set_type(questions).filter(
                    (item2: any, id2: any) => item2._id == id
                  )[0];
                  dispatch(
                    setState({ showModalView: true, questionSelect: item })
                  );
                }}
                onChangePagination={function (page: number): void {
                  dispatch(setPage(page));
                }}
                top_text={`Aqui ficam as atribuições da ${parent.demand?.name}`}
                onSearchButtonClick={function (): void {
                  dispatch(setPage(1));
                  dispatch(fetchList({ id: params.id, force: true }));
                }}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}></TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}

const SkeletonCoursePage = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="skeleton-title" />
        {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
        <br />
        <div className="w100 flex jcc align-center">
          <div
            className="skeleton-base skeleton-shimmer"
            style={{ width: "12em", height: "12em" }}
          />
        </div>
        <br />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "48px" }}
        />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "5em" }}
        />
        <div className="skeleton-small-button skeleton-shimmer" />
      </div>
    </div>
  );
};
