
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { UsersAPI } from "../../../apis/UsersAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { AuthAPI } from "../../../apis/AuthAPI";

export const fetchId = createAsyncThunk(
  "users-admin/get",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await AuthAPI.me(
        token,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchId.pending, (state, _action) => {
      clearErrorState(state);
      state.loading = true;
    })
    .addCase(
      fetchId.fulfilled,
      (state, action) => {
        clearErrorState(state);
        state.model = action.payload
        state.loading = false;
      }
    )
    .addCase(
      fetchId.rejected,
      (
        state: { data: any[]; error_code: any; loading: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading = false;
      }
    );
};
