import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { UserRole } from "../../../constants/enum/UserRole";
import { User } from "../../../model/User";
export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: { ...User, role: UserRole.TEACHER_USER,permission:{view_question_exam_organization:false,view_question_list_organization:false} },
};
