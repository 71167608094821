import SweetAlert from "react-bootstrap-sweetalert";

export function Alert(props) {
  return (
    <SweetAlert
      warning
      title={props.title}
      onConfirm={() => props.onConfirm()}
      onCancel={() => props.onCancel()}
      show={props.show_warning}
      confirmBtnText="Sim, desejo apagar"
      cancelBtnText="Cancelar"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      showCancel={true}
    >
      {props.text}
    </SweetAlert>
  );
}
