import axios, { AxiosResponse } from "axios";
import { API_URI } from "../../../constants/consts";
import { store } from "../../stores";

export const api = axios.create({
  withCredentials: true,
  baseURL: API_URI,
});

const responseHandler = (response: AxiosResponse<any, any>) => {
  console.debug(
    `axiosConfig::responseHandler [ ${response.status} ] [ ${JSON.stringify(
      response.data
    )} ]`
  );
  return response;
};

const errorHandler = (error: any) => {
  const statusCode = error?.response?.status;

  if (statusCode)
    console.log(`Error axiosConfig::errorHandler [ ${statusCode} ]`);

  let e: any = {};

  if (error.response.data.error !== undefined) e = error.response.data.error;
  if (error.response.data.message) e.message = error.response.data.message;
  e.status = statusCode;

  // console.log(statusCode);
  // if(parseInt(statusCode)==401 || statusCode==403){
  //   console.log('deslogou')
  //   store.dispatch({
  //     type: "auth/logout",
  //     error: null,
  //   });
  // }

  return Promise.reject(e);
};

api.interceptors.response.use(
  function (response) {
    return responseHandler(response);
  },
  (error) => {
    console.log(error.response?.data);
    return errorHandler(error);
  }
);

api.interceptors.request.use(
  function (config) {
    config.data &&
      config.method &&
      console.log(
        ` ${config.method.toUpperCase()} ${config.url}
         ${JSON.stringify(config?.data)}`
      );
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);
