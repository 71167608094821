
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TestsAPI } from "../../../apis/TestsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchJobId = createAsyncThunk(
  "tests/get_job",
  async (params: any , { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await TestsAPI.getJobs(
        token,
        params.id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchJobIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchJobId.pending, (state: { loading_job: boolean }, _action: any) => {
      clearErrorState(state);
    })
    .addCase(
      fetchJobId.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
       
        state.jobs = action.payload


        state.loading_job = false;
      }
    )
    .addCase(
      fetchJobId.rejected,
      (
        state: { data: any[]; error_code: any; loading_job: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_job = false;
      }
    );
};
