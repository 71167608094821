import { Exam } from "./Exam";
import { Question } from "./Question";
import { User } from "./User";

export const Answer = {
  dissertative_answer: "",
  objective_answer: {alternative_content: ""},
  student: User,
  exam: Exam,
  question: Question,
  corrections: [],
};
