
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchListTagFiltered = createAsyncThunk(
  "exams/listFilteredTag",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await QuestionsAPI.getAllFilteredTags(
        token,
        params.tagSelect,
        "discipline_id="+(state.exams.disciplineSelect._id),
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchListFilteredTagReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchListTagFiltered.pending, (state: { loading_search_tag: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_search_tag = true;
    })
    .addCase(
      fetchListTagFiltered.fulfilled,
      (state: {tagsSearch:any,questionsTag:any,tagSelect:any,questionContent:any,questionsSearch:any, questions:any,data: any; loading_search_tag: boolean;data_filtered:any }, action: { payload: any }) => {
        clearErrorState(state);
          state.tagsSearch = action.payload;
        console.log(state.tagsSearch)
        
        state.loading_search_tag = false;
      }
    )
    .addCase(
      fetchListTagFiltered.rejected,
      (
        state: any,
        action: { payload: any }
      ) => {
        console.log(state)
        state.data = [];
        const error = action.payload.error;
        console.log(error);
        state.error_code = error?.code;
        state.loading_search_tag = false;
      }
    );
};
