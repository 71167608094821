import { useEffect } from "react";

// classes
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Form,
  FormControl,
  FormControl as Input,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import "moment/locale/pt-br";

import {
  fetchCourseListThunk,
  fetchIdThunk,
  fetchUnitListThunk,
  saveThunk,
  updateThunk,
} from "../../../services/stores/classStore/slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearWindowState,
  setErrorMessage,
  setCourse,
  setUnit,
  setPeriod,
  setName,
  setTo,
  setFrom,
} from "../../../services/stores/classStore/actions";
import AsyncSelect from "react-select/async";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SaveRounded } from "@material-ui/icons";

var debounce = require("lodash.debounce");

export function ClassForm(props: { editMode?: boolean }) {
  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.classes.screen
  );
  const { from, to, name } = useAppSelector((store) => store.classes.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const selectedUnit = useAppSelector((store) => store.classes.model.unit);
  const selectedCourse = useAppSelector((store) => store.classes.model.course);
  const period = useAppSelector((store) => store.classes.model.period);

  const searchUnits = (query: any, callback: any) => {
    dispatch(fetchUnitListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchCourses = (query: any, callback: any) => {
    dispatch(fetchCourseListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const saveContent = () => {
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/turmas");
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/turmas");
      });
    }
  };

  useEffect(() => {
    if (props.editMode && params.id) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Turma
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma turma do seu sistema"
                : "Cadastre as turmas do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen == false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Período/Semestre <Attention />
                        </Form.Label>
                        <Input
                          placeholder="Digite o período/semestre"
                          type="number"
                          value={period}
                          onChange={(e) => dispatch(setPeriod(e.target.value))}
                          required
                        />
                      </div>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Curso <Attention />
                        </Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do aluno..."
                          onChange={(e: any) => {
                            dispatch(setCourse(e));
                          }}
                          cacheOptions
                          value={selectedCourse}
                          loadOptions={debounce(searchCourses, 250)}
                        />
                      </div>
                    </InputGroup>
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Unidade <Attention />
                        </Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do aluno..."
                          onChange={(e: any) => {
                            dispatch(setUnit(e));
                          }}
                          cacheOptions
                          value={selectedUnit}
                          loadOptions={debounce(searchUnits, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                    <div className="row">
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom me-3">
                          <Form.Label>
                            Data de início <Attention />
                          </Form.Label>
                          <DatePicker
                            inputVariant="outlined"
                            size="small"
                            autoOk
                            invalidDateMessage="Data inválida"
                            id="time-picker"
                            cancelLabel="Cancelar"
                            clearLabel="Limpar"
                            format="DD/MM/YYYY"
                            value={from}
                            onChange={(date) => dispatch(setFrom(date))}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom me-3">
                          <Form.Label>
                            Data de término <Attention />
                          </Form.Label>
                          <DatePicker
                            inputVariant="outlined"
                            size="small"
                            autoOk
                            invalidDateMessage="Data inválida"
                            id="time-picker"
                            cancelLabel="Cancelar"
                            clearLabel="Limpar"
                            format="DD/MM/YYYY"
                            value={to}
                            onChange={(date) => dispatch(setTo(date))}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </MuiPickersUtilsProvider>
                  {loadingSave == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/turmas"
                      /> */}
                      <Button
                        variant="secondary"
                        onClick={() => history("/turmas")}
                        className="flex align-center jcc"
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar Turma"
                        disabled={loadingSave}
                        loadingtext="Salvando turma"
                        loading={loadingSave}
                      /> */}{" "}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loadingSave}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
