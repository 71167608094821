import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setName,
  setDescription,
  setKeyword,
  setKeywords,
  setEndDate,
  setEndTime,
  setErrorMessage,
  setPage,
} = modelSlice.actions;
