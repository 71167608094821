
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchIdNoAuth = createAsyncThunk(
  "layouts/get_noauth",
  async (params: any , { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state', state);
    const { token } = state.auth;
    try {
      return await LayoutsExamsAPI.getByIdNoAuth(
        token,
        state.layouts.layout_id,
        params.class_id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdNoAuthReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchIdNoAuth.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchIdNoAuth.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        // state.model = { ...state.model, ...action.payload, exam_id: action.payload.exam._id };
        // let questoes_selecionadas: any = {};
        // for (let i = 0; i < action.payload.exam.questions.length; i++) {
        //   questoes_selecionadas['q_' + action.payload.exam.questions[i]._id] = action.payload.exam.questions[i];
        // }
        // state.questions = questoes_selecionadas;
        // state.examSelect = action.payload.exam;

        state.loading_screen = false;
      }
    )
    .addCase(
      fetchIdNoAuth.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
