import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";

import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";

import { CorrectionsAPI } from "../../apis/CorrectionAPI";

export const fetchIdThunk = fetchIdStoreFactory(
  "corrections/get",
  CorrectionsAPI.getById,
  "corrections"
);

export const updateThunk = updateStoreFactory(
  "corrections/update",
  CorrectionsAPI.update,
  "corrections"
);

export const modelSlice = createSlice({
  name: "corrections",
  initialState: modelInitialState,
  reducers: {

    setComment: (state, action) => {
      return { ...state, model: { ...state.model, comment: action.payload } };
    },
    setScore: (state, action) => {
      return { ...state, model: { ...state.model, score: action.payload } };
    },
    setReply: (state, action) => {
      return { ...state, model: { ...state.model, reply: action.payload } };
    },

    setAnnotations: (state, action) => {
      return { ...state, model: { ...state.model, annotations: action.payload } };
    },

    setNewAnnotation: (state, action) => {
      return { ...state, screen: { ...state.screen, new_anotation:action.payload } };
    },
    setAnnotationEdit: (state, action) => {
      return { ...state, screen: { ...state.screen, annotation_edit:action.payload } };
    },
    setShowModalAspect: (state, action) => {
      return { ...state, screen: { ...state.screen, show_modal_aspect:action.payload } };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);    
    addUpdateStoreFactoryReducer(builder, updateThunk);

    
  /*
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    //
    */
    // addFetchListFieldStoreFactoryReducer(builder, fetchExamsListThunk);
    // addFetchListFieldStoreFactoryReducer(builder, fetchCourseListThunk);
    // addFetchListFieldStoreFactoryReducer(builder, fetchStudentListThunk);
  },
});
