import { modelSlice } from "./slice";

export const {
  setSearch,
  setLoadingInput,
  setState,
  setLoadingScreen,
  setWarning,
  setId,
  clearState,
  setLoading,
  setModel,
  setErrorMessage,
  setPage
} = modelSlice.actions;
