import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../services/stores/questionStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { Form, FormControl as Input, InputGroup, Button, Spinner } from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { Close } from '@material-ui/icons';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { RiArrowGoBackLine, RiCloseFill } from 'react-icons/ri';
import DefaultButton from "../../component/base/DefaultButton";
import { save } from "../../services/stores/questionStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import { setState } from "../../services/stores/examStore/actions";
import { update } from "../../services/stores/questionStore/async/updateThunk";
import { fetchId } from "../../services/stores/questionStore/async/fetchIdThunk";
import JoditEditor from 'jodit-react';
import { FaListAlt } from "react-icons/fa";
import { MdDelete, MdSearch } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { API_URI, STORAGE_URL } from "../../constants/consts";
import { CircularProgress } from "@material-ui/core";
import { TableDrag } from "../ExamsView/TableDrag";
import { setState as setStateQuestion } from "../../services/stores/questionStore/actions";
import { fetchListFiltered } from "../../services/stores/examStore/async/fetchListFilteredThunk";
import { fetchListTagFiltered } from "../../services/stores/examStore/async/fetchListTagThunk";

export function ExamModalTag(props: any) {
    const { showModalTag,
        disciplineSelect, tagSelect, loading_input, questionsArrayTag, questionsTag, questions,
        showAutoCompleteTag,tagsSearch,loading_search_tag
    } = useAppSelector(
        (store) => store.exams
    );

    const { token } = useAppSelector(
        (store) => store.auth
    )

    const dispatch = useAppDispatch();
    const timeout_:any = useRef();
    const timeout_search:any = useRef();

    useEffect(() => {
        // const question_id = history.pa


        // dispatch(clearStateModal());
        // setState({ number_alternative: 5 });
        // if (params.id != undefined) {
        //     dispatch(setId(params.id))
        //     setEdit(true);
        //     dispatch(fetchId({ force: true }));

        // }

        return () => {
            // dispatch(setState({tagsSearch:[]}));

        }
    }, []);

    const parseQuestoesDragDrop = () => {
        let questoes: any = [...questionsArrayTag];
        let ids = questoes.map((item: any, id: any) => item._id);
        let ids_exists: any = []


        for (let i = 0; i < Object.values(questionsTag).length; i++) {

            let item: any = Object.values(questionsTag)[i];
            ids_exists.push(item._id);

            if (!ids.includes(item._id)) {
                questoes.push({
                    numero: i + 1,
                    _id: item._id,
                    id: item._id,
                    index: i,
                    enunciado: <p className='text_overflow'>{item.question_content}</p>,
                    categoria: item.discipline.name,
                    tipo_questao: <p style={{ whiteSpace: 'pre' }}>{item.type == 'set' ? ('Conjunto de questões\n' + item.questions.length + (item.questions.length == 1 ? ' questão' : ' questões')) :
                        (item.type == 'objective' ? 'Questão única' : 'Questão dissertativa')}</p>,
                    acoes: i
                });
            }

        }
        questoes = questoes.filter((item: any, id: any) => {
            if (ids_exists.includes(item._id)) {
                return true;
            }
            return false;
        })

        dispatch(setState({ questionsArrayTag: questoes }));
    }


    useEffect(() => {
        parseQuestoesDragDrop()
    }, [questionsTag]);

    useEffect(() => {
        // const question_id = history.pa
        // dispatch(clearStateModal());


    }, [showModalTag]);
    return (

        <Modal
            show={showModalTag == true}
            dialogClassName="modal_width"
            onHide={() => dispatch(setState({ showModalTag: false, tagSelect: '',tagsSearch:[],questionsArrayTag:[],questionsTag:{} }))}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Buscar questões da disciplina {disciplineSelect?.name} por tags<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                {disciplineSelect?.name != undefined && <div >


                    <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Tag <Attention /></Form.Label>
                            <Input
                                disabled={loading_input || loading_search_tag}
                                placeholder="Palavra-chave"
                                value={tagSelect}
                                onChange={(e) => { 
                                    clearTimeout(timeout_search.current);
                                    dispatch(setState({ tagSelect: e.target.value }))
                                    if(e.target.value.length>=1){
                                        timeout_search.current = setTimeout(() => {
                                            if(e.target.value.length>=1){
                                                dispatch(fetchListTagFiltered({force:true,tagSelect:e.target.value})).then((data)=>{
                                                    console.log(data);
                                                })
                                            }
                                        }, 700)

                                    }
                                    else{
                                        dispatch(setState({tagsSearch:[]}));

                                    }
                                   
                                    // this.get_questao_search(e.target.value, this.state.categoria._id); 
                                    // this.setState({ questao_enunciado: e.target.value }) 
                                }}
                                required
                                onKeyPress={(e) => {
                                    // if (e.key == 'Enter') {
                                    //     e.preventDefault();
                                    //     if (loading_input == true) {
                                    //         return;
                                    //     }
                                    //     dispatch(fetchListFiltered({ force: true })).then((data: any) => {
                                    //         console.log(data);
                                    //     })
                                    //     // this.get_questoes_palavra_chave();

                                    // }
                                }}




                                onBlur={() => {
                                    timeout_.current = setTimeout(() => {
                                        // this.setState({ show_autocomplete_questao: false });
                                        dispatch(setState({showAutoCompleteTag:false}));

                                    }, 500)
                                }}
                                onFocus={() => {
                                    clearTimeout(timeout_.current);
                                    dispatch(setState({showAutoCompleteTag:true}));
                                   
                                    // this.setState({ show_autocomplete_questao: true })
                                }}
                                
                            // title="Coloque o nome completo do usuário"
                            />

                            {showAutoCompleteTag == true && tagSelect != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                                {loading_search_tag == false && tagsSearch.map((item: any, id: any) => (
                                    <div key={id} onClick={() => {
                                        // this.select_questao(item)
                                        dispatch(setState({tagSelect:item,showAutoCompleteTag:false}));
                                        dispatch(fetchListFiltered({ force: true,tagSelect:item })).then((data: any) => {
                                            console.log(data);
                                        })
                                        // this.setState({ questao_enunciado: '', show_autocomplete_questao: false, questoes_selecionadas: questoes })
                                    }} className="opcao_autocomplete">
                                        <b>{item}</b>
                                    </div>
                                ))}
                                {loading_search_tag == false && tagsSearch.length == 0 &&
                                    <div className="opcao_autocomplete">
                                        {tagSelect.length==0?'Digite o nome da tag que deseja buscar':' Nenhuma tag encontrada com este termo'}
                                    </div>
                                }
                                {loading_search_tag == true &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div></div>}
                            </div>}
                        </div>
                        <div className="margin-bottom" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                            <a
                                style={{ paddingLeft: 10, cursor: 'pointer' }}
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     if (loading_input == true) {
                                //         return;
                                //     }
                                //     dispatch(fetchListFiltered({ force: true })).then((data: any) => {
                                //         console.log(data);
                                //     })
                                // }}
                            >
                                {loading_input == false && <MdSearch style={{ width: 25, height: 25 }} />}
                                {loading_input == true && <CircularProgress
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        color: 'green'
                                    }}
                                />}
                            </a>
                        </div>


                    </InputGroup>

                    <Form.Label >Questões selecionadas</Form.Label>
                    <br />
                    <Form.Label >Total: <span style={{ fontWeight: 'normal' }}>{Object.values(questionsTag).map((item: any, id: any) => {
                        if (item.type == 'set') {
                            return item.questions.length;
                        }
                        else {
                            return 1;
                        }
                    }).reduce((a, b) => a + b, 0)} {Object.values(questionsTag).map((item: any, id) => {
                        if (item.type == 'set') {
                            return item.questions.length;
                        }
                        else {
                            return 1;
                        }
                    }).reduce((a, b) => a + b, 0) == 1 ? 'questão' : 'questões'}</span></Form.Label>

                    <InputGroup>
                        <TableDrag draggable={true} data={questionsArrayTag}
                            showData={(index: any) => {
                                let questions_array: any = questionsArrayTag;
                                let questao_id = questions_array[index]!._id;
                                let questions_: any = questionsTag;
                                dispatch(setStateQuestion({ showModalView: true, questionSelect: { ...questions_[questao_id] } }))
                            }}
                            deleteData={(index: any) => {
                                let questions_array: any = questionsArrayTag;

                                let questao_id = questions_array[index]._id;
                                let questions_: any = questionsTag;

                                let questoes_selecionadas = { ...questions_ };
                                delete questoes_selecionadas[questao_id];
                                let questoes_array = questions_array.filter((item: any, id: any) => item._id != questao_id);
                                dispatch(setState({ questionsTag: questoes_selecionadas, questionsArrayTag: questoes_array }));
                                // this.setState({ questoes_selecionadas, questoes_array });
                            }}
                            setData={(update_: any) => {
                                if (update_ != undefined) {
                                    dispatch(setState({ questionsArrayTag: update_ }));
                                }
                                else {
                                    console.log(update_)
                                }
                            }} />



                    </InputGroup>


                </div>}

            </Modal.Body>

            <Modal.Footer>
                <div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                            <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                        </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            dispatch(setState({ showModalTag: false, tagSelect: '' }))
                            return undefined

                            // setShowModal(false);
                            // setSalasSelecionadas({});
                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                            {this.state.loading ? <Spinner /> : ""}
                            Registrar usuário
                        </Button> */}
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            let questions_: any = { ...questions };
                            Object.values(questionsTag).map((item: any, id: any) => {
                                questions_[item._id] = item;
                            })
                            dispatch(setState({ questionsTag: {}, questionsArrayTag: [], tagSelect: '', questions: questions_, showModalTag: false }))
                            return undefined
                        }}
                        bg="confirm"
                        text="Incluir questões"
                        loadingtext="Incluindo questões"
                    />
                </div>
            </Modal.Footer>

        </Modal>

    );
}
