import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { DemandsAPI } from "../../../apis/DemandsAPI";
import { handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchId = createAsyncThunk(
  "demands/get",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      console.log('state2', state)

      return await DemandsAPI.getById(
        token,
        params.id ?? state.demands.list.selectedId,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchId.pending, (state, _action) => {
      console.log('state', state)
      state.screen.loadingScreen = true;

    })
    .addCase(fetchId.fulfilled, (state, action) => {
      return {
        ...state,
        model: {
          ...action.payload,
          endDate: moment(action.payload.end_datetime),
          endTime: moment(action.payload.end_datetime),
        },
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
      };
    })
    .addCase(fetchId.rejected, (state, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
