import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
  screenState,
} from "../../../constants/consts";

import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";

import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";

import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";

import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";

import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import { RegistrationsAPI } from "../../apis/RegistrationsAPI";
import {
  addFetchListFieldStoreFactoryReducer,
  fetchListFieldStoreFactory,
} from "../_stores/fetchListFieldStore";
import { ClassesAPI } from "../../apis/ClassesAPI";
import { UsersAPI } from "../../apis/UsersAPI";

export const fetchClassListThunk = fetchListFieldStoreFactory(
  "registrations/list-units",
  ClassesAPI.getAll,
  "registrations",
  "units"
);

export const fetchStudentListThunk = fetchListFieldStoreFactory(
  "registrations/list-students",
  UsersAPI.getAllStudent,
  "registrations",
  "students"
);

export const fetchListThunk = fetchListStoreFactory(
  "registrations/list",
  RegistrationsAPI.getAll,
  "registrations"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "registrations/get",
  RegistrationsAPI.getById,
  "registrations"
);
export const deleteThunk = deleteStoreFactory(
  "registrations/delete",
  RegistrationsAPI.delete,
  "registrations"
);
export const saveThunk = saveStoreFactory(
  "registrations/save",
  RegistrationsAPI.save,
  "registrations"
);
export const saveAllThunk = saveStoreFactory(
  "registrations/save-class",
  RegistrationsAPI.saveClass,
  "registrations"
);
export const updateThunk = updateStoreFactory(
  "registrations/update",
  RegistrationsAPI.update,
  "registrations"
);

export const modelSlice = createSlice({
  name: "registrations",
  initialState: modelInitialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, _id: action.payload } };
    },
    setActive: (state, action) => {
      return {
        ...state,
        model: { ...state.model, active: !state.model.active },
      };
    },
    setStudent: (state, action) => {
      return {
        ...state,
        model: { ...state.model, student: action.payload },
      };
    },
    setClass: (state, action) => {
      return {
        ...state,
        model: { ...state.model, class: action.payload },
      };
    },
    setClass2: (state, action) => {
      return {
        ...state,
        model: { ...state.model, class2: action.payload },
      };
    },
    clearErrorState: (state) => {
      return {
        ...state,
        screen: screenState,
      };
    },
    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    setQuery: listReducers.setQuery,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    //
    addFetchListFieldStoreFactoryReducer(builder, fetchClassListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchStudentListThunk);
  },
});
