import moment from 'moment';
import { paginationState } from '../../../constants/consts';

export const modelInitialState = {
    redirect: false,
    path: "/exams",
    loading_screen: false,
    data: [],
    pagination: paginationState,

    examSelect:{id:''},
    exam_id:null,
    msgErro: "",
    show_warning: false,
    show_erro: false,
    search: "",
    loading_search: false,
    showModal: false,
    loading_input:false,
    showAutoCompleteQuestion:false,
    showModalTag:false,
    disciplineSelect:{_id:'',name:''},
    tagSelect:'',
    questionsArrayTag:[],
    questionsTag:{},
    questionContent:'',
    questionsSearch:[],
    questionsArray:[],
    questions:{},
    model:{
      name:'',
      type:'objective',
      description:'',
      questions:[],
      exam_father_id:'',
      application_id:'',
      random_question: false,
      random_per_category: false,
      select_from_group: false,
      selected_disciplines: [],
      number_select_questions: 0,
      random_alternatives: false,
    },
    showAutoCompleteTag:false,
    tagsSearch:[],
    loading_search_tag:false,
    jobs:[],
    file:'',
    fileValue:'',
    search_files:'',
    loading_zip:false,
    jobId:'',
    progress:0,
    messageProgress:'',
    loading_save:false,
    error_message: null,
    loading_job:false,
    loading_files:false,
    files:[],
    examsSelecteds:{},
    exam_text:'',
    examSelected:{},
    examSelectedVersion:{_id:''},
    exam_text_version:'',
};
