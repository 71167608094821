import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import { DashboardAPI } from "../../apis/DashboardAPI";
import { handleThunkRejection } from "../utils";
import {
  DashboardResponse,
  InitialState,
} from "../../../model/app/DashboardResponse";
import { Widgets } from "@material-ui/icons";

export const fetchId = createAsyncThunk(
  "dashboard/get",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await DashboardAPI.getById(token, params.id, null, false, params.from, params.to);
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchId.pending, (state, action) => {
      if (state.widgets !== undefined) {
        const idx = state.widgets?.findIndex(
          (x: DashboardResponse) => x.id == action.payload
        );
        if (idx >= 0) state.widgets[idx].loading = true;
      }
    })
    .addCase(fetchId.fulfilled, (state, action) => {
      if (state.widgets !== undefined) {
        const idx = state.widgets.findIndex(
          (x: DashboardResponse) => x.id == action.payload.id
        );
        if (idx >= 0) {
          state.widgets[idx] = {
            ...state.widgets[idx],
            ...action.payload,
            loading: false,
          };
        }
      }
    })

    .addCase(fetchId.rejected, (state, action: any) => {
      console.log(JSON.stringify(action));
      return state;
    });
};
