import { FaExternalLinkAlt } from "react-icons/fa";
import style from "./style.module.scss";

type TableSectionProps = {
  widgets: any; // replace 'any' with the actual type of 'widgets'
};

export default function TableSection({ widgets }: TableSectionProps) {
  const widgetsFiltered = widgets.filter((x: any) => x.type == "table");
  return (
    <div
      style={{
        width: "100%",
        height: "max-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          color: "#4f4f4f",
        }}
      >
        Tabelas
      </h4>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2rem",
          marginTop: "1rem",
        }}
      >
        {widgetsFiltered.map((widget: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#ececf1",
                border: "1px solid #d0d0d6",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "0.5rem",
                flexGrow: 1,
              }}
            >
              <div
                style={{ fontWeight: "bold", margin: "1rem 2rem 1rem 2rem" }}
              >
                {widget.title}
              </div>
              <table
                className={style.dashboardTable}
                style={{
                  marginBottom: "0",
                }}
              >
                <thead>
                  <tr>
                    {widget.labels.map((column: any) => (
                      <th>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {widget.values.map((row: any) => (
                    <tr>
                        {widget.keys.map((column: any) => (
                            <td>{row[column]}</td>
                          ))}
                    </tr>
                  ))}
                  {widget.values.length === 0 && (
                    <tr>
                      <td colSpan={widget.labels.length}>Não há informações</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
}
