import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { UserRole } from "../../../constants/enum/UserRole";
import { Class } from "../../../model/Class";
import { User } from "../../../model/User";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: {
    ...User,
    role: UserRole.STUDENT_USER,
    class: Class,
    add_class: false,
  },
};
