import { Organization } from "./Organization";
import { User } from "./User";

export const Exam = {
  description: "",
  questions: [],
  owner: User,
  organization: Organization,
  answers:[],
};
