import { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../services/stores/questionStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { List } from "../../component/list/list";
import { useNavigate } from "react-router-dom";
import { setId, setState, setWarning } from "../../services/stores/questionStore/actions";
import { QuestionModalView } from "../QuestionModalView";
import { deleteItem } from "../../services/stores/questionStore/async/deleteThunk";
import { setSearch } from "../../services/stores/questionStore/actions";

export function QuestionsView() {
  const questions = useAppSelector((store) => store.questions.data);
  const { search } = useAppSelector((store) => store.questions);
  const dispatch = useAppDispatch();

  const show_warning = useAppSelector((store) => store.questions.show_warning);
  const loading_screen = useAppSelector((store) => store.questions.loading_screen);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const set_type = useCallback((questions_old: any) => {
    let questions_new: any = [];
    questions_old.map((item: any, id: any) => {
      let new_item = { ...item };
      new_item.type_formatted = new_item.type == 'set' ? 'Conjunto' : (new_item.type == 'text' ? 'Dissertativa' : 'Objetiva');
      questions_new.push(new_item);
    })
    return questions_new;
  }, [questions])

  useEffect(() => {
    dispatch(fetchList({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <QuestionModalView />
      <List
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Questões"}
        button_text="Adicionar questão"
        button_link="/questions/create"
        search_text="Pesquise pelo enunciado da questão"
        not_found_message={"Nenhuma questão encontrada"}
        deleting_message={
          'Ao deletar esta questão, ela não estará mais disponível no sistema'
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem())
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => { navigate('/questions/edit/' + id) }}
        onManagerActionClick={(id) => {
          let item = set_type(questions).filter((item2:any,id2:any)=>item2._id==id)[0];
          dispatch(setState({ showModalView: true, questionSelect: item }))
        }}
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true }));
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        pronoun='a'

        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={set_type(questions)}
        pagination={{ last_page: last_page, current_page: current_page }}
        fields={[
          { name: "question_content", type: "text", header: "Enunciado" },
          { name: "type_formatted", type: "text", header: "Tipo de questão" },
          { name: "owner.name", type: "text", header: "Cadastrado por" },
        { name: "created_at", type: "date", header: "Criado em" },

        ]}
      ></List>
    </div>
  );
}
