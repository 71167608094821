import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/slice";
import { AuthAPI } from "../apis/AuthAPI";
import { modelSlice as questionSlice } from "./questionStore/slice";
import { modelSlice as demandSlice } from "./demandStore/slice";
import { modelSlice as demandManagerSlice } from "./demandManagerStore/slice";
import { modelSlice as disciplineSlice } from "./disciplineStore/slice";
import { modelSlice as userAdminSlice } from "./userAdminStore/slice";
import { modelSlice as examsSlice } from "./examStore/slice";

import { modelSlice as userColaboratorSlice } from "./userColaboratorStore/slice";
import { modelSlice as userTeacherSlice } from "./userTeacherStore/slice";
import { modelSlice as userStudentSlice } from "./userStudentStore/slice";
import { modelSlice as editProfileSlice } from "./editProfileStore/slice";
import { modelSlice as teacherDemandSlice } from "./teacherDemandStore/slice";
import { modelSlice as teacherDemandManagerSlice } from "./teacherDemandManagerStore/slice";

import { modelSlice as organizationSlice } from "./organizationStore/slice";
import { modelSlice as loginSlice } from "./loginStore/slice";
import { modelSlice as layoutsSlice } from "./layoutExamStore/slice";
import { modelSlice as studentExamsSlice } from "./studentExamsStore/slice";
import { modelSlice as unitSlice } from "./unitStore/slice";
import { modelSlice as courseSlice } from "./courseStore/slice";
import { modelSlice as classSlice } from "./classStore/slice";
import { modelSlice as registrationSlice } from "./registrationStore/slice";
import { modelSlice as correctionExamSlice } from "./correctionExamStore/slice";
import { modelSlice as correctionStudentSlice } from "./correctionStudentStore/slice";
import { modelSlice as correctionQuestionSlice } from "./correctionQuestionStore/slice";
import { modelSlice as correctionSlice } from "./correctionStore/slice";
import { modelSlice as dashboardSlice } from "./dashboardStore/slice";
import { modelSlice as logSlice } from "./logStore/slice";
import { modelSlice as testSlice } from "./testStore/slice";


import { modelSlice as teacherDemandManagerQuestionSlice } from "./teacherDemandManagerQuestionStore/slice";
import { setTokenAndMe, logout } from "./auth/actions";
import { uploadExamSlice } from "./uploadExam/slice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    login: loginSlice.reducer,
    editProfile: editProfileSlice.reducer,
    questions: questionSlice.reducer,
    demands: demandSlice.reducer,
    teacherDemands: teacherDemandSlice.reducer,
    teacherDemandsManager: teacherDemandManagerSlice.reducer,
    teacherDemandManagerQuestion: teacherDemandManagerQuestionSlice.reducer,
    demandsManager: demandManagerSlice.reducer,
    disciplines: disciplineSlice.reducer,
    organizations: organizationSlice.reducer,
    userAdmin: userAdminSlice.reducer,
    userColaborator: userColaboratorSlice.reducer,
    userTeacher: userTeacherSlice.reducer,
    userStudent: userStudentSlice.reducer,
    exams: examsSlice.reducer,
    layouts: layoutsSlice.reducer,
    studentExams: studentExamsSlice.reducer,
    units: unitSlice.reducer,
    courses: courseSlice.reducer,
    classes: classSlice.reducer,
    corrections: correctionSlice.reducer,
    registrations: registrationSlice.reducer,
    correctionExams: correctionExamSlice.reducer,
    correctionStudents: correctionStudentSlice.reducer,
    correctionQuestions: correctionQuestionSlice.reducer,
    dashboard: dashboardSlice.reducer,
    logs: logSlice.reducer,
    uploadExam: uploadExamSlice.reducer,
    tests: testSlice.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const getAsyncStorage = () => {
  console.log("store::getAsyncStorage");
  return (dispatch) => {
    let token = localStorage.getItem("token");

    if (token) {
      AuthAPI.me(token)
        .then((result) => {
          dispatch(setTokenAndMe({ token: token, me: result }));
        })
        .catch((e) => {
          console.log(
            `Error getAsyncStorage::AuthAPI::me ${JSON.stringify(e)}`
          );
          if (
            (e.errors !== undefined && e.errors[0].status === 401) ||
            (e.errors !== undefined && e.errors[0].status === 403) ||
            e.status == 401 ||
            e.status == 403 ||
            e == 403
          ) {
            console.log("Loggin out....");
            dispatch(logout());
          }
        });
    } else {
      console.log(`Error getAsyncStorage::getItem::result`);
      dispatch(logout());
    }
  };
};

store.dispatch(getAsyncStorage());
