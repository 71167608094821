import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const fetchListStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (params: any = { force: false }, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      if (
        !params.force &&
        state[slice].all != null &&
        state[slice].all.length > 0
      )
        return state[slice].all;
      try {
        return await apiFunction(
          token,
          state[slice].pagination.page,
          state[slice].list.search,
          false,
          state[slice].list.query,
        );
      } catch (e: any) {
        console.log(
          `Error fetchList::createAsyncThunk ${e.status} ${e.message}`
        );

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};
export const addFetchListStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  fetchListStoreFactory: any
) => {
  builder
    .addCase(fetchListStoreFactory.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(fetchListStoreFactory.fulfilled, (state, action) => {
      state.list.all = action.payload.docs;
      state.screen.loadingScreen = false;

      Object.keys(action.payload)
        .filter((key) => key in state.pagination)
        .forEach((key) => {
          state.pagination[key] = action.payload[key];
        });
    })
    .addCase(fetchListStoreFactory.rejected, (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          all: [],
        },
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
