import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addFetchIdReducers } from "./async/fetchIdThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";

import { setChildVisible } from "./sync/setChildVisible";

import moment from "moment";

export const modelSlice = createSlice({
  name: "users",
  initialState: modelInitialState,
  reducers: {
    setChildVisible: (state, action) => setChildVisible(state, action),
    setId: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, _id: action.payload } };
    },
    setName: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setEmail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, email: action.payload },
      };
    },
    setModel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading_screen: false,
        success_message: "",
        error_message: "",
        model: { ...action.payload, password: "" },
      };
    },
    setPassword: (state, action: PayloadAction<string>) => {
      let passwordError = false;
      if (state.model.password != action.payload) passwordError = true;

      return {
        ...state,
        success_message: "",
        error_message: passwordError ? "Senha não confere" : "",
        model: { ...state.model, password: action.payload },
      };
    },
    setPasswordConfirmation: (state, action: PayloadAction<string>) => {
      let passwordError = false;

      if (state.model.password !== action.payload) passwordError = true;

      return {
        ...state,
        success_message: "",
        error_message: passwordError ? "Senha não confere" : "",
        password_confirmation: action.payload,
      };
    },
    setErrorMessage: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        model: { ...state.model, error_message: action.payload },
      };
    },
    clearForm: (state: any) => {
      return {
        ...state,
        error_message: "",
        success_message: "",
        loading_save: false,
        model: {
          _id: "",
          name: "",
          description: "",
        },
      };
    },
  },
  extraReducers: (builder) => {
    addFetchIdReducers(builder);
    addUpdateReducers(builder);
  },
});
