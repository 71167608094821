import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import moment from "moment";

export const updateClasses = createAsyncThunk(
  "layouts/update-classes",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
     
      return await LayoutsExamsAPI.updateClasses(token, state.layouts.layout_id,{
        classes:Object.keys(state.layouts.students)
      });
    } catch (e: any) {
      console.log(
        `Error update::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addUpdateClassesReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(updateClasses.pending, (state, _action) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(updateClasses.fulfilled, (state, action) => {
      // var data = [...state.data];
      // console.log(action.payload)
      // var farmId = data.findIndex((x) => x._id === action.payload.data._id);
      // if (farmId >= 0) data[farmId] = action.payload.data;
      return { ...clearErrorState(state), loading_save: false };
    })
    .addCase(updateClasses.rejected, (state: any, action: { payload: any }) => {
      const error = action.payload;
      console.log(error)
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_save: false,
      };
    });
};
