import moment from "moment";
import { Class } from "./Class";
import { User } from "./User";

export const Registration = {
  _id: "",
  active: true,
  class: Class,
  class2: Class,
  student: User,
};
