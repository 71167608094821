import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdDelete, MdModeEdit } from "react-icons/md";
import moment from "moment";
import { Actions } from "./actions";
import { Searchbar } from "./searchbar";
import { CircularProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

export function Grid(props: {
  not_found_message: string;
  loading_search: boolean;
  loading_screen: boolean;
  button_text?: String;
  button_link?: String;
  component?: any;
  showAuxiliar1?: boolean;
  top_text?: String;
  search_text: String;
  search_input_value: any;
  fields: any[];
  items: any[];
  pagination: { last_page: number; current_page: number };
  onDeleteActionClick?: (id: any) => void;
  onEditActionClick?: (id: any) => void;
  onManagerActionClick?: (id: any) => void;
  onChangePagination: (page: number) => void;
  onChangeSearchInput: (value: any) => void;
  onSearchButtonClick: () => void;
  onAuxiliar1Click?:(value: any) => void;
  onClickButton?: any;

  hideManager?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hideBtn?: boolean;
}) {
  return (
    <div className="row">
      <div className="col-12">
        {/* <hr /> */}
        {props?.loading_screen == false && (
          <div>
            {props?.top_text && <p className="text-muted">{props.top_text}</p>}

            <div>
              <Searchbar
                component={props.component}
                search_input_value={props?.search_input_value}
                button_text={props?.button_text}
                button_link={props?.button_link}
                search_text={props?.search_text}
                hide_btn={props?.hideBtn}
                onClickButton={props.onClickButton}
                onChangeSearchInput={function (value: any): void {
                  props.onChangeSearchInput(value);
                }}
                onSearchButtonClick={() => {
                  props.onSearchButtonClick();
                }}
              ></Searchbar>
            </div>
            <div>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0 "
                  data-pattern="priority-columns"
                  style={{
                    border: "1px solid #d6d6d6",
                    borderRadius: "0.5rem",
                  }}
                >
                  <table id="tech-companies-1" className="table table-striped" style={{
                          borderRadius: "2rem",
                        }}>
                    <thead>
                      <tr>
                        {props?.fields?.map((item: any, id: any) => (
                          <th key={id}>{item.header}</th>
                        ))}
                        <th data-priority="3">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.loading_search == false &&
                        props?.items != undefined &&
                        props?.items?.map(
                          (
                            item: {
                              [x: string]: moment.MomentInput;
                              id: any;
                            },
                            id: any
                          ) => (
                            <tr key={id}>
                              {props?.fields.map(
                                (
                                  field: {
                                    type: string;
                                    name: string | number;
                                  },
                                  field_id: any
                                ) =>
                                  field.type === "text" ? (
                                    <td key={field_id}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item[field.name]?.toString() ?? "",
                                        }}
                                      ></div>
                                    </td>
                                  ) : field.type === "date" ? (
                                    <td key={field_id}>
                                      {item[field.name]
                                        ? moment(item[field.name]).format(
                                            "DD/MM/YYYY"
                                          )
                                        : ""}
                                    </td>
                                  ) : (
                                    "[undefined type]"
                                  )
                              )}

                              <td>
                                <Actions
                                  onAuxiliar1Click={() => {
                                    if (props?.onAuxiliar1Click)
                                      props?.onAuxiliar1Click(item._id);
                                  }}
                                  showAuxiliar1 = {props.showAuxiliar1}
                                  actionItem={item}
                                  onEditClick={() => {
                                    if (props?.onEditActionClick)
                                      props?.onEditActionClick(item._id);
                                  }}
                                  onDeleteClick={() => {
                                    if (props?.onDeleteActionClick)
                                      props?.onDeleteActionClick(item._id);
                                  }}
                                  onManagerClick={() => {
                                    if (props?.onManagerActionClick)
                                      props?.onManagerActionClick(item._id);
                                  }}
                                  hideManager={props.hideManager}
                                  hideEdit={props.hideEdit}
                                  hideDelete={props.hideDelete}
                                ></Actions>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              {props?.loading_search && (
                <div className="row mt-3" style={{ margin: 0 }}>
                  {" "}
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </div>
                </div>
              )}
              {props?.loading_search == false && props?.items?.length == 0 && (
                <div className="row w-100 mt-3">
                  <div className="col-12">
                    <p style={{ textAlign: "center" }}>
                      {props?.not_found_message}
                    </p>
                  </div>
                </div>
              )}

              <Pagination
                className={props?.pagination.last_page == 1 ? "hide" : ""}
                style={{ display: "flex", justifyContent: "flex-end" }}
                count={props?.pagination.last_page}
                page={props?.pagination.current_page}
                onChange={(_, page) => {
                  props?.onChangePagination(page);
                }}
              />
            </div>
          </div>
        )}
        {props?.loading_screen && (
          <div className="row">
            {" "}
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
