import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addDeleteItemReducers } from "./async/deleteThunk";
import { addSaveReducers } from "./async/saveThunk";
import { addFetchListReducers } from "./async/fetchListThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";
import { setChildVisible } from "./sync/setChildVisible";
import moment from "moment";
import { addFetchIdReducers } from "./async/fetchIdThunk";
import { addFetchListFilteredReducers } from "./async/fetchListFilteredThunk";
import { addFetchIdNoAuthReducers } from "./async/fetchIdNoAuthThunk";
import { addFetchCourses } from "./async/fetchCourseThunk";
import { addFetchUnits } from "./async/fetchUnitThunk";
import { addFetchStudent } from "./async/fetchStudentThunk";
import { addUpdateClassesReducers } from "./async/updateClassesThunk";
import { addDeleteClassItemReducers } from "./async/deleteClassThunk";
import { addFetchDownloadReducers } from "./async/fetchDownload";
import { addFetchDefaultReducers } from "./async/fetchDefaultIdThunk";
import { addFetchDefaultListReducers } from "./async/fetchDefaultListThunk";
import { addAddCorrector } from "./async/addCorrectorThunk";
import { addFetchTeacher } from "./async/fetchTeacherThunk";
import { addRemoveCorrector } from "./async/removeCorrectorThunk";

export const modelSlice = createSlice({
  name: "layoutList",
  initialState: modelInitialState,
  reducers: {
    setChildVisible: (state, action) => setChildVisible(state, action),
    setModel: (state, action: PayloadAction<any>) => {
      return { ...state, model: action.payload };
    },
    setState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setLoadingInput: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_input: action.payload };
    },

    setLoadingScreen: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_screen: action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_save: action.payload };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      return { ...state, search: action.payload };
    },
    setErrorMessage: (state, action: PayloadAction<any>) => {
      return { ...state, error_message: action.payload };
    },
    setId: (state, action: PayloadAction<any>) => {
      return { ...state, layout_id: action.payload };
    },
    setWarning: (state, action: PayloadAction<any>) => {
      return { ...state, show_warning: action.payload };
    },
    setCorrectorWarning: (state, action: PayloadAction<any>) => {
      return { ...state, show_corrector_warning: action.payload };
    },
    clearState: (state: any) => {
      return { ...state, ...modelInitialState };
    },
  },
  extraReducers: (builder) => {
    addDeleteClassItemReducers(builder);
    addFetchListReducers(builder);
    addFetchListFilteredReducers(builder);
    addFetchIdReducers(builder);
    addDeleteItemReducers(builder);
    addSaveReducers(builder);
    addUpdateReducers(builder);
    addUpdateClassesReducers(builder);
    addFetchIdNoAuthReducers(builder);
    addFetchCourses(builder);
    addFetchUnits(builder);
    addFetchStudent(builder);
    addFetchDownloadReducers(builder);
    addFetchDefaultReducers(builder);
    addFetchDefaultListReducers(builder);

    addFetchTeacher(builder);
    addAddCorrector(builder);
    addRemoveCorrector(builder);
  },
});
