import moment from "moment";
import { STORAGE_URL } from "../../../constants/consts";

export const modelInitialState = {
  redirect: false,
  path: "/layouts_exams",
  loading_screen: false,
  data: [],
  pagination: {
    page: 1,
    last_page: 1,
  },
  layout_id: null,
  msgErro: "",
  show_warning: false,
  show_corrector_warning:false,
  show_erro: false,
  search: "",
  loading_search: false,
  showModal: false,
  loading_input: false,
  loadings_images: [false, false, false, false, false, false],

  showAutoCompleteQuestion: false,
  showModalTag: false,
  loading_pdf: false,
  disciplineSelect: { _id: "", name: "" },
  tagSelect: "",
  questionsArrayTag: [],
  questionsTag: {},
  questionContent: "",
  questionsSearch: [],
  questionsArray: [],
  questions: {},
  generate_pdf_image: false,
  loading_page: false,
  exams: [],
  pages: [],
  pages_new: [],
  offsetHeight: 0,
  examSelect: { _id: "", questions: [] },
  model: {
    codeExam: "",
    name: "",
    logo_path: "",
    logo: "",
    logo_bottom: "",
    logo_path_bottom: "",
    title: "",
    subtitle: "",
    exam_id: "",
    title_field_1: "",
    field_1: "",
    title_field_2: "",
    field_2: "",
    title_header: "",
    subtitle_header: "",
    rules: "",
    fontSizeRules: 13,
    fontSizeQuestions: 13,
    fontSizeAlternatives: 13,
    numberColumns: 2,
    numberColumnsRules: 1,
    showDiscipline: true,
    marginInternalRight: 40,
    marginInternalLeft: 40,
    marginInternalUp: 14,
    marginInternalDown: 40,
    border_visible: true,
    default_layout: false,

    title_answer: "MUNICÍPIO DE XYZ",
    subtitle_answer: "Concurso Público",

    name_answer: "Edital Nº 001/2023",

    title_field_1_answer: "Cargo/Opção:",
    field_1_answer: "CARGO TESTE",

    title_field_2_answer: "Local da Prova / Endereço:",
    field_2_answer: "FACULDADE XYZ",

    title_field_3_answer: "Município da Prova:",
    field_3_answer: "Cidade XYZ",

    title_field_4_answer: "Sala:",
    field_4_answer: "Sala 1",

    title_field_5_answer: "Número de Controle:",
    field_5_answer: "00001",

    rules_answer: "",
    answer_page_visible: true,
    random_question: false,
    random_per_category: false,
    select_from_group: false,
    selected_disciplines: [],
    number_select_questions: 0,
    random_alternatives: false,
    application_id:''
  },
  class_student: {},
  rules_answer:
    '<p>1. Verifique se os seus dados pessoais estão corretos. Caso haja erros comunique ao seu Fiscal de Sala.</p><p>2. NÃO se esqueça de assinar o seu cartão-resposta.</p><p>3. Para marcar as RESPOSTAS, utilize apenas caneta esferográfica AZUL ou PRETA fabricada em material transparente</p><p>4. Os quadros de respostas estão dispostos em colunas numeradas que iniciam "de cima para&nbsp;baixo" sendo a coluna à direita uma continuação numérica da coluna à esquerda.</p><p>5. Faça as marcas de acordo com o exemplo abaixo. Marcas mal feitas poderão não ser lidas pelo sistema de correção.</p><p style="text-align: center;">Forma CORRETA:&nbsp;<img src="' +
    STORAGE_URL +
    '/laq54_Captura de Tela 2023-08-17 aÌs 09.19.02.png" width="28" height="25">&nbsp; &nbsp; &nbsp;Forma INCORRETA:&nbsp;&nbsp;<img src="' +
    STORAGE_URL +
    '/nhs6b_Captura de Tela 2023-08-17 aÌs 09.19.12.png" width="113" height="26"></p><p style="text-align: justify;">6.&nbsp;A marcação no CARTÃO-RESPOSTA é definitiva, não admitindo rasuras. Marque apenas uma resposta para cada questão.</p><p style="text-align: justify;">7.&nbsp;Marcações feitas fora das margens poderão ser mal interpretadas. As questões com resp<span id="jodit-selection_marker_1690548045812_0975467197326112" data-jodit-temp="true" data-jodit-selection_marker="start" style="line-height: 0; display: none;">﻿</span>ostas "em branco"&nbsp;não serão computadas.</p><p style="text-align: justify;">8.&nbsp;Não risque, não amasse, não dobre e não suje o CARTÃO-RESPOSTA, pois isso poderá prejudicá-lo.</p><p style="text-align: justify;">9.&nbsp;Não altere nenhum dado impresso nesta folha. Qualquer dúvida chame o fiscal.</p>',
  showAnswerConfig: false,
  loading_download: false,
  message_download: "",
  showTextConfig: true,
  showPageConfig: false,
  showStudents: false,
  showWarning: false,
  search_form: "",
  loading_save: false,
  error_message: null,
  loading_student: false,
  students: {},
  classes: [],
  unit: {},
  unit_text: "",

  classSelected: {},
  
  class_text: "",
  course_text: "",
  course: {},
  courses: [],
  units: [],
  class_id: "",
  search_student: "",
  correctors: [],
  corrector_id:null,
  layoutSelect:{},
  layout_text:''
};
