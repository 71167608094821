import SidebarItem from "../SidebarItem";

// icons
import {
  FaQuestion,
} from "react-icons/fa";

export default function StudentMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<FaQuestion />}
        title="Exames"
        href="/student-exams"
        {...{ shrunk }}
      />
    </>
  );
}
