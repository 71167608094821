import { modelSlice } from "./slice";

export const {
  setComment,
  setScore,
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setErrorMessage,
  setPage,
  setReply,
  setNewAnnotation,
  setAnnotationEdit,
  setShowModalAspect,
  setAnnotations
} = modelSlice.actions;
