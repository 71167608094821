import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { TestsAPI } from "../../../apis/TestsAPI";
import moment from "moment";

export const save = createAsyncThunk(
  "tests/save",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      // console.log( {
      //   name:state.demands.name,
      //   description:state.demands.description,
      //   keywords:state.demands.keywords,
      //   end_datetime: moment(state.demands.endDate.format('YYYY-MM-DD')+' '+state.demands.endTime.format('HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss')
      // })
      return await TestsAPI.save(token, state.tests.model);
    } catch (e: any) {
      console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);
      // return rejectWithValue({
      //   code: "FETCH_ERROR",
      //   message: JSON.stringify(e),
      // });
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addSaveReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(save.pending, (state: any, _action: any) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(
      save.fulfilled,
      (state: { data: any }, action: { payload: { data: any } }) => {
        var data = [...state.data];
        data.push(action.payload.data);
        return { ...clearErrorState(state), loading_save: false, data: data };
      }
    )
    .addCase(save.rejected, (state: any, action: { payload: any }) => {
      const error: any = action.payload;
      // const errors: any = JSON.parse(error.message).errors
      console.log(error.message)
      // let erro = '';
      // for (let i = 0; i < errors.length; i++) {
      //   if (i != errors.length - 1)
      //     erro += errors[i].message + '\n';
      //   else
      //     erro += errors[i].message;
      // }
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message:error.message,
        loading_save: false,
      };
    });
};
