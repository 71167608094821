import { useEffect } from "react";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import DefaultButton from "../../../component/base/DefaultButton";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setName,
  setEmail,
  setPassword,
  setConfirmPassword,
  setViewQuestionExamOrg,
  setViewQuestionListOrg,
  setOrganization,
  setErrorMessage,
  clearWindowState,
} from "../../../services/stores/userTeacherStore/actions";
import PasswordInput from "../../../component/base/PasswordInput";
import { UserRole } from "../../../constants/enum/UserRole";
import { RiArrowGoBackLine } from "react-icons/ri";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {
  fetchIdThunk,
  fetchOrganizationListThunk,
  saveThunk,
  updateThunk,
} from "../../../services/stores/userTeacherStore/slice";
import { SaveRounded } from "@material-ui/icons";
var debounce = require("lodash.debounce");

export function UsersTeacherForm(props: { editMode?: boolean }) {
  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.userTeacher.screen);
  const { name, email, password, password_confirmation, permission } =
    useAppSelector((store) => store.userTeacher.model);

  const role = useAppSelector((store) => store.auth.me.role);

  const selectedOrganization = useAppSelector(
    (store) => store.userTeacher.model.organization
  );

  const dispatch = useAppDispatch();
  const history = useNavigate();
  const params = useParams();

  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/users-teacher");
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/users-teacher");
      });
    }
  };

  const searchOrganizations = (query: any, callback: any) => {
    dispatch(fetchOrganizationListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true })).then(
        (result: any) => {
          dispatch(setOrganization(result.payload.organization));
        }
      );
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Professor
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite um professor do seu sistema"
                : "Cadastre os professores do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <InputGroup className="col-lg">
                      <PasswordInput
                        required={!props.editMode}
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={password}
                        OnChangeText={(e: any) =>
                          dispatch(setPassword(e.target.value))
                        }
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <PasswordInput
                        required={!props.editMode}
                        label="Confirmação de senha"
                        placeholder="Digite a confirmação de senha"
                        className="flex1"
                        value={password_confirmation}
                        OnChangeText={(e: any) =>
                          dispatch(setConfirmPassword(e.target.value))
                        }

                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                  </div>
                  {
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn  margin-bottom">
                        <Form.Label>
                          Poderá selecionar todas questões do sistema ao criar
                          prova? <Attention />
                        </Form.Label>

                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={permission.view_question_exam_organization}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            // dispatch(setState({ random_question: e.target.value }));
                          }}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              dispatch(setViewQuestionExamOrg(true));
                            }}
                            control={
                              <Radio
                                checked={
                                  permission.view_question_exam_organization ==
                                  true
                                }
                                value={true}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Sim"}
                          />
                          <FormControlLabel
                            onClick={(e) => {
                              dispatch(setViewQuestionExamOrg(false));
                            }}
                            control={
                              <Radio
                                checked={
                                  permission.view_question_exam_organization ==
                                  false
                                }
                                value={false}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Não"}
                          />
                        </RadioGroup>
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom"></div>
                    </InputGroup>
                  }
                  {
                    <InputGroup>
                      <div className="flex fdcolumn  margin-bottom">
                        <Form.Label>
                          Poderá atuar como auditor? <Attention />
                        </Form.Label>

                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={permission.view_question_list_organization}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            // dispatch(setState({ random_question: e.target.value }));
                          }}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              dispatch(setViewQuestionListOrg(true));
                            }}
                            control={
                              <Radio
                                checked={
                                  permission.view_question_list_organization ==
                                  true
                                }
                                value={true}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Sim"}
                          />
                          <FormControlLabel
                            onClick={(e) => {
                              dispatch(setViewQuestionListOrg(false));
                            }}
                            control={
                              <Radio
                                checked={
                                  permission.view_question_list_organization ==
                                  false
                                }
                                value={false}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Não"}
                          />
                        </RadioGroup>
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom"></div>
                    </InputGroup>
                  }

                  {role === UserRole.SUPER_USER && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Organização</Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do professor..."
                          onChange={(e: any) => {
                            dispatch(setOrganization(e));
                          }}
                          cacheOptions
                          value={selectedOrganization}
                          loadOptions={debounce(searchOrganizations, 250)}
                          defaultOptions
                        />
                      </div>
                    </InputGroup>
                  )}
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users-teacher"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/users-teacher")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <DefaultButton
                          type="submit"
                          bg="confirm"
                          text="Salvar"
                          disabled={loadingImage == true}
                          loadingtext="Salvando"
                          loading={loadingSave}
                        /> */}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
