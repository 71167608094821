import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";

export const fetchLayoutCorrectors = createAsyncThunk(
  "layouts/fetch-teachers",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await LayoutsExamsAPI.getCorrectors(token, params.id,"", false);
    } catch (e: any) {
      console.log(
        `Error fetchTeachers::createAsyncThunk ${e.status} ${e.message}`
      );

      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchTeacher = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(fetchLayoutCorrectors.pending, (_state, _action: any) => {})
    .addCase(fetchLayoutCorrectors.fulfilled, (state, action) => {

      state.correctors = action.payload;
    })
    .addCase(fetchLayoutCorrectors.rejected, (state: any, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loading_student: false,
        },
      };
    });
};
