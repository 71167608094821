import { PayloadAction } from "@reduxjs/toolkit";

// npm start: .env.development.local, .env.local, .env.development, .env
// npm run build: .env.production.local, .env.local, .env.production, .env
// npm test: .env.test.local, .env.test, .env (note .env.local is missing)

export const API_URI = process.env.REACT_APP_API_URI;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
export const STUDENT_REDIRECT = process.env.REACT_APP_STUDENT_REDIRECT;

export const paginationState = {
  hasNextPage: true,
  hasPrevPage: false,
  limit: 10,
  nextPage: 0,
  page: 1,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
};

export const screenState = {
  loadingScreen: false,
  loadingSearch: false,
  loadingSave: false,
  showWarning: false,
  redirect: false,
  errorMessages: [],
  successMessage: null,
  showModal: false,
  showErro: false,
  loadingImage: false,
};

export const listState = {
  selectedId: null,
  all: [],
  loading: false,
  search: "",
  query: "",
};

export const paginationReducers = {
  setPage: (state: any, action: PayloadAction<any>) => {
    return {
      ...state,
      pagination: { ...state.pagination, page: action.payload },
    };
  },
};

export const screenReducers = {
  setLoading: (state: any, action: PayloadAction<boolean>) => {
    return {
      ...state,
      screen: { ...state.screen, loadingScreen: action.payload },
    };
  },
  setErrorMessage: (state: any, action: PayloadAction<any>) => {
    return {
      ...state,
      screen: { ...state.screen, errorMessages: action.payload },
    };
  },
  setWarning: (state: any, action: PayloadAction<any>) => {
    return {
      ...state,
      screen: { ...state.screen, showWarning: action.payload },
    };
  },
};
export const listReducers = {
  setChildVisible: (state: any, action: PayloadAction<any>) => {
    const Idx = state.all.findIndex((x: any) => x._id === action.payload);
    if (state.all[Idx].hasOwnProperty("childVisible"))
      state.all[Idx].childVisible = !state.all[Idx].childVisible;
    else state.all[Idx].childVisible = true;
  },
  setSelectedId: (state: any, action: PayloadAction<any>) => {
    return { ...state, list: { ...state.list, selectedId: action.payload } };
  },
  setParentId: (state: any, action: PayloadAction<string>) => {
    return { ...state, list: { ...state.list, parentId: action.payload } };
  },
  setSearch: (state: any, action: PayloadAction<any>) => {
    return {
      ...state,
      list: { ...state.list, search: action.payload },
    };
  },
  setQuery: (state: any, action: PayloadAction<any>) => {
    return {
      ...state,
      list: { ...state.list, query: action.payload },
    };
  },
};

export const parseHtmlString = (yourHtmlString: string) => {
  var element = document.createElement("div");
  element.innerHTML = yourHtmlString;
  let srcs = [];
  var imgSrcUrls = element.getElementsByTagName("img");
  for (var i = 0; i < imgSrcUrls.length; i++) {
    var urlValue = imgSrcUrls[i].getAttribute("src");

    srcs.push(urlValue);
  }
  return srcs;
};
