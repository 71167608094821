import { useEffect } from "react";

// unidades
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { setModel, setPercent } from "../../services/stores/uploadExam/actions";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { uploadFileArrayThunk } from "../../services/stores/uploadExam/slice";
import { Container, Input } from "reactstrap";
import { FaCheckCircle, FaExclamationCircle, FaUpload } from "react-icons/fa";
import { ProgressBar } from "react-bootstrap";

export function UploadExamView() {
  const dispatch = useAppDispatch();

  const model = useAppSelector((store: any) => store.uploadExam);

  useEffect(() => {}, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Fotografar Prova
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Fotografe suas provas utilizando o celular ou escolha a fotografia
              no seu dispositivo
            </p>
            <Input
              type="file"
              accept="image/*;capture=camera"
              onChange={(e: any) => {
                let target: any = e.target;
                let file = target.files[0];
                dispatch(setModel(file));
                dispatch(
                  uploadFileArrayThunk({
                    onUpdateFunction: (ref: any, pct: any) => {
                      dispatch(setPercent({ ref, pct }));
                    },
                  })
                );
              }}
            />
            {model?.map((item: any, i: number) => (
              <div key={i}  style={{ marginTop:10, color: "green" }}>
                <img width={"20%"} src={URL.createObjectURL(item.file)} />

                {item.status == "SUCESSO" && (
                  <FaCheckCircle
                    style={{ color: "green", marginLeft: 10, fontSize: 40 }}
                  />
                )}
                {item.status == "CARREGANDO" && (
                  <FaUpload
                    style={{ color: "blue", marginLeft: 10, fontSize: 40 }}
                  />
                )}
                {item.status == "FALHA" && (
                  <FaExclamationCircle
                    style={{ color: "red", marginLeft: 10, fontSize: 40 }}
                  />
                )}
                {item.status == "CARREGANDO" && (
                  <ProgressBar
                    style={{ height: 20, width: "20%", fontSize: 10 }}
                    animated
                    now={item.pct}
                    label={"Enviando..."}
                  />
                )}
              </div>
            ))}

            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
