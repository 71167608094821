import moment from "moment";
import { UserRole } from "../../../constants/enum/UserRole";

export const modelInitialState = {
  // navigation
  redirect: false,
  path: "/edit-profile",
  // screen
  loading_screen: true,
  success_message: "",
  loading_image: false,
  error_message: "",
  show_warning: false,
  show_erro: false,
  search: null,
  loading_search: true,
  loading_save: false,
  showModal: false,
  password_confirmation: "",
  // pagination
  pagination: {
    page: 1,
    last_page: 1,
  },
  // model
  all: [],
  model: {
    _id: "",
    name: "",
    email: "",
    password: "",
    role: UserRole.ADMIN_USER,
    organization_id: "",
  },
};
