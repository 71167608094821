import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../../services/stores/disciplineStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/examStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setModel,
  setErrorMessage,
  clearState,
  setId,
  setLoadingScreen,
  setState,
} from "../../../services/stores/examStore/actions";
import {
  setId as setTestId,
} from "../../../services/stores/testStore/actions";
import { setState as setStateQuestion } from "../../../services/stores/questionStore/actions";

import { update } from "../../../services/stores/examStore/async/updateThunk";
import { fetchId } from "../../../services/stores/examStore/async/fetchIdThunk";
import { TableDrag } from "../TableDrag";
import { fetchListFiltered } from "../../../services/stores/examStore/async/fetchListFilteredThunk";
import { fetchList as fetchListExam } from "../../../services/stores/examStore/async/fetchListThunk";

import { QuestionModalView } from "../../QuestionModalView";
import { ExamModalTag } from "../../ExamModalTag";
import AsyncSelect from "react-select/async";
import { fetchListExamSelect } from "../../../services/stores/testStore/async/fetchListExamSelectThunk";
import { fetchId as fetchTestId } from "../../../services/stores/testStore/async/fetchIdThunk";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

var debounce = require("lodash.debounce");

export function ExamsCreate() {
  const {
    error_message,
    model,
    loading_input,
    loading_save,
    loading_screen,
    questionsArray,
    disciplineSelect,
    questionContent,
    questionsSearch,
    showAutoCompleteQuestion,
    loading_search,
    showModalTag,
    questions,
    examSelected,
    exam_text,
    examSelectedVersion,
    exam_text_version
  } = useAppSelector((store) => store.exams);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((store) => store.disciplines);
  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);
  const timeout_: any = useRef();
  const timeout_search: any = useRef();

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);

  const history = useNavigate();
  const params = useParams();



  const saveContent = () => {
    let model_: any = { ...model };
    model_.questions = questionsArray.map((item: any, id) => item._id);
    console.log("entrou");
    dispatch(setModel(model_));
    if (edit == true) {
      dispatch(update()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          console.log('model', model_)
          model_.application_id ? history('/tests/view/' + model_.application_id + '?tab=2') : (params.test_id ? history('/tests/view/' + params.test_id + '?tab=2') : history("/exams"))
        }
      });
    } else {
      dispatch(save()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          console.log('model2', model_)

          model_.application_id ? history('/tests/view/' + model_.application_id + '?tab=2') : (params.test_id ? history('/tests/view/' + params.test_id + '?tab=2') : history("/exams"))
        }
      });
    }
  };

  useEffect(() => {
    // const exam_id = history.pa
    dispatch(setLoadingScreen(true));
    dispatch(fetchList({ force: true })).then((data) => {
      console.log(data);
      if (params.id == undefined) {
        if(params.test_id==undefined){
          dispatch(setLoadingScreen(false));
        }
        else{
          dispatch(setTestId(params.test_id));
          dispatch(setLoadingScreen(true));
          dispatch(fetchTestId({force:true})).then((data)=>{
            console.log('test',data);
            let model_:any = {...model};
            model_.random_question=data.payload.random_question ?? false;
            model_.random_alternatives=data.payload.random_alternatives ?? false;
            console.log(model_)
            dispatch(setModel(model_));
            dispatch(setLoadingScreen(false));
          })

        }
      }
    });
    if (params.id != undefined) {
      dispatch(setId(params.id));
      setEdit(true);
      dispatch(fetchId({ force: true }));
    }

    return () => {
      console.log('model1', model)
      dispatch(clearState());
    };
  }, []);

  const parseQuestoesDragDrop = () => {
    let questoes: any = [...questionsArray];
    let ids = questoes.map((item: any, id: any) => item._id);
    let ids_exists: any = [];

    for (let i = 0; i < Object.values(questions).length; i++) {
      let item: any = Object.values(questions)[i];
      ids_exists.push(item._id);

      if (!ids.includes(item._id)) {
        questoes.push({
          numero: i + 1,
          _id: item._id,
          id: item._id,
          index: i,
          enunciado: <p className="text_overflow">{item.question_content}</p>,
          categoria: item.discipline.name,
          tipo_questao: (
            <p style={{ whiteSpace: "pre" }}>
              {item.type == "set"
                ? "Conjunto de questões\n" +
                item.questions.length +
                (item.questions.length == 1 ? " questão" : " questões")
                : item.type == "objective"
                  ? "Questão única"
                  : "Questão dissertativa"}
            </p>
          ),
          acoes: i,
        });
      }
    }
    questoes = questoes.filter((item: any, id: any) => {
      if (ids_exists.includes(item._id)) {
        return true;
      }
      return false;
    });

    dispatch(setState({ questionsArray: questoes }));
  };

  const searchClasses = (query: any, callback: any) => {
    dispatch(fetchListExamSelect({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchExams = (query: any, callback: any) => {
    let exam: any = { ...examSelected };
    console.log(exam._id)
    dispatch(fetchListExamSelect({ force: true, search: query, exam_id: exam._id })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };


  useEffect(() => {
    parseQuestoesDragDrop();
  }, [questions]);
  console.log(exam_text);
  let exam: any = { ...examSelected }
  console.log(exam)
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {edit == true ? "Editar" : "Cadastrar"} Prova
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {edit == false
                ? "Cadastre as provas do seu sistema"
                : "Edite uma prova do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                <QuestionModalView />
                <ExamModalTag />

                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  {edit == false && params.test_id != undefined && <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Prova
                        <Attention />
                      </Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Nenhuma opção encontrada..."
                        noOptionsMessage={
                          exam_text.length == 0
                            ? () => "Digite parte do nome da prova..."
                            : () => "Nenhuma opção encontrada"
                        }
                        onChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ examSelected: e, examSelectedVersion: {}, exam_text: '', exam_text_version: '' }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ exam_text: e }));
                          return undefined;
                        }}
                        value={examSelected}
                        loadOptions={debounce(searchClasses, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup>}
                  {edit == false && params.test_id != undefined && exam._id != undefined && <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Versão da Prova
                        <Attention />
                      </Form.Label>
                      <AsyncSelect
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option._id}
                        placeholder="Nenhuma opção encontrada..."
                        noOptionsMessage={
                          exam_text_version.length == 0
                            ? () => "Digite parte do nome da prova..."
                            : () => "Nenhuma opção encontrada"
                        }
                        onChange={(e: any) => {
                          let model_: any = { ...e,random_question:model.random_question ?? false, random_alternatives:model.random_alternatives ?? false };
                          let examSelect:any=e;
                          model_.select_from_group = model.select_from_group ?? false;
                          model_.random_per_category = model.random_per_category ?? false;
                          model_.number_select_questions = model.number_select_questions ?? 0;

                          model_.exam_father_id = exam._id;
                          model_.application_id = params.test_id;
                          model_.questions = e.questions.map((item: any, id: any) => item._id);
                          model_.selected_disciplines=[];
                          let questions: any = {};
                          e.questions.map((item: any, id: any) => {
                            questions[item._id] = item;
                          })
                          delete model_._id;
                          delete model_.created_at;
                          delete model_.updated_at;
                          delete model_.createdAt;
                          delete model_.updatedAt;

                          let disciplines: any = [];
                          console.log('e', e)
                          for (
                            let i = 0;
                            i < examSelect.questions.length;
                            i++
                          ) {
                            if (
                              !disciplines.includes(
                                examSelect.questions[i].discipline_id
                              )
                            ) {
                              disciplines.push(
                                examSelect.questions[i].discipline_id
                              );
                              model_.selected_disciplines.push({
                                _id: examSelect.questions[i].discipline._id,
                                name: examSelect.questions[i].discipline
                                  .name,
                                order: model_.selected_disciplines.length,
                                number: 1,
                              });
                            }
                          }
                          dispatch(setModel(model_));
                          console.log(e);
                          dispatch(setState({ examSelectedVersion: e, questions, exam_text_version: '' }));
                        }}
                        onInputChange={(e: any) => {
                          console.log(e);
                          dispatch(setState({ exam_text_version: e }));
                          return undefined;
                        }}
                        value={examSelectedVersion}
                        loadOptions={debounce(searchExams, 250)}
                        defaultOptions={[]}
                      />
                    </div>
                  </InputGroup>}
                  {((params.test_id!=undefined && examSelectedVersion._id ) ||  params.test_id==undefined) && <>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da prova"
                        value={model.name}
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.name = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        required
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição </Form.Label>
                      <Input
                        placeholder="Descrição da prova"
                        value={model.description}
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.description = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        as="textarea"
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Tipo de prova <Attention />
                      </Form.Label>

                      <select
                        disabled={loading_input || params?.id != undefined}
                        className="form-select "
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.type = e.target.value;
                          model_.questions = [];
                          dispatch(
                            setState({
                              model: model_,
                              questionContent: "",
                              questionsSearch: [],
                              questions: {},
                              questionsArray: [],
                              disciplineSelect: { _id: "", name: "" },
                            })
                          );
                        }}
                        value={model.type}
                      >
                        <option value="objective">Prova Objetiva</option>
                        <option value="text">Prova Dissertativa</option>
                      </select>
                    </div>
                  </InputGroup>
                  
                  {(params.test_id || model.application_id) && <>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Ordem das questões <Attention />
                      </Form.Label>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={model.random_question}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          // dispatch(setState({ random_question: e.target.value }));
                        }}
                      >
                        <FormControlLabel
                        disabled
                          onClick={(e) => {
                            
                          }}
                          control={
                            <Radio
                              checked={model.random_question == false}
                              value={false}
                            />
                          }
                          style={{ color: "black" }}
                          label={"Questões na ordem de cadastro"}
                        />
                        <FormControlLabel
                        disabled
                          onClick={(e) => {
                          
                          }}
                          control={
                            <Radio
                              checked={model.random_question == true}
                              value={true}
                            />
                          }
                          style={{ color: "black" }}
                          label={"Questões em ordem aleatória"}
                        />
                      </RadioGroup>
                    </div>
                    <div className="flex fdcolumn flex1 margin-bottom"></div>
                  </InputGroup>
                  {model.random_question == true && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Seleção das questões <Attention />
                        </Form.Label>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group1"
                          value={model.select_from_group}
                          onChange={(e) => {
                            console.log(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              let model_: any = { ...model };
                              model_.select_from_group = false;
                              dispatch(setModel(model_));
                            }}
                            control={
                              <Radio
                                checked={
                                  model.select_from_group == false
                                }
                                value={false}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Usar todas questões selecionadas"}
                          />
                          <FormControlLabel
                            onClick={(e) => {
                              let model_: any = { ...model };
                              model_.select_from_group = true;
                              dispatch(setModel(model_));
                            }}
                            control={
                              <Radio
                                checked={
                                  model.select_from_group == true
                                }
                                value={true}
                              />
                            }
                            style={{ color: "black" }}
                            label={
                              "Selecionar questões aleatórias para a prova de cada candidato"
                            }
                          />
                        </RadioGroup>
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Forma de aleatorização <Attention />
                        </Form.Label>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group0"
                          value={model.random_per_category}
                          onChange={(e) => {
                            console.log(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              let model_: any = { ...model };
                              model_.random_per_category = false;
                              dispatch(setModel(model_));
                            }}
                            control={
                              <Radio
                                checked={
                                  model.random_per_category == false
                                }
                                value={false}
                              />
                            }
                            style={{ color: "black" }}
                            label={"Seguindo a ordem das disciplinas"}
                          />
                          <FormControlLabel
                            onClick={(e) => {
                              let model_: any = { ...model };
                              model_.random_per_category = true;
                              dispatch(setModel(model_));
                            }}
                            control={
                              <Radio
                                checked={
                                  model.random_per_category == true
                                }
                                value={true}
                              />
                            }
                            style={{ color: "black" }}
                            label={
                              "Questões de forma aleatória independente da disciplina"
                            }
                          />
                        </RadioGroup>
                        {model.random_per_category == false && (
                          <>
                            <Form.Label className="mt-1">
                              Ordem das disciplinas:{" "}
                            </Form.Label>
                            {[...model.selected_disciplines]
                              .sort(
                                (a: any, b: any) => a.order - b.order
                              )
                              .map((item: any, id: any) => (
                                <div
                                  key={id}
                                  className="d-flex justify-content-between align-items-center mt-2"
                                >
                                  <p style={{margin:0}}>{item.name}</p>
                                  <div
                                    style={{
                                      marginRight: 15,
                                      display: "flex",
                                    }}
                                  >
                                    {model.select_from_group ==
                                      true && (
                                        <Input
                                          onWheel={(e: any) =>
                                            e.target.blur()
                                          }
                                          placeholder="Número de questões que devem ser selecionadas"
                                          value={item.number}
                                          type="number"
                                          min={1}
                                          onChange={(e: any) => {
                                            let model_: any = {
                                              ...model,
                                            };
                                            let disciplines = [
                                              ...model_.selected_disciplines,
                                            ];
                                            model_.selected_disciplines =
                                              disciplines.map(
                                                (
                                                  item2: any,
                                                  id2: any
                                                ) => {
                                                  let item3 = {
                                                    ...item2,
                                                  };
                                                  if (
                                                    item2._id == item._id
                                                  ) {
                                                    item3.number =
                                                      e.target.value;
                                                  }
                                                  return item3;
                                                }
                                              );
                                            dispatch(setModel(model_));
                                          }}
                                          required
                                          style={{ maxWidth: 80 }}
                                        // max={Object.values(this.state.questoes_selecionadas).filter((item2,id2)=>{
                                        //     if(item2.categoria_id==item.id){
                                        //         return true;
                                        //     }
                                        //     return false;
                                        // }).map((item2, id2) => {
                                        //     if (item2.type_questao == 'conjunto') {
                                        //         return item2.questoes.length;
                                        //     }
                                        //     else {
                                        //         return 1;
                                        //     }
                                        // }).reduce((a, b) => a + b, 0)}

                                        // title="Coloque o nome completo do usuário"
                                        />
                                      )}
                                    <MdArrowDropUp
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          id == 0 ? "grey" : "black",
                                      }}
                                      className="click_input"
                                      onClick={() => {
                                        // if(item.order)
                                        let first = true;
                                        let model_: any = { ...model };

                                        let disciplines = [
                                          ...model_.selected_disciplines,
                                        ].sort(
                                          (a: any, b: any) =>
                                            a.order - b.order
                                        );
                                        let item4 = { ...item };

                                        let categorias_selecionadas =
                                          disciplines.map(
                                            (item2: any, id2: any) => {
                                              let item3 = { ...item2 };
                                              if (
                                                id2 + 1 == id &&
                                                first == true
                                              ) {
                                                let order = item4.order;
                                                console.log(
                                                  order,
                                                  item3.order
                                                );

                                                item4.order =
                                                  item3.order;
                                                item3.order = order;
                                                first = false;
                                                console.log(
                                                  "order changed"
                                                );
                                              }
                                              return item3;
                                            }
                                          );
                                        console.log(
                                          categorias_selecionadas
                                        );

                                        categorias_selecionadas =
                                          categorias_selecionadas.map(
                                            (item2: any, id2: any) => {
                                              if (
                                                item2._id == item4._id
                                              ) {
                                                return item4;
                                              }
                                              return item2;
                                            }
                                          );
                                        model_.selected_disciplines =
                                          categorias_selecionadas;
                                        console.log(
                                          categorias_selecionadas
                                        );

                                        dispatch(setModel(model_));
                                      }}
                                      size={30}
                                    />
                                    <MdArrowDropDown
                                      className="click_input"
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          id ==
                                            model.selected_disciplines
                                              .length -
                                            1
                                            ? "grey"
                                            : "black",
                                      }}
                                      onClick={() => {
                                        let first = true;
                                        let model_: any = { ...model };
                                        let disciplines = [
                                          ...model_.selected_disciplines,
                                        ].sort(
                                          (a: any, b: any) =>
                                            a.order - b.order
                                        );
                                        let item4 = { ...item };

                                        let categorias_selecionadas =
                                          disciplines.map(
                                            (item2: any, id2: any) => {
                                              let item3 = { ...item2 };

                                              if (
                                                id2 - 1 == id &&
                                                first == true
                                              ) {
                                                let order = item4.order;
                                                item4.order =
                                                  item3.order;
                                                item3.order = order;
                                                console.log(item4);
                                                console.log(item3);

                                                first = false;
                                                console.log("entrou");
                                              }
                                              return item3;
                                            }
                                          );
                                        console.log([
                                          ...categorias_selecionadas,
                                        ]);

                                        categorias_selecionadas =
                                          categorias_selecionadas.map(
                                            (item2: any, id2: any) => {
                                              if (
                                                item2._id == item4._id
                                              ) {
                                                return item4;
                                              }
                                              return item2;
                                            }
                                          );
                                        console.log(
                                          categorias_selecionadas
                                        );
                                        model_.selected_disciplines =
                                          categorias_selecionadas;
                                        dispatch(setModel(model_));
                                      }}
                                      size={30}
                                    />
                                  </div>
                                </div>
                              ))}
                            {model.selected_disciplines.length == 0 && (
                              <div>
                                <p>Nenhuma questão selecionada</p>
                              </div>
                            )}
                          </>
                        )}

                        {model.select_from_group == true &&
                          model.random_per_category == true && (
                            <>
                              <Form.Label className="mt-1">
                                Número de questões que devem ser
                                selecionadas:{" "}
                              </Form.Label>

                              <Input
                                onWheel={(e: any) => e.target.blur()}
                                placeholder="Número de questões que devem ser selecionadas"
                                value={model.number_select_questions}
                                type="number"
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.number_select_questions =
                                    e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                required
                                min={1}
                                max={Object.values(questions)
                                  .map((item: any, id: any) => {
                                    if (item.type == "set") {
                                      return item.questions.length;
                                    } else {
                                      return 1;
                                    }
                                  })
                                  .reduce((a: any, b: any) => a + b, 0)}
                              // title="Coloque o nome completo do usuário"
                              />
                            </>
                          )}
                      </div>
                    </InputGroup>
                  )}
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Ordem das alternativas <Attention />
                      </Form.Label>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group2"
                        value={model.random_alternatives}
                        onChange={(e) => {
                        
                        }}
                      >
                        <FormControlLabel
                          onClick={(e) => {
                           
                          }}
                          disabled
                          control={
                            <Radio
                              checked={
                                model.random_alternatives == false
                              }
                              value={false}
                            />
                          }
                          style={{ color: "black" }}
                          label={"Alternativas na ordem de cadastro"}
                        />
                        <FormControlLabel
                          onClick={(e) => {
                           
                          }}
                          disabled
                          control={
                            <Radio
                              checked={
                                model.random_alternatives == true
                              }
                              value={true}
                            />
                          }
                          style={{ color: "black" }}
                          label={"Alternativas em ordem aleatória"}
                        />
                      </RadioGroup>
                    </div>
                  </InputGroup>
                  </>}
                  <h5 style={{ fontWeight: "bold" }}>Questões</h5>
                  <br />
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Selecione uma disciplina</Form.Label>

                      <select
                        disabled={loading_input}
                        className="form-select "
                        onChange={(e) => {
                          if (e.target.value != "") {
                            let categoria = data.filter(
                              (item: any, id: any) => item._id == e.target.value
                            )[0];
                            dispatch(
                              setState({
                                disciplineSelect: categoria,
                                questionContent: "",
                                questionsSearch: [],
                              })
                            );
                          } else {
                            dispatch(
                              setState({
                                disciplineSelect: { _id: "", name: "" },
                                questionContent: "",
                                questionsSearch: [],
                              })
                            );
                          }
                        }}
                        value={disciplineSelect._id}
                      >
                        <option value="">Selecione uma disciplina</option>
                        {data &&
                          data.length != 0 &&
                          data.map((value: any, index: any) => (
                            <option key={index} value={value["_id"]}>
                              {value["name"]}
                            </option>
                          ))}
                        -
                      </select>
                      {disciplineSelect._id != "" && (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <a
                            onClick={() => {
                              // this.get_questoes_categoria(this.state.categoria._id);
                              dispatch(setState({ questionContent: "" }));

                              dispatch(fetchListFiltered({ force: true })).then(
                                (data) => {
                                  console.log(data);
                                }
                              );
                            }}
                            style={{
                              textAlign: "left",
                              fontSize: 15,
                              marginTop: 10,
                              marginRight: 20,
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            className="link-success"
                          >
                            Selecionar todas as questões desta disciplina
                          </a>

                          <a
                            onClick={() => {
                              dispatch(setState({ showModalTag: true }));
                              // this.setState({ showModalPalavraChave: true });
                            }}
                            style={{
                              textAlign: "left",
                              fontSize: 15,
                              marginTop: 10,
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            className="link-success"
                          >
                            Selecionar questões por uma tag
                          </a>
                        </div>
                      )}
                    </div>
                  </InputGroup>

                  {disciplineSelect._id != "" && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Busque questões da disciplina {disciplineSelect.name}{" "}
                        </Form.Label>
                        <Input
                          disabled={loading_input}
                          placeholder="Pesquise a questão pelo enunciado"
                          value={questionContent}
                          // onChange={(e) => this.setState({ questao_enunciado: e.target.value })}
                          onBlur={() => {
                            timeout_.current = setTimeout(() => {
                              // this.setState({ show_autocomplete_questao: false });
                              dispatch(
                                setState({ showAutoCompleteQuestion: false })
                              );
                            }, 500);
                          }}
                          onFocus={() => {
                            clearTimeout(timeout_.current);
                            dispatch(
                              setState({ showAutoCompleteQuestion: true })
                            );

                            // this.setState({ show_autocomplete_questao: true })
                          }}
                          onChange={(e) => {
                            clearTimeout(timeout_search.current);

                            dispatch(
                              setState({ questionContent: e.target.value })
                            );
                            if (e.target.value.length >= 1) {
                              timeout_search.current = setTimeout(() => {
                                if (e.target.value.length >= 1) {
                                  dispatch(
                                    fetchListFiltered({ force: true })
                                  ).then((data) => {
                                    console.log(data);
                                  });
                                }
                              }, 700);
                            } else {
                              dispatch(setState({ questionsSearch: [] }));
                            }

                            // this.get_questao_search(e.target.value, this.state.categoria._id);
                            // this.setState({ questao_enunciado: e.target.value })
                          }}
                        // title="Coloque o nome completo do usuário"
                        />
                        {showAutoCompleteQuestion == true &&
                          questionContent != "" && (
                            <div
                              className="overflow-auto"
                              style={{
                                width: "100%",
                                position: "absolute",
                                zIndex: 20,
                                backgroundColor: "white",
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                                maxHeight: "50vh",
                                top: 70,
                              }}
                            >
                              {loading_search == false &&
                                questionsSearch.map((item: any, id: any) => (
                                  <div
                                    key={id}
                                    onClick={() => {
                                      // this.select_questao(item)
                                      let questoes: any = { ...questions };
                                      if (questoes[item._id] == undefined) {
                                        questoes[item._id] = item;
                                      }
                                      dispatch(
                                        setState({
                                          questionContent: "",
                                          questionsSearch: [],
                                          showAutoCompleteQuestion: false,
                                          questions: questoes,
                                        })
                                      );
                                      // this.setState({ questao_enunciado: '', show_autocomplete_questao: false, questoes_selecionadas: questoes })
                                    }}
                                    className="opcao_autocomplete"
                                  >
                                    <b>
                                      {item.discipline.name} -{" "}
                                      {item.type == "set"
                                        ? "Conjunto de questões: " +
                                        item.questions.length +
                                        (item.questions.length == 1
                                          ? " questão"
                                          : " questões")
                                        : item.type == "objective"
                                          ? "Questão única"
                                          : "Questão dissertativa"}
                                    </b>{" "}
                                    -{" "}
                                    {item.question_content.length < 200
                                      ? item.question_content
                                      : item.question_content.substring(
                                        0,
                                        200
                                      ) + "..."}
                                  </div>
                                ))}
                              {loading_search == false &&
                                questionsSearch.length == 0 && (
                                  <div className="opcao_autocomplete">
                                    {questionContent.length == 0
                                      ? "Digite o enunciado da questão que deseja buscar"
                                      : " Nenhuma questão encontrada com este termo"}
                                  </div>
                                )}
                              {loading_search == true && (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="spinner-border text-primary"
                                    role="status"
                                  ></div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </InputGroup>
                  )}

                  <Form.Label>Questões selecionadas</Form.Label>
                  <br />
                  <Form.Label>
                    Total:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Object.values(questions)
                        .map((item: any, id: any) => {
                          if (item.type == "set") {
                            return item.questions.length;
                          } else {
                            return 1;
                          }
                        })
                        .reduce((a, b) => a + b, 0)}{" "}
                      {Object.values(questions)
                        .map((item: any, id: any) => {
                          if (item.type == "set") {
                            return item.questions.length;
                          } else {
                            return 1;
                          }
                        })
                        .reduce((a, b) => a + b, 0) == 1
                        ? "questão"
                        : "questões"}
                    </span>
                  </Form.Label>

                  <InputGroup>
                    <TableDrag
                      draggable={true}
                      data={questionsArray}
                      showData={(index: any) => {
                        let questions_array: any = questionsArray;
                        let questao_id = questions_array[index]!._id;
                        let questions_: any = questions;
                        dispatch(
                          setStateQuestion({
                            showModalView: true,
                            questionSelect: { ...questions_[questao_id] },
                          })
                        );
                      }}
                      deleteData={(index: any) => {
                        let questions_array: any = questionsArray;

                        let questao_id = questions_array[index]._id;
                        let questions_: any = questions;

                        let questoes_selecionadas = { ...questions_ };
                        delete questoes_selecionadas[questao_id];
                        let questoes_array = questions_array.filter(
                          (item: any, id: any) => item._id != questao_id
                        );
                        dispatch(
                          setState({
                            questions: questoes_selecionadas,
                            questionsArray: questoes_array,
                          })
                        );
                        // this.setState({ questoes_selecionadas, questoes_array });
                      }}
                      setData={(update_: any) => {
                        if (update_ != undefined) {
                          dispatch(setState({ questionsArray: update_ }));
                        } else {
                          console.log(update_);
                        }
                      }}
                    />
                  </InputGroup>
                  </>}

                  {loading_save == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/exams"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => model.application_id ? history('/tests/view/' + model.application_id + '?tab=2') : (params.test_id ? history('/tests/view/' + params.test_id + '?tab=2') : history("/exams"))}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar prova"
                        disabled={loading_save}
                        loadingtext="Salvando prova"
                        loading={loading_save}
                      /> */}
                      {((params.test_id!=undefined && examSelectedVersion._id ) ||  params.test_id==undefined) && <>
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loading_save}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                      </>}
                    </div>
                  )}

                  {loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
