
export default function getAdminDashboard({ from, to, dispatch, addWidget, fetchId }) {
  dispatch(addWidget({ id: "provas-fora-do-prazo-de-correcao" }));
  dispatch(
    addWidget({
      id: "totais-da-instituicao",
    })
  );
  dispatch(
    addWidget({
      id: "provas-que-ainda-nao-foram-corrigidas",
    })
  );
  dispatch(
    addWidget({
      id: "quantidade-de-questoes-a-serem-corrigidas-por-professor",
    })
  );
  dispatch(
    addWidget({
      id: "professor-que-ainda-nao-fez-prova",
    })
  );
  dispatch(
    addWidget({
      id: "alunos-que-ainda-nao-tiveram-resposta-dos-seus-questionamentos",
    })
  );
  dispatch(
    addWidget({
      id: "alunos-que-nao-acessaram-para-ver-o-resultado-da-prova",
    })
  );
  dispatch(
    addWidget({
      id: "professores-que-mais-fazem-provas",
    })
  );
  dispatch(
    addWidget({
      id: "indice-de-atendimento-de-demanda-nos-prazos",
    })
  );
  dispatch(
    addWidget({
      id: "media-de-nota-das-provas-do-professor",
    })
  );
  dispatch(
    addWidget({
      id: "professores-que-mais-mandam-provas-atrasadas",
    })
  );
  dispatch(
    addWidget({
      id: "professores-que-mais-mandam-as-provas-no-prazo",
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "totais-da-instituicao",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "provas-fora-do-prazo-de-correcao",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "provas-que-ainda-nao-foram-corrigidas",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "quantidade-de-questoes-a-serem-corrigidas-por-professor",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "professor-que-ainda-nao-fez-prova",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "alunos-que-ainda-nao-tiveram-resposta-dos-seus-questionamentos",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "alunos-que-nao-acessaram-para-ver-o-resultado-da-prova",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "professores-que-mais-fazem-provas",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "indice-de-atendimento-de-demanda-nos-prazos",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "media-de-nota-das-provas-do-professor",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "professores-que-mais-mandam-provas-atrasadas",
      from,
      to,
    })
  );
  dispatch(
    fetchId({
      force: true,
      id: "professores-que-mais-mandam-as-provas-no-prazo",
      from,
      to,
    })
  );
};
