import { useEffect, useState } from "react";

// unidades
import { fetchList } from "../../services/stores/disciplineStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { List } from "../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setId,
  setWarning,
  setSearch,
} from "../../services/stores/disciplineStore/actions";
import { deleteItem } from "../../services/stores/disciplineStore/async/deleteThunk";

export function DisciplinesView() {
  const disciplines = useAppSelector((store) => store.disciplines.data);
  const { search, loading_screen } = useAppSelector(
    (store) => store.disciplines
  );

  const show_warning = useAppSelector(
    (store) => store.disciplines.show_warning
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [loading_screen, setLoadingScreen] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);
  console.log("disciplines", disciplines);
  useEffect(() => {
    dispatch(fetchList({ force: true })).then((result) => {
      console.log("result", result);
    });
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Disciplinas"}
        not_found_message={"Nenhuma disciplina encontrada"}
        button_text="Adicionar disciplina"
        button_link="/disciplines/create"
        search_text="Pesquise pelo nome da disciplina"
        deleting_message={
          'Ao deletar esta disciplina, ela não estará mais disponível no sistema'
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true }));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/disciplines/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        pronoun='a'
        hideManager
        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={disciplines}
        pagination={{ last_page: last_page, current_page: current_page }}
        fields={[{ name: "name", type: "text", header: "Nome" },
        { name: "owner.name", type: "text", header: "Cadastrado por" },
        { name: "created_at", type: "date", header: "Criado em" },

      ]}
      ></List>
    </div>
  );
}
