import { AiOutlineOrderedList } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { MdRateReview } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export default function SummaryTeacherSection() {
  const navigate = useNavigate();
  
  // 1
  const provas_nao_corrigidas = 10;
  const correcao_fora_do_prazo = 2;
  const questionarios_sem_respostas = 3;

  // 2
  const total_demandas = 293;
  const nao_fizeram_prova = 10;

  return (
    <div
      style={{
        width: "100%",
        height: "max-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          color: "#4f4f4f",
        }}
      >
        Resumo
      </h4>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2rem",
          backgroundColor: "#ececf1",
          border: "1px solid #d0d0d6",
          borderRadius: "1rem",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "0.4rem 0",
        }}
      >
        <div className={style.summaryItem}
          onClick={()=>navigate('/users-student')}
          >
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "1rem",
              display: "flex",
              width: "3.2rem",
              height: "3.2rem",
              fontSize: "1.8rem",
              justifyContent: "center",
              alignItems: "center",
              color: "#525252",
            }}
          >
            <ImUsers />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Alunos
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              1,876
            </span>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            width: "0.2rem",
            height: "3.2rem",
          }}
        ></div>

        <div className={style.summaryItem}
          onClick={()=>navigate('/correction-exams')}
          >
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "1rem",
              display: "flex",
              width: "3.2rem",
              height: "3.2rem",
              fontSize: "1.8rem",
              justifyContent: "center",
              alignItems: "center",
              color: "#525252",
            }}
          >
            <MdRateReview />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Revisões pendentes
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              12
            </span>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            width: "0.2rem",
            height: "3.2rem",
          }}
        ></div>

        <div className={style.summaryItem}
          onClick={()=>navigate('/exams')}
          >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(235, 52, 52, 0.3)",
                borderRadius: "1rem",
                display: "flex",
                width: "3.2rem",
                height: "3.2rem",
                fontSize: "1.8rem",
                justifyContent: "center",
                alignItems: "center",
                color: "#613737",
              }}
            >
              <RiErrorWarningFill />
            </div>
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Provas
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Não corrigidas:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "rgba(235, 52, 52, 1)",
                }}
              >
                {provas_nao_corrigidas}
              </span>
            </span>
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Fora do prazo de correção:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "rgba(235, 52, 52, 1)",
                }}
              >
                {correcao_fora_do_prazo}
              </span>
            </span>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            width: "0.2rem",
            height: "3.2rem",
          }}
        ></div>

        <div className={style.summaryItem}
          onClick={()=>navigate('/demands')}
          >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(235, 198, 52, 0.4)",
                borderRadius: "1rem",
                display: "flex",
                width: "3.2rem",
                height: "3.2rem",
                fontSize: "1.8rem",
                justifyContent: "center",
                alignItems: "center",
                color: "#616037",
              }}
            >
              <AiOutlineOrderedList />
            </div>
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Demandas
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Total de demandas:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "rgba(153, 130, 40, 1)",
                }}
              >
                {total_demandas}
              </span>
            </span>
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              Não fizeram a prova:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "rgba(153, 130, 40, 1)",
                }}
              >
                {nao_fizeram_prova}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
