import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Correction } from "../../../model/Correction";

export const modelInitialState = {
  pagination: paginationState,
  screen: {...screenState,  annotation_edit:null,
    new_anotation:null,show_modal_aspect:false},
  list: listState,
  model: Correction,
};

