import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";
import moment from "moment";
import { modelInitialState } from "../initialState";

export const save = createAsyncThunk(
  "questions/save",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      // console.log( {
      //   name:state.demands.name,
      //   description:state.demands.description,
      //   keywords:state.demands.keywords,
      //   end_datetime: moment(state.demands.endDate.format('YYYY-MM-DD')+' '+state.demands.endTime.format('HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss')
      // })
      // let form = new FormData();
      // let keys = Object.keys(state.disciplines.model);


      return await QuestionsAPI.save(token, state.questions.model);
    } catch (e: any) {
      console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);
      // return rejectWithValue({
      //   code: "FETCH_ERROR",
      //   message: JSON.stringify(e),
      // });
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addSaveReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(save.pending, (state: any, _action: any) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(
      save.fulfilled,
      (state: any, action: { payload: { data: any } }) => {
        var data = [...state.data];
        data.push(action.payload.data);
        return { ...clearErrorState(state), loading_save: false, data: data };
      }
    )
    .addCase(save.rejected, (state: any, action: { payload: any }) => {
      const error: any = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message:error.message,
        loading_save: false,
      };
    });
};
