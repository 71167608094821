import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const uploadFileArrayStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (
      params: { onUpdateFunction: (ref: any, a: any) => void },
      { getState, rejectWithValue }
    ) => {
      const state: any = getState();
      const { token } = state.auth;
      try {
        return await apiFunction(
          token,
          state[slice][state[slice].length - 1],
          state[slice].length - 1,
          params.onUpdateFunction
        );
      } catch (e: any) {
        console.log(
          `Error uploadFile::createAsyncThunk ${e.status} ${e.message}`
        );

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};
export const addUploadFileArrayStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  uploadFileStoreFactory: any
) => {
  builder
    .addCase(uploadFileStoreFactory.pending, (_state, _action) => {})
    .addCase(uploadFileStoreFactory.fulfilled, (state, action) => {
      state[parseInt(action.payload.data)].status = "SUCESSO";
    })
    .addCase(uploadFileStoreFactory.rejected, (state, action) => {
      state[parseInt(action.payload.data)].status = "FALHA";
    });
};
