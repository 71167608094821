// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

const API_PATH = API_URI + "/layouts_exams";

export const LayoutsExamsAPI = {
  getAll: async function (token = "", page: any, search: any,test_id:any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&test_id=${test_id}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  downloadCSV: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}/download-csv`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getCorrectors: async function (
    token = "",
    id: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/${id}/correctors?search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  addCorrector: async function (
    token = "",
    id: any,
    search: any,
    cancel = false,
    data: any = {}
  ) {
    return api
      .request({
        url: `${API_PATH}/${id}/add-corrector`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  removeCorrector: async function (
    token = "",
    id: any,
    search: any,
    cancel = false,
    data: any = {}
  ) {
    return api
      .request({
        url: `${API_PATH}/${id}/remove-corrector`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getDefaultLayout: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/default_layout?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getDefaultLayoutId: async function (token = "",id:any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/default_layout/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getDownload: async function (token = "", id: any,job_id:any, cancel = false) {
    let complement=''
    if(job_id){
      complement=`/${job_id}`;
    }
    return api
      .request({
        url: `${API_PATH}/download/${id}${complement}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdNoAuth: async function (
    token = "",
    id: any,
    class_id: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_URI}/get_layout_exam/${id}/${class_id}`,
        method: "GET",
        headers: {
          // Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          // "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, _id: any, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${_id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  updateClasses: async function (
    token: string,
    _id: any,
    data: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/classes/${_id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any,default_layout=false, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}?default_layout=${default_layout}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  deleteClass: async function (
    token = "",
    id: any,
    class_id: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/classes/${id}/${class_id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
