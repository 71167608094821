
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ExamsAPI } from "../../../apis/ExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchId = createAsyncThunk(
  "exams/get",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await ExamsAPI.getById(
        token,
        state.exams.exam_id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchId.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchId.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        state.model.name = action.payload.name;
        state.model.description = action.payload.description;
        state.model.type = action.payload.type;

        state.model.application_id = action.payload.application_id;
        state.model.exam_father_id = action.payload.exam_father_id;

        let disciplines: any = [];
        let disciplines_obj = [];
        for (let i = 0; i < action.payload.questions.length; i++) {
          if (!disciplines.includes(action.payload.questions[i].discipline_id)) {
            disciplines.push(action.payload.questions[i].discipline_id)
            disciplines_obj.push(action.payload.questions[i].discipline)

          }
        }
        action.payload.selected_disciplines = action.payload.selected_disciplines.filter((item: any) => disciplines.includes(item._id));
        disciplines_obj.map((item: any) => {
          let contain = action.payload.selected_disciplines.filter((item2: any) => item2._id == item._id).length == 0 ? false : true;
          if (!contain) {
            action.payload.selected_disciplines.push({
              _id: item._id,
              name: item.name,
              order: action.payload.selected_disciplines.length,
              number: 1
            });
          }
        })

        state.model.random_question = action.payload.random_question ?? false;
        state.model.random_per_category = action.payload.random_per_category ?? false;
        state.model.select_from_group = action.payload.select_from_group ?? false;
        state.model.selected_disciplines = action.payload.selected_disciplines ?? [];
        state.model.number_select_questions = action.payload.number_select_questions ?? 0;
        state.model.random_alternatives =  action.payload.random_alternatives ?? false;



        state.model.questions = action.payload.questions.map((item:any,id:any)=>item._id);
        let questions:any = {};
        action.payload.questions.map((item:any,id:any)=>{
          questions[item._id]=item;
        })
        state.questions = questions


        state.loading_screen = false;
      }
    )
    .addCase(
      fetchId.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
