// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

const API_PATH = API_URI + "/assignments";

export const AssignmentsAPI = {
  assign: async function (
    token: string,
    question_id: any,
    assignment_id: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/${assignment_id}/assign-question`,
        method: "POST",
        data: { question_id: question_id },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  getAllTeacher: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/all-teacher?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAll: async function (
    token = "",
    page: any,
    search: any,
    search_query: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${
          search ?? ""
        }&search_query=${encodeURIComponent(search_query)}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
