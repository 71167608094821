import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";

import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";

import { CorrectionsAPI } from "../../apis/CorrectionAPI";
import { saveFieldStoreFactory } from "../_stores/saveFieldStore";

export const releaseExamThunk = saveFieldStoreFactory(
  "correction-exams/release-exam",
  CorrectionsAPI.releaseExam,
  "correctionExams",
  "exam"
);

export const fetchExamsListThunk = fetchListStoreFactory(
  "correction-exams/list-exams",
  CorrectionsAPI.getAllExamsFromTeacher,
  "correctionExams"
);

export const fetchStudentsListThunk = fetchIdStoreFactory(
  "correction-exams/list-students",
  CorrectionsAPI.getAllStudentsFromExam,
  "correctionExams"
);

export const modelSlice = createSlice({
  name: "correction-exams",
  initialState: modelInitialState,
  reducers: {
    setExam: (state, action) => {
      return {
        ...state,
        exam: action.payload,
      };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    setQuery: listReducers.setQuery,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchExamsListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchStudentsListThunk);

    /*
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    //
    */
    // addFetchListFieldStoreFactoryReducer(builder, fetchExamsListThunk);
    // addFetchListFieldStoreFactoryReducer(builder, fetchCourseListThunk);
    // addFetchListFieldStoreFactoryReducer(builder, fetchStudentListThunk);
  },
});
