import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { DemandsAPI } from "../../../apis/DemandsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchParent = createAsyncThunk(
  "demand-manager/fetch-parent",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await DemandsAPI.getById(token, params.id, false);
    } catch (e: any) {
      console.log(
        `Error fetchParent::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchParentReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchParent.pending, (state: any, _action: any) => {
      state.screen.loadingScreen = true;
    })
    .addCase(fetchParent.fulfilled, (state, action) => {
      return {
        ...state,
        parent: action.payload,
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
        list: {
          ...state.list,
          all: action.payload.assignments,
        },
      };
    })
    .addCase(fetchParent.rejected, (state, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loadingScreen: false,
        },
      };
    });
};
