import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";


import { CorrectionsAPI } from "../../apis/CorrectionAPI";
import { addFetchListFromTwoIdStoreFactoryReducer, fetchListFromTwoIdStoreFactory } from "../_stores/fetchListFromTwoIdStore";

export const fetchExamStudentQuestionsListThunk = fetchListFromTwoIdStoreFactory(
  "corrections/list-exam-answers",
  CorrectionsAPI.getAllAnswersFromStudentExam,
  "correctionQuestions",
);

export const modelSlice = createSlice({
  name: "correctionQuestions",
  initialState: modelInitialState,
  reducers: {
    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setParentId: listReducers.setParentId,
    setSearch: listReducers.setSearch,

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListFromTwoIdStoreFactoryReducer(builder, fetchExamStudentQuestionsListThunk);
  },
});
