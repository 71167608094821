import { useEffect } from "react";

// classes
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { List } from "../../component/list/list";
import { useNavigate, useParams } from "react-router-dom";
import {
  setSearch,
  setSelectedId,
  setParentId,
} from "../../services/stores/correctionQuestionStore/actions";
import { fetchExamStudentQuestionsListThunk } from "../../services/stores/correctionQuestionStore/slice";
import { CorrectionStatus, getCorrectionStatus } from "../../constants/enum/CorrectionStatus";

export function CorrectionExamStudentQuestionsList() {
  const questions = useAppSelector(
    (store) => store.correctionQuestions.list.all
  );
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.correctionQuestions.screen
  );
  const { pagination } = useAppSelector((store) => store.correctionQuestions);
  const { search } = useAppSelector((store) => store.correctionQuestions.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) dispatch(setParentId(params.id));
    if (params.idS) dispatch(setSelectedId(params.idS));
    dispatch(fetchExamStudentQuestionsListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        hideEdit
        hideDelete
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Questões"}
        not_found_message={"Nenhuma questão encontrada"}
        search_text="Pesquise pelo nome da questão"
        onSearchButtonClick={() => {
          dispatch(fetchExamStudentQuestionsListThunk({ force: true }));
        }}
        onManagerActionClick={(id) => {
          navigate("/corrections/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={questions?.map((x: any) => {
          return {
            _id: x.correction._id,
            question: x.question_content,
            status:
              x.correction.status == CorrectionStatus.CORRECTED
                ? x.correction.claim && !x.correction.reply
                  ? "Chamado"
                  : "Corrigida"
                : getCorrectionStatus(x.correction.status),
          };
        })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "question", type: "text", header: "Questão" },
          { name: "status", type: "text", header: "Status" },
        ]}
      ></List>
    </div>
  );
}
