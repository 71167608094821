import { BsFileEarmarkSpreadsheet, BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdDelete, MdModeEdit } from "react-icons/md";

export function Actions(props: {
  actionItem: { id: any };
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onManagerClick?: () => void;
  onAuxiliar1Click?: () => void;
  hideManager?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  showAuxiliar1?: boolean;
}) {
  return (
    <div>
       {props.showAuxiliar1 == true  && (
        <span
          onClick={() => {
            if (props.onAuxiliar1Click) props.onAuxiliar1Click();
          }}
          className="inline-flex align-center jcc bg-success icon"
          style={{
            width: "2em",
            height: "2em",
            borderRadius: "7px",
          }}
          title="Baixar Arquivo .csv"
        >
          <BsFileEarmarkSpreadsheet size={17} color="white" />
        </span>
      )}
      {!props.hideManager && (
        <span
          onClick={() => {
            if (props.onManagerClick) props.onManagerClick();
          }}
          className="inline-flex align-center jcc bg-warning icon"
          style={{
            width: "2em",
            height: "2em",
            borderRadius: "7px",
            marginLeft: "2px",
          }}
          title="Gerenciar"
        >
          <BsFillEyeFill size={17} color="white" />
        </span>
      )}
      {!props.hideEdit && (
        <span
          onClick={() => {
            if (props.onEditClick) props.onEditClick();
          }}
          className="inline-flex align-center jcc bg-secondary icon"
          style={{
            width: "2em",
            height: "2em",
            borderRadius: "7px",
            marginRight: "2px",
            marginLeft: "2px",
          }}
          data-toggle="tooltip"
          title="Editar"
        >
          <MdModeEdit color="white" size={17} />
        </span>
      )}
      {!props.hideDelete && (
        <span
          onClick={() => {
            if (props.onDeleteClick) props.onDeleteClick();
          }}
          className="inline-flex align-center jcc bg-danger icon"
          style={{
            width: "2em",
            height: "2em",
            borderRadius: "7px",
          }}
          title="Deletar"
        >
          <MdDelete size={17} color="white" />
        </span>
      )}
    </div>
  );
}
