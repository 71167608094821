import moment from 'moment';

export const modelInitialState = {
    redirect: false,
    path: "/disciplines",
    loading_screen: false,
    data: [],
    pagination: {
      page: 1,
      last_page: 1,
    },
    discipline_id:null,
    msgErro: "",
    show_warning: false,
    show_erro: false,
    search: "",
    loading_search: true,
    showModal: false,

    name:'',
    loading_save:false,
    error_message: null,
};
