import { modelSlice } from "./slice";

export const {
  setDemandId,
  setDescription,
  setMultipleChoiceQuestionsQty,
  setEssayQuestionsQty,
  setActiveTab,
  setSearch,
  setWarning,
  setLoading,
  setErrorMessage,
  clearWindowState,
  setSelectedId,
  setPage,
  setTeacher,
  setDiscipline,
  setDisciplineText,
  setTeacherText
} = modelSlice.actions;
