import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addDeleteItemReducers } from "./async/deleteThunk";
import { addSaveReducers } from "./async/saveThunk";
import { addFetchListReducers } from "./async/fetchListThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";
import { setChildVisible } from "./sync/setChildVisible";
import moment from "moment";
import { addFetchIdReducers } from "./async/fetchIdThunk";
import { addFetchListFilteredReducers } from "./async/fetchListFilteredThunk";
import { addFetchListFilteredTagReducers } from "./async/fetchListTagThunk";
import { addSaveZipReducers } from "./async/saveZipThunk";
import { addFetchJobIdReducers } from "./async/fetchJobThunk";
import { addFetchFilesIdReducers } from "./async/fetchFilesThunk";
import { paginationReducers } from "../../../constants/consts";

export const modelSlice = createSlice({
  name: "examsList",
  initialState: modelInitialState,
  reducers: {
    setChildVisible: (state, action) => setChildVisible(state, action),
    setModel: (state, action: PayloadAction<any>) => {
      return { ...state, model: action.payload };
    },
    setState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setLoadingInput: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_input: action.payload };
    },
    setPage: paginationReducers.setPage,
    setLoadingScreen: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_screen: action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_save: action.payload };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      return { ...state, search: action.payload };
    },
    setErrorMessage: (state, action: PayloadAction<any>) => {
      return { ...state, error_message: action.payload };
    },
    setId: (state, action: PayloadAction<any>) => {
      return { ...state, exam_id: action.payload };
    },
    setWarning: (state, action: PayloadAction<any>) => {
      return { ...state, show_warning: action.payload };
    },
    clearState: (state) => {
      return {
        ...state, ...modelInitialState
      };
    },
  },
  extraReducers: (builder) => {
    addFetchListReducers(builder);
    addFetchListFilteredReducers(builder);
    addFetchIdReducers(builder);
    addDeleteItemReducers(builder);
    addSaveReducers(builder);
    addUpdateReducers(builder);
    addFetchListFilteredTagReducers(builder);
    addSaveZipReducers(builder);
    addFetchJobIdReducers(builder);
    addFetchFilesIdReducers(builder);
  },
});
