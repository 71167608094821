import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../services/stores/questionStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { Form, FormControl as Input, InputGroup, Button, Spinner } from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { Close } from '@material-ui/icons';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from "../../component/base/DefaultButton";
import { save } from "../../services/stores/questionStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading, setModel, setErrorMessage, clearState, setId, setTag, setState, clearStateModal } from "../../services/stores/questionStore/actions";
import { update } from "../../services/stores/questionStore/async/updateThunk";
import { fetchId } from "../../services/stores/questionStore/async/fetchIdThunk";
import JoditEditor from 'jodit-react';
import { FaListAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';

export const QuestionModalView = (props: any) => {
    const { questionSelect, showModalView
    } = useAppSelector(
        (store) => store.questions
    );
    const dispatch = useAppDispatch();
    let question: any = questionSelect;
    return (
        <Modal
            show={showModalView == true}
            dialogClassName="modal_width"
            onHide={() => dispatch(setState({ showModalView: false }))}
        >
            {question != null && <>
                <Modal.Header closeButton closeLabel=''><Modal.Title>Questão<br /></Modal.Title></Modal.Header>

                {(question.type == 'objective' || question._id == undefined) && <Modal.Body>
                    <p style={{ color: 'black' }}><b>Escolaridade:</b> {question.degree}</p>
                    <p style={{ color: 'black' }}><b>Dificuldade:</b> {question.difficulty_level}</p>
                    <p style={{ color: 'black' }}><b>Enunciado:</b></p>



                    <div className="custom_scrollbar">

                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.question }}>

                        </div>
                        <hr />
                        <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: question.correct == 'a' ? 'green' : 'black', fontWeight: question.correct == 'a' ? 'bold' : 'normal' }}>a)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.alternatives[0].alternative }}>

                                </div>
                            </div>

                        </div>
                        {question.number_alternative >= 2 && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: question.correct == 'b' ? 'green' : 'black', fontWeight: question.correct == 'b' ? 'bold' : 'normal' }}>b)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.alternatives[1].alternative }}>

                                </div>
                            </div>

                        </div>}
                        {question.number_alternative >= 3 && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: question.correct == 'c' ? 'green' : 'black', fontWeight: question.correct == 'c' ? 'bold' : 'normal' }}>c)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.alternatives[2].alternative }}>

                                </div>
                            </div>

                        </div>}
                        {question.number_alternative >= 4 && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: question.correct == 'd' ? 'green' : 'black', fontWeight: question.correct == 'd' ? 'bold' : 'normal' }}>d)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.alternatives[3].alternative }}>

                                </div>
                            </div>

                        </div>}

                        {question.number_alternative >= 5 && <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ width: '10%' }}>
                                <p style={{ color: question.correct == 'e' ? 'green' : 'black', fontWeight: question.correct == 'e' ? 'bold' : 'normal' }}>e)</p>
                            </div>
                            <div style={{ width: '90%' }}>

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.alternatives[4].alternative }}>

                                </div>
                            </div>

                        </div>}

                    </div>

                </Modal.Body>}


                {question.type == 'set' && <Modal.Body>
                    <div className="custom_scrollbar">
                        <p style={{ color: 'black' }}><b>Enunciado geral:</b></p>

                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.question }}>

                        </div>

                        {question.questions.map((item2: any, id2: any) => (<div key={id2}>
                            <hr />
                            <p style={{ color: 'black' }}><b>Escolaridade:</b> {item2.degree}</p>
                            <p style={{ color: 'black' }}><b>Dificuldade:</b> {item2.difficulty_level}</p>
                            <p style={{ color: 'black' }}><b>Questão {id2 + 1}:</b></p>

                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.question }}>

                            </div>
                            <p style={{ color: 'black' }}><b>Alternativas:</b></p>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ width: '10%' }}>
                                    <p style={{ color: item2.correct == 'a' ? 'green' : 'black', fontWeight: item2.correct == 'a' ? 'bold' : 'normal' }}>a)</p>
                                </div>
                                <div style={{ width: '90%' }}>

                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternatives[0].alternative }}>

                                    </div>
                                </div>

                            </div>
                            {item2.number_alternative >= 2 && <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ width: '10%' }}>
                                    <p style={{ color: item2.correct == 'b' ? 'green' : 'black', fontWeight: item2.correct == 'b' ? 'bold' : 'normal' }}>b)</p>
                                </div>
                                <div style={{ width: '90%' }}>

                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternatives[1].alternative }}>

                                    </div>
                                </div>

                            </div>}
                            {item2.number_alternative >= 3 && <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ width: '10%' }}>
                                    <p style={{ color: item2.correct == 'c' ? 'green' : 'black', fontWeight: item2.correct == 'c' ? 'bold' : 'normal' }}>c)</p>
                                </div>
                                <div style={{ width: '90%' }}>

                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternatives[2].alternative }}>

                                    </div>
                                </div>

                            </div>}
                            {item2.number_alternative >= 4 && <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ width: '10%' }}>
                                    <p style={{ color: item2.correct == 'd' ? 'green' : 'black', fontWeight: item2.correct == 'd' ? 'bold' : 'normal' }}>d)</p>
                                </div>
                                <div style={{ width: '90%' }}>

                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternatives[3].alternative }}>

                                    </div>
                                </div>

                            </div>}

                            {item2.number_alternative >= 5 && <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ width: '10%' }}>
                                    <p style={{ color: item2.correct == 'e' ? 'green' : 'black', fontWeight: item2.correct == 'e' ? 'bold' : 'normal' }}>e)</p>
                                </div>
                                <div style={{ width: '90%' }}>

                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternatives[4].alternative }}>

                                    </div>
                                </div>

                            </div>}
                        </div>))}

                    </div>

                </Modal.Body>}

                {(question.type == 'text') && <Modal.Body>
                    <p style={{ color: 'black' }}><b>Escolaridade:</b> {question.degree}</p>
                    <p style={{ color: 'black' }}><b>Dificuldade:</b> {question.difficulty_level}</p>
                    <p style={{ color: 'black' }}><b>Enunciado:</b></p>
                    <div className="custom_scrollbar">

                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: question.question }}>

                        </div>
                    </div>

                </Modal.Body>}
            </>}

        </Modal>

    );
}
