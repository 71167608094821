import { useEffect, useState } from "react";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/demandStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setName,
  setDescription,
  setKeyword,
  setKeywords,
  setEndDate,
  setEndTime,
  setErrorMessage,
  clearWindowState,
} from "../../../services/stores/demandStore/actions";
import { update } from "../../../services/stores/demandStore/async/updateThunk";
import { fetchId } from "../../../services/stores/demandStore/async/fetchIdThunk";

export function DemandsCreate(props: { editMode?: boolean }) {
  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.demands.screen
  );
  const { name, description, keyword, keywords, endDate, endTime } =
    useAppSelector((store) => store.demands.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    if (props.editMode) {
      dispatch(update()).then((result: any) => {
        if (result?.payload?.status === "success") {
          history("/demands");
        }
      });
    } else {
      dispatch(save()).then((result: any) => {
        if (result?.payload?.status === "success") {
          history("/demands");
        }
      });
    }
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchId({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Demanda
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma demanda do seu sistema"
                : "Cadastre as demandas do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen === false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>
                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da demanda"
                        value={name}
                        onChange={(e) => dispatch(setName(e.target.value))}
                        required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição </Form.Label>
                      <Input
                        placeholder="Descrição da demanda"
                        value={description}
                        onChange={(e) =>
                          dispatch(setDescription(e.target.value))
                        }
                        as="textarea"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Tags </Form.Label>
                      <div>
                        <Input
                          placeholder="Digite uma tag"
                          value={keyword}
                          onChange={(e) => dispatch(setKeyword(e.target.value))}
                        />

                        {
                          <div
                            className="row"
                            style={{ marginLeft: 0, marginRight: 0 }}
                          >
                            {keywords.map((item, id) => (
                              <div
                                key={id}
                                style={{
                                  backgroundColor: "#f2f2f2",
                                  borderRadius: 10,
                                  padding: 5,
                                  width: "auto",
                                  marginTop: 5,
                                  display: "flex",
                                  marginRight: 5,
                                }}
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    textAlign: "center",
                                  }}
                                >
                                  {item}
                                </p>
                                <Close
                                  onClick={() => {
                                    let palavras_chaves = keywords.filter(
                                      (item2: any, id2: any) => id2 !== id
                                    );
                                    dispatch(setKeywords(palavras_chaves));
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    width: "0.9rem",
                                    marginLeft: 3,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        }

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 5,
                          }}
                        >
                          <Button
                            variant="outline-primary"
                            onClick={(e) => {
                              if (keyword.trim().length === 0) {
                                return;
                              }
                              let palavras_chaves: any[] = [...keywords];
                              palavras_chaves.push(encodeURIComponent(keyword.toLowerCase().replace(/[^\w\s]/gi, '-')).replace(/%20/g,'-'));
                              dispatch(setKeywords(palavras_chaves));
                              dispatch(setKeyword(""));
                            }}
                            className="flex jcc align-center"
                          >
                            incluir tag
                          </Button>
                        </div>
                      </div>
                    </div>
                  </InputGroup>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data de encerramento <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={(date) => dispatch(setEndDate(date))}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário de encerramento <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={endTime}
                          onChange={(date) => dispatch(setEndTime(date))}
                        />
                      </div>
                    </InputGroup>
                  </MuiPickersUtilsProvider>

                  {loadingSave === false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/demands"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/demands")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} />{" "}
                        Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar demanda"
                        disabled={loadingSave}
                        loadingtext="Salvando demanda"
                        loading={loadingSave}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loadingSave}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loadingSave === true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen === true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
