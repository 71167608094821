import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const updateStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any,
  preSaveValidate: any = null
) => {
  return createAsyncThunk(
    typePrefix,
    async (_params, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      if (preSaveValidate) {
        const preSaveStatus: any = preSaveValidate(state[slice].model);
        if (preSaveStatus.error)
          return handleThunkRejection(preSaveStatus.e, rejectWithValue);
      }
      try {
        return await apiFunction(token, state[slice].model);
      } catch (e: any) {
        console.log(`Error update::createAsyncThunk ${e.status} ${e.message}`);
        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};
export const addUpdateStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  updateStoreFactory: any
) => {
  builder
    .addCase(updateStoreFactory.pending, (state, _action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: true,
        },
      };
    })
    .addCase(updateStoreFactory.fulfilled, (state, action) => {
      var all = [...state.list.all];
      var id = all.findIndex((x) => x._id === action.payload._id);
      if (id >= 0) all[id] = action.payload;
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: false,
        },
        list: { ...state.list, all: all },
      };
    })
    .addCase(updateStoreFactory.rejected, (state, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorMessages: action.payload.message,
          loadingSave: false,
        },
      };
    });
};
