import { useEffect, useState } from "react";

// corrections
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { Grid } from "../../component/list/grid";
import { Tab, Tabs } from "@material-ui/core";
import { Header } from "../../component/list/header";
import { useNavigate } from "react-router-dom";
import {
  setSearch,
  setPage,
  setQuery,
  setExam,
} from "../../services/stores/correctionExamStore/actions";
import {
  fetchExamsListThunk,
  releaseExamThunk,
} from "../../services/stores/correctionExamStore/slice";
import { CorrectionStatus } from "../../constants/enum/CorrectionStatus";
import { Alert } from "../../component/list/alert";

export function CorrectionExamsList() {
  const [showRelease, setShowRelease] = useState(false);
  const exams = useAppSelector((store) => store.correctionExams.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.correctionExams.screen
  );
  const { pagination } = useAppSelector((store) => store.correctionExams);
  const { search } = useAppSelector((store) => store.correctionExams.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(
      setQuery(`question.type=${activeTab == 0 ? "text" : "objective"}`)
    );
    dispatch(fetchExamsListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card">
            <Alert
              title="Atenção"
              text={
                "Deseja liberar todas as correções de provas para os alunos?"
              }
              show_warning={showRelease}
              onCancel={() => setShowRelease(false)}
              onConfirm={(id: any) => {
                dispatch(releaseExamThunk());
                setShowRelease(false);
              }}
            ></Alert>
            <Header pronoun={"a"} title={"Exames"}></Header>

            <div className="row">
              <div className="col-12">
                <Tabs
                  value={activeTab}
                  onChange={(_: any, value: any) => {
                    setActiveTab(value);
                    dispatch(
                      setQuery(
                        `question.type=${value == 0 ? "text" : "objective"}`
                      )
                    );
                    dispatch(fetchExamsListThunk({ force: true }));
                  }}
                  style={{ backgroundColor: "#ececf1" }}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={"scrollable"}
                  scrollButtons={"on"}
                >
                  <Tab label="Dissertativas" />
                  <Tab label="Objetivas" />
                </Tabs>
                <div style={{ marginTop: 10 }}>
                  <Grid
                    showAuxiliar1
                    hideEdit
                    hideDelete
                    onAuxiliar1Click={(id) => {
                      dispatch(setExam({ id }));
                      setShowRelease(true);
                    }}
                    onManagerActionClick={(id) => {
                      navigate("/correction-exams/" + id);
                    }}
                    onChangeSearchInput={(value: string) => {
                      dispatch(setSearch(value));
                    }}
                    search_input_value={search}
                    not_found_message={"Nenhum exame encontrado"}
                    search_text="Pesquise pelo nome do exame"
                    onSearchButtonClick={() => {
                      dispatch(fetchExamsListThunk({ force: true }));
                    }}
                    onChangePagination={(value: number) => {
                      dispatch(setPage(value));
                      dispatch(fetchExamsListThunk({ force: true }));
                    }}
                    loading_screen={loadingScreen}
                    loading_search={loadingSearch}
                    items={exams?.map((x: any) => {
                      let corrected = 0;
                      let pending = 0;
                      let answered = 0;
                      x.corrections?.forEach((element: any) => {
                        corrected +=
                          element.status === CorrectionStatus.CORRECTED ? 1 : 0;
                        pending += element.claim ? 1 : 0;
                        answered += element.claim && element.reply ? 1 : 0;
                      });
                      return {
                        _id: x._id,
                        name: x.name,
                        students: `${corrected}/${x.corrections?.length}`,
                        claims: `${answered}/${pending}`,
                      };
                    })}
                    pagination={{
                      last_page: pagination?.totalPages,
                      current_page: pagination?.page,
                    }}
                    fields={[
                      { name: "name", type: "text", header: "Exame" },
                      { name: "students", type: "text", header: "Correções" },
                      { name: "claims", type: "text", header: "Chamados" },
                    ]}
                  ></Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
