import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addFetchListReducers } from "./async/fetchListThunk";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";
import { addFetchParentReducers } from "./async/fetchParent";

export const modelSlice = createSlice({
  name: "assignmentsList",
  initialState: modelInitialState,
  reducers: {
    setDemandId: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        model: { ...state.model, demand: action.payload },
      };
    },
    setDiscipline: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        model: { ...state.model, discipline: action.payload },
      };
    },
    setTeacher: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        model: { ...state.model, teacher: action.payload },
      };
    },
    setDescription: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },
    setMultipleChoiceQuestionsQty: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: {
          ...state.model,
          multiple_choice_questions_qty: action.payload,
        },
      };
    },
    setEssayQuestionsQty: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, essay_questions_qty: action.payload },
      };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    setActiveTab: (state, action: PayloadAction<any>) => {
      return { ...state, model: { ...state.model, activeTab: action.payload } };
    },
    clearWindowState: (state) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListReducers(builder);
    addFetchParentReducers(builder);
  },
});
