import { Navigate, Route, Routes } from "react-router-dom";
import { LoginView } from "../screens/LoginView";


export default function AuthStack() {

  const token = localStorage.getItem('token');
  return (
    <Routes>
      <Route path="/" element={<LoginView />} />
      {<Route path="/*" element={<Navigate to="/" />} />}

    </Routes>
  );
}
