import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useParams } from "react-router-dom";
import { setState } from "../../../services/stores/examStore/actions";
import { saveZip } from "../../../services/stores/examStore/async/saveZipThunk";
import { fetchJobId } from "../../../services/stores/examStore/async/fetchJobThunk";
import { CircularProgress } from "@material-ui/core";
import DefaultButton from "../../../component/base/DefaultButton";
import { Attention } from "../../../component/base/Attention";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";

const ModalAddZip = props => {

    const params = useParams();

    const { showModal, file, fileValue, loading_zip, jobId, progress, messageProgress, loading_job, jobs,error_message,model } = useAppSelector(
        (store) => store.exams
    );

    const [msgErro, setMsgErro] = useState('');
    const dispatch = useAppDispatch();
    const timerRef = useRef(null);

    useEffect(() => {
        dispatch(setState({ loading_job: true }))
        get_status_job();
        return () => {
            dispatch(setState({fileValue:'',file:''}))
            clearTimeout(timerRef.current);
        }
    }, [])


    const save_file = useCallback(() => {
        dispatch(saveZip({
            id: params.id, callback: (e) => {
                console.log(e);
                dispatch(setState({ progress: (e.loaded / e.total) * 100, messageProgress: 'Enviando ' + parseInt((e.loaded / e.total) * 100) + '%' }))
            }
        })).then((data) => {
            dispatch(setState({ loading_job: true, messageProgress: '',loading_zip:false,file:'',fileValue:'' }))
            get_status_job(false);
            
        })

    }, [loading_zip, params]);



    const get_status_job = useCallback((loop=true) => {
        dispatch(fetchJobId({id:params.id})).then((data) => {
            console.log(data)
            console.log(loop);
            if(loop==true){
                timerRef.current = setTimeout(() => {
                    get_status_job();
    
                }, 10000);
            }
           

        })


    }, [file, params]);
    console.log(fileValue)

    return (
        <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => dispatch(setState({ showModal: false }))}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title> Adicionar .zip de {model.type=='objective'?'folhas de resposta':'cadernos de resposta'}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p style={{ fontSize: '1rem', color: 'black' }}>{model.type=='objective'?'Todas as folhas de resposta devem estar no formato .png ou .jpg, e todas compactadas em um arquivo .zip, que pode ser selecionado abaixo.':'Todos os cadernos de resposta devem estar no formato .pdf, e todos compactados em um arquivo .zip, que pode ser selecionado abaixo.'}</p>

                        <div className="table-rep-plugin w-100">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Status</th>
                                            <th>Erro</th>
                                            <th>Progresso</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading_job == false && jobs.map((item, id) => (

                                            <tr key={id}>
                                                <td style={{ color: (item.status == 'Error' ? 'red' : (item.status == 'Success' ? 'green' : 'darkgoldenrod')) }}>{item.status}</td>
                                                <td>{item.error_friendly ?? '-'}</td>
                                                <td>{item.progress}</td>
                                                <td>{item.total}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                {loading_job == true &&
                                    <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                        <CircularProgress />
                                    </div></div>

                                }
                                {loading_job == false && jobs.length == 0 && (
                    <div className="row w-100">
                      <div className="col-12 mt-3">
                        <p style={{ textAlign: "center" }}>
                          Nenhum upload encontrado
                        </p>
                      </div>
                    </div>
                  )}
                            </div>
                        </div>
                        <br/>

                        {error_message  && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => dispatch(setState({error_message:null}))}></button>


                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{error_message.join('\n')}</p>

                            </div>
                            <br />

                        </div>}
                        <InputGroup>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label >{model.type=='objective'? 'Folhas de resposta (.zip)':'Cadernos de resposta (.zip)'} <Attention /> </Form.Label>
                                <input type="file" placeholder="Arquivo de folhas de resposta (.zip)"
                                    accept='.zip'
                                    value={''}
                                    className="form form-control"
                                    onChange={(e) => {
                                        dispatch(setState({ file: e.target.files[0], fileValue: e.target.value }))
                                    }} />
                                {file && <p style={{marginTop:4,fontSize:13}}>Arquivo selecionado: {file.name}</p>}
                            </div>
                        </InputGroup>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>

                {loading_zip == false && <div className="w100 inline-flex justify-content-end margin-top-1">
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            save_file()
                        }}
                        bg="confirm"
                        text="Salvar arquivos"
                        disabled={loading_zip}
                        loadingtext="Salvando arquivos"
                        loading={loading_zip}
                    />
                </div>}
                {loading_zip == true && <div className="w100 margin-top-1">
                    <ProgressBar style={{ height: 40, fontSize: 20 }} animated now={progress} label={messageProgress} />
                </div>}

            </Modal.Footer>


        </Modal>

    );
}

export default ModalAddZip;