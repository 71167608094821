import { useState } from "react";

// unidades
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

import { Searchbar } from "./searchbar";
import { Actions } from "./actions";
import { Header } from "./header";
import { Alert } from "./alert";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { Button } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../base/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";

export function List(props: {
  show_warning: any;
  title: string;
  button_text?: string;
  button_link?: string;
  search_text?: string;
  deleting_message?: string;
  not_found_message: string;
  search_input_value: any;
  additionalButtons?: any[];
  onDeleteCancel?: () => any;
  onDeleteConfirm?: () => any;
  loading_screen: boolean;
  fields: any[];
  loading_search: boolean;
  items: any[];
  onDeleteActionClick?: (id: any) => void;
  onEditActionClick?: (id: any) => void;
  onManagerActionClick?: (id: any, params?: any) => void;
  onAuxiliar1ActionClick?: (id: any, params?: any) => void;
  pronoun?: string;
  pagination: { last_page: number; current_page: number };
  onChangePagination: (page: number) => void;
  onSearchButtonClick: () => void;
  onChangeSearchInput: (value: string) => void;
  onClickButton?: any;
  hideManager?: boolean;
  showAuxiliar1?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hide_margin?:boolean;
}) {
  const [image, setImage] = useState("");
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { me } = useAppSelector((store: any) => store.auth); // TODO: refatorar isso para ficar independente
  const history = useNavigate();
  const location = useLocation();

  return (
    <div className={props.hide_margin?'':"col-12"}>
      <div className={props.hide_margin?'':"py-5 px-3"}>
        <div className={props.hide_margin?'':"card-body"} id="card">
          {!props.hide_margin && <Header pronoun={props.pronoun} title={props.title}></Header>}

          <Alert
            title="Atenção"
            text={props.deleting_message}
            show_warning={props.show_warning}
            onCancel={() => {
              if (props.onDeleteCancel) props.onDeleteCancel();
            }}
            onConfirm={() => {
              if (props.onDeleteConfirm) props.onDeleteConfirm();
            }}
          ></Alert>
          <div className="row mb-2">
            <div className="col">
              {props.additionalButtons?.map((item) => (
                <Button
                  variant="success"
                  onClick={(e) => item.eventHandler(e)}
                  className="flex align-center jcc"
                >
                  {item.label}
                </Button>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* <hr /> */}

              <Searchbar
                search_input_value={props.search_input_value}
                button_text={props.button_text}
                button_link={props.button_link}
                search_text={props.search_text}
                onClickButton={props.onClickButton}
                onChangeSearchInput={function (value: any): void {
                  props.onChangeSearchInput(value);
                }}
                onSearchButtonClick={() => {
                  props.onSearchButtonClick();
                }}
              ></Searchbar>
              {!props.loading_screen && (
                <div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{
                        border: "1px solid #d6d6d6",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <table
                        id="tech-companies-1"
                        className={props.hide_margin?'table table-striped':"table table-hover"}
                        style={{
                          borderRadius: "2rem",
                        }}
                      >
                        <thead>
                          <tr>
                            {props.fields.map((item: any, id: any) => (
                              <th key={id}>{item.header}</th>
                            ))}
                            {(!props.hideManager ||
                              !props.hideDelete ||
                              !props.hideEdit) && (
                              <th data-priority="3">Ações</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {props.loading_search == false &&
                            props.items?.map(
                              (
                                item: {
                                  [x: string]: moment.MomentInput;
                                  id: any;
                                },
                                id: any
                              ) =>
                                item !== undefined && (
                                  <tr key={id}>
                                    {props.fields.map(
                                      (
                                        field: {
                                          type: string;
                                          name: string | number;
                                        },
                                        field_id: any
                                      ) => {
                                        let field_actual = field.name
                                          .toString()
                                          .split(".");
                                        let item_: any = item;
                                        field_actual.map((field1, id1) => {
                                          item_ = item_[field1] ?? "-";
                                        });
                                        return field.type === "text" ? (
                                          <td key={field_id}>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item_?.toString() ?? "",
                                              }}
                                            ></div>
                                          </td>
                                        ) : field.type === "date" ? (
                                          <td key={field_id}>
                                            {item_ && moment(item_).isValid()
                                              ? moment(item_).format(
                                                  "DD/MM/YYYY HH:mm"
                                                )
                                              : "-"}
                                          </td>
                                        ) : (
                                          "[undefined type]"
                                        );
                                      }
                                    )}
                                    {(!props.hideManager ||
                                      !props.hideDelete ||
                                      !props.hideEdit ||
                                      props.showAuxiliar1) && (
                                      <td>
                                        <Actions
                                          actionItem={item}
                                          onEditClick={() => {
                                            if (props.onEditActionClick)
                                              props.onEditActionClick(item._id);
                                          }}
                                          onDeleteClick={() => {
                                            if (props.onDeleteActionClick)
                                              props.onDeleteActionClick(
                                                item._id
                                              );
                                          }}
                                          onManagerClick={() => {
                                            if (props.onManagerActionClick)
                                              props.onManagerActionClick(
                                                item._id,
                                                item
                                              );
                                          }}
                                          onAuxiliar1Click={() => {
                                            if (props.onAuxiliar1ActionClick)
                                              props.onAuxiliar1ActionClick(
                                                item._id
                                              );
                                          }}
                                          showAuxiliar1={props.showAuxiliar1}
                                          hideManager={props.hideManager}
                                          hideEdit={props.hideEdit}
                                          hideDelete={
                                            props.hideDelete ||
                                            item._id == me._id
                                          }
                                        ></Actions>
                                      </td>
                                    )}
                                  </tr>
                                )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {props.loading_search && (
                    <div className="row" style={{ margin: 0 }}>
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <CircularProgress />
                      </div>
                    </div>
                  )}
                  {props.loading_search == false &&
                    props.items?.length == 0 && (
                      <div className="row w-100">
                        <div className="col-12">
                          <p style={{ textAlign: "center", margin: '2vh 0', fontWeight: 'normal' }}>
                            {props.not_found_message}
                          </p>
                        </div>
                      </div>
                    )}
                  <br/>
                  <Pagination
                    className={props.pagination.last_page == 1 ? "hide" : ""}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    count={props.pagination.last_page}
                    page={props.pagination.current_page}
                    onChange={(_, page) => {
                      props.onChangePagination(page);
                    }}
                  />
                  {props.hide_margin==false && (location.pathname.match(/\//g) || []).length > 1 && (
                    <DefaultButton
                      bg="secondary"
                      text="Voltar"
                      icon={<RiArrowGoBackLine />}
                      onClick={() => {
                        history(-1);
                        return undefined;
                      }}
                    />
                  )}
                </div>
              )}

              {props.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <CircularProgress />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
