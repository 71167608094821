import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { ExamsAPI } from "../../../apis/ExamsAPI";
import moment from "moment";

export const update = createAsyncThunk(
  "exams/update",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await ExamsAPI.update(token, {
        _id:state.exams.exam_id,
        ...state.exams.model
      });
    } catch (e: any) {
      console.log(
        `Error update::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addUpdateReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(update.pending, (state, _action) => {
      return { ...clearErrorState(state), loading_save: true };
    })
    .addCase(update.fulfilled, (state, action) => {
      var data = [...state.data];
      console.log(action.payload)
      var farmId = data.findIndex((x) => x._id === action.payload.data._id);
      if (farmId >= 0) data[farmId] = action.payload.data;
      return { ...clearErrorState(state), loading_save: false, data: data };
    })
    .addCase(update.rejected, (state: any, action: { payload: any }) => {
      const error = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        error_message: error.message,
        loading_save: false,
      };
    });
};
