import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../services/stores/questionStore/async/fetchListThunk";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { Form, FormControl as Input, InputGroup, Button, Spinner } from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { Close } from '@material-ui/icons';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from "../../component/base/DefaultButton";
import { save } from "../../services/stores/questionStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading, setModel, setErrorMessage, clearState, setId, setTag, setState, clearStateModal } from "../../services/stores/questionStore/actions";
import { update } from "../../services/stores/questionStore/async/updateThunk";
import { fetchId } from "../../services/stores/questionStore/async/fetchIdThunk";
import JoditEditor from 'jodit-react';
import { FaListAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { API_URI, STORAGE_URL } from "../../constants/consts";

export function QuestionModalCreate(props: any) {
    const { error_message_modal, model, model_modal, tag, show_modal_register, loading_save, loading_screen, difficulty_levels, degrees, question_modal,
        loadings_images_modal, number_alternatives, corretas, alternativa_a_modal, alternativa_b_modal, alternativa_c_modal, alternativa_d_modal, alternativa_e_modal,
        file_select_modal
    } = useAppSelector(
        (store) => store.questions
    );

    const {token} = useAppSelector(
        (store) => store.auth
    )
    const dispatch = useAppDispatch();
    const editor_question = useRef<any>();
    const editor_alternative_a = useRef<any>();
    const editor_alternative_b = useRef<any>();
    const editor_alternative_c = useRef<any>();
    const editor_alternative_d = useRef<any>();
    const editor_alternative_e = useRef<any>();

    const configs_array = useRef<any>();
    const config_question = useRef<any>();
    const config_alternative_a = useRef<any>();
    const config_alternative_b = useRef<any>();
    const config_alternative_c = useRef<any>();
    const config_alternative_d = useRef<any>();
    const config_alternative_e = useRef<any>();


    const [show_warning, setShowWarning] = useState(false);
    // const [loading_screen, setLoadingScreen] = useState(false);

    const [loading_search, setLoadingSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [edit, setEdit] = useState(false);


    const history = useNavigate();
  
    const load_configs = () => {
        configs_array.current = [];
        configs_array.current.push(config_question);
        configs_array.current.push(config_alternative_a);
        configs_array.current.push(config_alternative_b);
        configs_array.current.push(config_alternative_c);
        configs_array.current.push(config_alternative_d);
        configs_array.current.push(config_alternative_e);

        for (let i = 0; i < 6; i++) {
            configs_array.current[i] = {
                height: 300,
                allowResizeTags: ['img', 'iframe', 'table', 'jodit'],
                resizer: {
                    // useAspectRatio: false, // don't save,
                    // useAspectRatio: ['img'], // save only for images (default value)
                    // useAspectRatio: true, // save for all
                    showSize: true,
                    useAspectRatio: true
                },
                tabIndex: 1,
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
                defaultActionOnPaste: 'insert_only_text',
                defaultActionOnPasteFromWord: 'insert_only_text',
                pasteHTMLActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                pasteFromWordActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                readonly: loadings_images_modal[i],
                language: 'pt_br',
                toolbarButtonSize: 'large',
                "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
                uploader: {
                    url: API_URI+'/questions/file',  //your upload api url
                    insertImageAsBase64URI: false, //not inster base64
                    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    headers: { "accept": `application/json`, 'Authorization': token },
                    filesVariableName: function (t: any) {
                        return 'file';
                    }, //"files",
                    withCredentials: false,
                    pathVariableName: 'path',
                    //   format: 'json',
                    method: 'POST',
                    prepareData: (formdata: any) => {
                        console.log(formdata)
                        let loadings_ = [...loadings_images_modal];
                        loadings_[i] = true;
                        dispatch(setState({ loadings_images_modal: loadings_ }));
                        return formdata;
                    },
                    isSuccess: (e: any) => {
                        console.log(e);

                        return 'Upload realizado com sucesso';
                    },
                    getMessage: (e: any) => {
                        console.log(e);

                        return e;
                    },
                    process: (resp: any) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        let files: any[] = [];
                        console.log(resp);
                        files.unshift(STORAGE_URL+'/'+resp.data);
                        let loadings_ = [...loadings_images_modal];
                        loadings_[i] = false;
                        dispatch(setState({ loadings_images_modal: loadings_ }));

                        return {
                            files: files,

                        };
                    },
                    error: (elem: any, e: any) => {
                        // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                        console.log(e);
                        console.log(elem);
                        console.log('Bearer ' + localStorage.getItem('token'));
                    },
                    defaultHandlerSuccess: function (resp: any, elem: any) { // `this` is the editor.
                        let t: any = this;
                        if (resp.files && resp.files.length) {
                            resp.files.forEach((filename: any, index: any) => { //edetor insertimg function
                                t.selection.insertImage(filename);
                            });
                        }

                        // debugger;

                    },
                    defaultHandlerError: (elem: any, e: any) => {
                        // elem.j.e.fire('errorMessage', e.message);
                        console.log(e);
                        console.log(elem);
                        // this.setState({ loading_image_enunciado: false });
                        let loadings_ = [...loadings_images_modal];
                        loadings_[i] = false;
                        dispatch(setState({ loadings_images_modal: loadings_ }));
                    },
                    //   contentType: function (e) {
                    //     return (

                    //       'application/x-www-form-urlencoded; charset=UTF-8'
                    //     );
                    //   },
                },
            }
        }
    }

    useEffect(() => {
        // const question_id = history.pa
        load_configs();


        dispatch(clearStateModal());
        // setState({ number_alternative: 5 });
        // if (params.id != undefined) {
        //     dispatch(setId(params.id))
        //     setEdit(true);
        //     dispatch(fetchId({ force: true }));

        // }

        return () => {
            dispatch(clearStateModal());
            Array.from(document.getElementsByClassName('jodit-wysiwyg')).forEach((el:any) => {
                // Do stuff here
                el!.blur()
            })
        }
    }, []);

    useEffect(() => {
        // const question_id = history.pa
        dispatch(clearStateModal());

        
    }, [show_modal_register]);
    console.log(edit)
    return (

        <Modal
            show={show_modal_register == true}
            dialogClassName="modal_width"
            onHide={() => dispatch(setState({ show_modal_register: false }))}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Cadastrar Questão do Conjunto<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div >
                    {error_message_modal != null && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                        <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => dispatch(setState({ error_message_modal: null }))}></button>


                            <p style={{ marginBottom: 0 }}>{error_message_modal}</p>

                        </div>
                        <br />

                    </div>}
                    <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Nível de Escolaridade <Attention /></Form.Label>
                            <select className="form-select " onChange={(e) => {
                                let model_ = { ...model_modal };
                                model_.degree = e.target.value;
                                dispatch(setState({ model_modal: model_ }));
                                // this.setState({ escolaridade: e.target.value })
                            }} value={model_modal.degree}>
                                <option
                                    value={''}
                                >{'Selecione um nível de escolaridade'}</option>

                                {degrees.map((item: any, id: number) => (
                                    <option
                                        key={id}
                                        value={item}
                                    >{item}</option>
                                ))
                                }
                                {/* <option
                                            value={'dissertativa'}
                                        >{'Questão dissertativa'}</option> */}
                            </select>
                        </div>
                    </InputGroup>
                    <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Nível de Dificuldade <Attention /></Form.Label>
                            <select className="form-select " onChange={(e) => {
                                let model_ = { ...model_modal };
                                model_.difficulty_level = e.target.value;
                                dispatch(setState({ model_modal: model_ }));
                            }} value={model_modal.difficulty_level}>
                                <option
                                    value={''}
                                >{'Selecione um nível de dificuldade'}</option>

                                {difficulty_levels.map((item: any, id: number) => (
                                    <option
                                        key={id}
                                        value={item}
                                    >{item}</option>
                                ))
                                }

                            </select>
                        </div>
                    </InputGroup>


                    {configs_array.current?.length >= 1 && configs_array.current[0] != undefined && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Enunciado <Attention /> {loadings_images_modal[0] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_question}
                                value={question_modal}
                                config={configs_array.current[0]}
                                // height={400}
                                // tabIndex={1} // tabIndex of textarea
                                onChange={newContent => {
                                    // let model_: any = { ...model };
                                    // model_.question = newContent;
                                    // dispatch(setModel(model_));
                                    dispatch(setState({ question_modal: newContent }));

                                }}
                                onBlur={newContent => {
                                    // let model_: any = { ...model };
                                    // model_.question = newContent;
                                    // dispatch(setModel(model_));
                                    dispatch(setState({ question_modal: newContent }));

                                }} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                        </div>
                    </InputGroup>}

                    <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Número de alternativas <Attention /></Form.Label>
                            <select className="form-select " onChange={(e) => {
                                let model_: any = { ...model_modal };
                                model_.correct = '';
                                model_.number_alternative = e.target.value;
                                model_.alternatives = model_.alternatives.filter((item: any, id: any) => id + 1 <= parseInt(e.target.value))
                                for (let i: number = model_.alternatives.length; i < parseInt(e.target.value); i++) {
                                    model_.alternatives = [...model_.alternatives, { alternative: '', alternative_content: '', correct: false }];
                                }
                                dispatch(setState({ model_modal: model_ }));
                                // this.setState({ numero_alternativa: e.target.value, correta: '' })
                            }} value={model_modal.number_alternative}>

                                {number_alternatives.map((item, id) => (
                                    <option
                                        key={id}
                                        value={id + 1}
                                    >{id + 1}</option>
                                ))
                                }

                            </select>
                        </div>
                    </InputGroup>

                    {configs_array.current?.length >= 2 && configs_array.current[1] != undefined && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Alternativa a) <Attention /> {loadings_images_modal[1] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_alternative_a}
                                value={alternativa_a_modal ?? ''}
                                config={configs_array.current[1]}
                                onChange={newContent => {
                                    // this.setState({ alternativa_a: newContent })

                                    dispatch(setState({ alternativa_a_modal: newContent }));
                                }}
                                onBlur={newContent => {
                                    // this.setState({ alternativa_a: newContent })

                                    dispatch(setState({ alternativa_a_modal: newContent }));
                                }} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                        </div>
                    </InputGroup>}

                    {configs_array.current?.length >= 3 && configs_array.current[2] != undefined && model_modal.number_alternative >= 2 && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Alternativa b) <Attention /> {loadings_images_modal[2] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_alternative_b}
                                value={alternativa_b_modal ?? ''}
                                config={configs_array.current[2]}
                                onChange={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_b_modal: newContent }));

                                }}
                                onBlur={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_b_modal: newContent }));

                                }}
                            />
                        </div>
                    </InputGroup>}

                    {configs_array.current?.length >= 4 && configs_array.current[3] != undefined && model_modal.number_alternative >= 3 && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Alternativa c) <Attention /> {loadings_images_modal[3] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_alternative_c}
                                value={alternativa_c_modal ?? ''}
                                config={configs_array.current[3]}
                                onChange={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_c_modal: newContent }));

                                }}
                                onBlur={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_c_modal: newContent }));

                                }}
                            />
                        </div>
                    </InputGroup>}

                    {configs_array.current?.length >= 5 && configs_array.current[4] != undefined && model_modal.number_alternative >= 4 && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Alternativa d) <Attention /> {loadings_images_modal[4] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_alternative_d}
                                value={alternativa_d_modal ?? ''}
                                config={configs_array.current[4]}
                                onChange={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_d_modal: newContent }));

                                }}
                                onBlur={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_d_modal: newContent }));

                                }}
                            />
                        </div>
                    </InputGroup>}

                    {configs_array.current?.length >= 5 && configs_array.current[5] != undefined && model_modal.number_alternative >= 5 && <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label >Alternativa e) <Attention /> {loadings_images_modal[5] == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                            </div>}</Form.Label>
                            <JoditEditor
                                ref={editor_alternative_e}
                                value={alternativa_e_modal ?? ''}
                                config={configs_array.current[5]}
                                onChange={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_e_modal: newContent }));

                                }}
                                onBlur={newContent => {
                                    // this.setState({ alternativa_a: newContent })
                                    dispatch(setState({ alternativa_e_modal: newContent }));

                                }}
                            />
                        </div>
                    </InputGroup>}

                    <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Qual a alternativa correta? <Attention /></Form.Label>
                            <select className="form-select " onChange={(e) => {
                                let model_: any = { ...model_modal };
                                model_.correct = e.target.value;
                                dispatch(setState({ model_modal: model_ }));
                            }} value={model_modal.correct}>
                                <option value="">Selecione uma anternativa correta</option>
                                {(corretas && corretas.length != 0) && corretas.map((value, index) => (
                                    index <= model_modal.number_alternative - 1 && <option
                                        key={index}
                                        value={value["id"]}
                                    >{value["name"]}</option>
                                ))}
                            </select>
                        </div>
                    </InputGroup>

                    <InputGroup>

                        <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Justificativa explicando a resposta <Attention /></Form.Label>
                            <Input
                                placeholder="Justificativa explicando a resposta da questão"
                                value={model_modal.justify_answer_text}
                                onChange={(e) => {
                                    let model_ = { ...model_modal };
                                    model_.justify_answer_text = e.target.value;
                                    dispatch(setState({ model_modal: model_ }));
                                }}
                                as="textarea"
                            />
                        </div>
                    </InputGroup>
{/* 
                    <InputGroup>
                        <div style={{ width: '100%' }}>

                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Arquivos anexados com justificativa <Attention /></Form.Label>
                                <Input
                                    placeholder="Justificativa explicando a resposta da questão"
                                    value={file_select_modal}
                                    multiple
                                    // accept='.pdf,.doc,.docx,image/*'
                                    type="file"
                                    onChange={(e: any) => {
                                        let event_target: any = e.target;
                                        let model_: any = { ...model_modal };
                                        model_.justify_answer_files = [...model_.justify_answer_files, ...event_target.files];
                                        console.log(model_);

                                        dispatch(setState({ model_modal: model_ }));
                                    }}
                                />
                            </div>
                            {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                                {model_modal.justify_answer_files.map((item: any, id: any) => (
                                    <div key={id} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                                        <p style={{ marginBottom: 0, textAlign: 'center' }}>{item.name}</p>
                                        <Close onClick={() => {
                                            let model_ = { ...model_modal };
                                            model_.justify_answer_files = [...model_modal.justify_answer_files].filter((item2: any, id2: any) => id2 != id);
                                            dispatch(setState({ model_modal: model_ }));
                                        }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />
                                    </div>
                                ))}
                            </div>}
                        </div>

                    </InputGroup>
 */}

                </div>

            </Modal.Body>

            <Modal.Footer>
                <div className="w100 inline-flex justify-content-end margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}

                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        onClick={(e) => {
                            let model_ = {...model_modal};

                            if (model_.degree.trim().length == 0) {
                                dispatch(setState({error_message_modal: 'A Nível de Escolaridade é obrigatória'}))
                                document.getElementsByClassName('modal-dialog')[0]?.parentElement?.scrollTo(0, 0);
                                return undefined;
                            }

                            if (model_.difficulty_level.trim().length == 0) {
                                dispatch(setState({error_message_modal: 'A Nível de Dificuldade é obrigatória'}))
                                document.getElementsByClassName('modal-dialog')[0]?.parentElement?.scrollTo(0, 0);
                                return undefined;
                            }

                            document.getElementById('text_enunciado')!.innerHTML = question_modal;
                            model_.alternatives = [...model_.alternatives];
                            if (document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !question_modal.includes('<img')) {
                                dispatch(setState({error_message_modal:'O campo Enunciado é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }

                            model_.question=question_modal;
                            model_.question_content=document.getElementById('text_enunciado')!.textContent ?? '';


                            document.getElementById('text_enunciado')!.innerHTML = alternativa_a_modal;

                            if (model_modal.number_alternative >= 1 && document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !alternativa_a_modal.includes('<img')) {
                                dispatch(setState({error_message_modal: 'O campo Alternativa a) é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }
                            else if (model_modal.number_alternative >= 1) {
                                model_.alternatives[0] = { ...model_.alternatives[0] };
                                model_.alternatives[0].alternative = alternativa_a_modal;
                                model_.alternatives[0].alternative_content = document.getElementById('text_enunciado')!.textContent ?? '';
                                model_.alternatives[0].correct = model_.correct == 'a';
                            }

                            document.getElementById('text_enunciado')!.innerHTML = alternativa_b_modal;

                            if (model_modal.number_alternative >= 2 && document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !alternativa_b_modal.includes('<img')) {
                                dispatch(setState({error_message_modal: 'O campo Alternativa b) é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }
                            else if (model_modal.number_alternative >= 2) {
                                model_.alternatives[1] = { ...model_.alternatives[1] };
                                model_.alternatives[1].alternative = alternativa_b_modal;
                                model_.alternatives[1].alternative_content = document.getElementById('text_enunciado')!.textContent ?? '';
                                model_.alternatives[1].correct = model_.correct == 'b';
                            }

                            document.getElementById('text_enunciado')!.innerHTML = alternativa_c_modal;

                            if (model_modal.number_alternative >= 3 && document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !alternativa_c_modal.includes('<img')) {
                                dispatch(setState({error_message_modal: 'O campo Alternativa c) é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }
                            else if (model_modal.number_alternative >= 3) {
                                model_.alternatives[2] = { ...model_.alternatives[2] };
                                model_.alternatives[2].alternative = alternativa_c_modal;
                                model_.alternatives[2].alternative_content = document.getElementById('text_enunciado')!.textContent ?? '';
                                model_.alternatives[2].correct = model_.correct == 'c';
                            }


                            document.getElementById('text_enunciado')!.innerHTML = alternativa_d_modal;

                            if (model_modal.number_alternative >= 4 && document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !alternativa_d_modal.includes('<img')) {
                                dispatch(setState({error_message_modal: 'O campo Alternativa d) é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }
                            else if (model_modal.number_alternative >= 4) {
                                model_.alternatives[3] = { ...model_.alternatives[3] };
                                model_.alternatives[3].alternative = alternativa_d_modal;
                                model_.alternatives[3].alternative_content = document.getElementById('text_enunciado')!.textContent ?? '';
                                model_.alternatives[3].correct = model_.correct == 'd';
                            }

                            document.getElementById('text_enunciado')!.innerHTML = alternativa_e_modal;

                            if (model_modal.number_alternative >= 5 && document.getElementById('text_enunciado')!.textContent!.trim()!.length == 0 && !alternativa_e_modal.includes('<img')) {
                                dispatch(setState({error_message_modal: 'O campo Alternativa e) é obrigatório'}))
                                document.getElementsByClassName('modal-dialog')[0]!.parentElement!.scrollTo(0, 0);
                                return undefined;
                            }
                            else if (model_modal.number_alternative >= 5) {
                                model_.alternatives[4] = { ...model_.alternatives[4] };
                                model_.alternatives[4].alternative = alternativa_a_modal;
                                model_.alternatives[4].alternative_content = document.getElementById('text_enunciado')!.textContent ?? '';
                                model_.alternatives[4].correct = model_.correct == 'e';
                            }

                            if (model_.correct == '') {
                                dispatch(setState({error_message_modal: 'Selecione uma alternativa correta'}))
                                document.getElementsByClassName('modal-dialog')[0]?.parentElement?.scrollTo(0, 0);
                                return undefined;
                            }

                            if (model_.justify_answer_text.trim().length == 0) {
                                dispatch(setState({error_message_modal: 'A justificativa da questão é obrigatória'}))
                                document.getElementsByClassName('modal-dialog')[0]?.parentElement?.scrollTo(0, 0);
                                return undefined;
                            }


                            let model_oficial:any = {...model};
                            model_oficial.questions=[...model_oficial.questions,{...model_}];
                            dispatch(setModel(model_oficial))
                            dispatch(clearStateModal());
                            dispatch(setState({show_modal_register:false}))

                        }}
                        bg="confirm"
                        text="Salvar questão"
                        disabled={loadings_images_modal.includes(true)}
                        loadingtext="Salvando questão"
                        loading={loading_save}
                    />
                </div>
            </Modal.Footer>
        </Modal>

    );
}
