export enum CorrectionStatus {
  CORRECTED = "Corrected",
  PENDING = "Pending",
  WAITING_RELEASE = "Waiting Release",
  CANCELLED = "Cancelled",
  ALREADY_CORRECTED = "Already Corrected",
}

export const getCorrectionStatus = (status: any) => {
  switch (status) {
    case CorrectionStatus.CORRECTED:
      return "Corrigida";
    case CorrectionStatus.PENDING:
      return "Pendente Correção";
    case CorrectionStatus.WAITING_RELEASE:
      return "Agardando liberação";
    case CorrectionStatus.CANCELLED:
      return "Cancelada";
    case CorrectionStatus.ALREADY_CORRECTED:
      return "Já corrijida";
  }
};
