import moment from "moment";
import { Course } from "./Course";
import { Unit } from "./Unit";

export const Class = {
  _id: "",
  name: "",
  period: "",
  description: "",
  from: moment(),
  to: moment(),
  active: true,
  unit: Unit,
  course: Course,
};
