import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setName,
  setEmail,
  setPassword,
  setConfirmPassword,
  setErrorMessage,
  setPage,
  setOrganization,
  setClass,
  setAddClass,
} = modelSlice.actions;
