import moment from "moment";
import { Demand } from "../../../model/Demand";
import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Assignment } from "../../../model/Assignment";
import { Question } from "../../../model/Question";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  activeTab: 0,
  parent: Assignment,
  model: Question,
};
