import { useEffect, useState } from "react";

import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { Tab, Tabs } from "@material-ui/core";
import "moment/locale/pt-br";

import {
  fetchClassListThunk,
  fetchIdThunk,
  fetchStudentListThunk,
  saveThunk,
  saveAllThunk,
  updateThunk,
} from "../../../services/stores/registrationStore/slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearWindowState,
  clearErrorState,
  setErrorMessage,
  setClass,
  setStudent,
  setActive,
  setClass2,
} from "../../../services/stores/registrationStore/actions";
import AsyncSelect from "react-select/async";
import { SaveRounded } from "@material-ui/icons";

var debounce = require("lodash.debounce");

export function RegistrationForm(props: { editMode?: boolean }) {
  const [items, setItems] = useState<any[]>([]);
  const [listDisabled, setListDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.registrations.screen
  );
  const { active } = useAppSelector((store) => store.registrations.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const selectedClass = useAppSelector(
    (store) => store.registrations.model.class
  );
  const selectedClass2 = useAppSelector(
    (store) => store.registrations.model.class2
  );
  const selectedStudent = useAppSelector(
    (store) => store.registrations.model.student
  );

  const searchClasses = (query: any, callback: any) => {
    dispatch(fetchClassListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchClasses2 = (query: any, callback: any) => {
    dispatch(fetchClassListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const searchStudent = (query: any, callback: any) => {
    dispatch(fetchStudentListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const saveAndContinue = () => {
    dispatch(saveThunk()).then((result) => {
      if (result.type.includes("fulfilled")) {
        const item: any = {
          name: selectedStudent.name,
          email: selectedStudent.email,
        };
        const temp: any[] = [...items, item];
        setItems(temp);
        setListDisabled(true);
        dispatch(clearErrorState());
      }
    });
  };
  const saveAllAndContinue = () => {
    dispatch(saveAllThunk()).then((result) => {
      if (result.type.includes("fulfilled")) {
        history(-1);

        // const item: any = {
        // name: selectedStudent.name,
        // email: selectedStudent.email,
        // };
        // const temp: any[] = [...items, item];
        // setItems(temp);
        // setListDisabled(true);
        // dispatch(clearErrorState());
      }
    });
  };
  const saveContent = () => {
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/registrations");
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/registrations");
      });
    }
  };

  useEffect(() => {
    if (props.editMode && params.id) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Matrícula
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma turma do seu sistema"
                : "Cadastre as matrículas do seu sistema"}
            </p>
            <br />
            <Tabs
              value={activeTab}
              onChange={(_: any, value: any) => {
                setActiveTab(value);
              }}
              style={{ backgroundColor: "#ececf1" }}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
            >
              <Tab label="Por Aluno" />
              <Tab label="Migração de Turmas" />
            </Tabs>
            {/* <hr /> */}

            {loadingScreen == false && activeTab == 1 && (
              <div style={{ marginTop: 10 }}>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Turma Origem <Attention />
                        </Form.Label>
                        <AsyncSelect
                          isDisabled={listDisabled}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome da turma..."
                          onChange={(e: any) => {
                            dispatch(setClass(e));
                          }}
                          cacheOptions
                          value={selectedClass}
                          loadOptions={debounce(searchClasses, 250)}
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Turma Destino <Attention />
                        </Form.Label>
                        <AsyncSelect
                          isDisabled={listDisabled}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome da turma..."
                          onChange={(e: any) => {
                            dispatch(setClass2(e));
                          }}
                          cacheOptions
                          value={selectedClass2}
                          loadOptions={debounce(searchClasses2, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  {loadingSave == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/registrations")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>

                      <div className="inline-flex jcsb ">
                        <Button
                          style={{ marginRight: 10 }}
                          variant="success"
                          type="button"
                          onClick={() => {
                            saveAllAndContinue();
                          }}
                          disabled={
                            loadingSave ||
                            selectedClass?._id.length <= 0 ||
                            selectedClass2?._id.length <= 0
                          }
                          className="flex align-right jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} />
                          Carregar todos da turma
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen == false && activeTab == 0 && (
              <div style={{ marginTop: 10 }}>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Turma <Attention />
                        </Form.Label>
                        <AsyncSelect
                          isDisabled={listDisabled}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome da turma..."
                          onChange={(e: any) => {
                            dispatch(setClass(e));
                          }}
                          cacheOptions
                          value={selectedClass}
                          loadOptions={debounce(searchClasses, 250)}
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Aluno <Attention />
                        </Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do aluno..."
                          onChange={(e: any) => {
                            dispatch(setStudent(e));
                          }}
                          cacheOptions
                          value={selectedStudent}
                          loadOptions={debounce(searchStudent, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <Form.Check
                    type={"checkbox"}
                    className="mb-4"
                    label={"Matrícula Ativa"}
                    checked={active}
                    onChange={(e) => {
                      dispatch(setActive(null));
                    }}
                  />

                  {items.length > 0 && (
                    <div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0 "
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table table-striped"
                          >
                            <thead>
                              <tr>
                                <td>Nome</td>
                                <td>Email</td>
                              </tr>
                            </thead>
                            <tbody>
                              {items?.map((item: any, id: any) => (
                                <tr key={id}>
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {loadingSave == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/registrations"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/registrations")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>

                      <div className="inline-flex jcsb ">
                        <Button
                          style={{ marginRight: 10 }}
                          variant="success"
                          type="button"
                          onClick={() => {
                            saveAndContinue();
                          }}
                          disabled={loadingSave}
                          className="flex align-right jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar e
                          Continuar
                        </Button>
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingSave}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
