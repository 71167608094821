import { User } from "./User";

export const Log = {
  _id: "",
  log: "",
  status: "",
  key: "",
  operation: "",
  user:User
};
