import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthAPI } from "../../../apis/AuthAPI";
import { initialState } from "../initialState";
import { handleThunkRejection } from "../../utils";

export const changePasswordFirstLogin = createAsyncThunk(
  "auth/change-password-first-login",
  async (params: any = null, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    var response = { me: null };
    try {
      const responseMe = await AuthAPI.changePasswordFirstLogin(
        token,
        params.password
      );
      response = { me: responseMe };
    } catch (e: any) {
      console.log(`Error changePasswordFirstLogin::createAsyncThunk -----------`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addChangePasswordFirstLoginReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(changePasswordFirstLogin.pending, (state, _action) => {
      state.errorMessage = null;
      state.loading = true;
    })
    .addCase(changePasswordFirstLogin.fulfilled, (state, action) => {
        localStorage.removeItem("token");
        state = { ...initialState };
        return { ...state, logged: false, errorMessage: "" };
    })
    .addCase(changePasswordFirstLogin.rejected, (state, action: any) => {
      const error = action.payload;
      state.errors = error.message;
      state.loading = false;
    });
};
