import { useEffect, useState } from "react";
// unidades
import { fetchList } from "../../../services/stores/testStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setId,
  setWarning,
  setSearch,
} from "../../../services/stores/testStore/actions";
import { deleteItem } from "../../../services/stores/testStore/async/deleteThunk";
//
import { API_URI } from "../../../constants/consts";
import moment from "moment";
//
export function ExamsApplicationView() {
  const exams = useAppSelector((store) => store.tests.data);
  const { search, loading_screen } = useAppSelector((store) => store.tests);

  const show_warning = useAppSelector((store) => store.tests.show_warning);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [loading_screen, setLoadingScreen] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  const { token } = useAppSelector((store) => store.auth);

  console.log("exams", exams);

  useEffect(() => {
    dispatch(fetchList({ force: true })).then((result) => {
      console.log("result", result);
    });
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        // showAuxiliar1
        // onAuxiliar1ActionClick={(id) => {
        //   let anchor = document.createElement("a");
        //   document.body.appendChild(anchor);
        //   let file = `${API_URI}/layouts_exams/${id}/download-csv`;

        //   let headers = new Headers();
        //   headers.append("Authorization", `${token}`);
        //   fetch(file, { headers })
        //     .then((response) => response.blob())
        //     .then((blobby) => {
        //       let objectUrl = window.URL.createObjectURL(blobby);

        //       anchor.href = objectUrl;
        //       anchor.download = `${id}.csv`;
        //       anchor.click();
        //       window.URL.revokeObjectURL(objectUrl);
        //     });
        // }}
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Aplicações"}
        pronoun="a"
        onManagerActionClick={(id: any) => {
          navigate("/tests/view/" + id);
        }}
        not_found_message={"Nenhuma aplicaçào encontrada"}
        button_text="Adicionar aplicação"
        button_link="/tests/create"
        search_text="Pesquise pelo nome da aplicação"
        deleting_message={
          "Ao deletar esta aplicação, ela não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true }));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/tests/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={exams.map((item:any)=>{
          let exam:any={...item};
          exam.init = moment(item.init_test).format('YYYY-MM-DD')+' '+moment(item.time_init_test).format('HH:mm:ss');
          exam.end = moment(item.end_test).format('YYYY-MM-DD')+' '+moment(item.time_end_test).format('HH:mm:ss');
          exam.status = exam.init>moment().format('YYYY-MM-DD HH:mm:ss')?'Não iniciada':(exam.end>moment().format('YYYY-MM-DD HH:mm:ss')?'Em Andamento':'Finalizada');
          return exam;
        })}
        pagination={{ last_page: last_page, current_page: current_page }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "init", type: "date", header: "Início" },
          { name: "end", type: "date", header: "Encerramento" },
          { name: "status", type: "text", header: "Status" },

        ]}
      ></List>
    </div>
  );
}
