import { useEffect, useState } from "react";

import { STORAGE_URL } from "../../constants/consts";
import { Col, Container, Row } from "react-bootstrap";
// classes
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl,
  FormControl as Input,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../component/base/DefaultButton";
import {
  fetchIdThunk,
  updateThunk,
} from "../../services/stores/correctionStore/slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearWindowState,
  setAnnotationEdit,
  setAnnotations,
  setComment,
  setErrorMessage,
  setNewAnnotation,
  setReply,
  setScore,
  setShowModalAspect,
} from "../../services/stores/correctionStore/actions";
import DrawAnnotations from "./DrawAnotations";
import ModalErrors from "./ModalErrors";

export function CorrectionForm(props: { editMode?: boolean }) {
  const { errorMessages, loadingSave, loadingScreen, annotation_edit, new_anotation, show_modal_aspect } = useAppSelector(
    (store) => store.corrections.screen
  );
  const correction = useAppSelector((store) => store.corrections.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const [editReply, setEditReply] = useState(false);

  const saveContent = () => {
    dispatch(updateThunk()).then((result) => {
      if (result.type.includes("fulfilled")) history(-1);
    });
  };

  useEffect(() => {
    if (props.editMode && params.id) {
      dispatch(fetchIdThunk({ id: params.id, force: true })).then(
        (result: any) => {
          if (result.type.includes("fulfilled")) {
            if (result.payload.reply == undefined || !result.payload.reply) {
              setEditReply(true);
            }
          }
        }
      );
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  console.log(show_modal_aspect)
  console.log(correction.annotations)
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Correção
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Realize a correção da questão
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen == false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error, id) => (
                        <p key={id} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Questão</Form.Label>
                      <div
                        style={{ fontSize: 12, color: "black" }}
                        dangerouslySetInnerHTML={{
                          __html: correction.answer.question.question,
                        }}
                      ></div>
                    </div>
                  </InputGroup>
                  <InputGroup style={{overflowX:'scroll',overflowY:'hidden'}}>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Resposta</Form.Label>
                      {correction.answer.question.type == "text" && (
                        <Row style={{display:'flex',justifyContent:'center',alignItems:'center',margin:0}}>
                          <DrawAnnotations
                            height={400}
                            width={900}
                            annotation_edit={annotation_edit}
                            showEditAnnotation={(e: any) => {
                              let correction_: any = { ...correction }
                              correction_.annotations = [...correction_.annotations]
                              let annotation_edit = { ...correction_.annotations[e] };
                              dispatch(setAnnotationEdit(annotation_edit));
                              dispatch(setShowModalAspect(true));
                              // this.setState({ show_modal_aspect: true, annotation_edit: annotation_edit });
                            }}
                            annotations={correction.annotations} MouseEnter={(id: any, value: any) => {
                              let annotations: any = [...correction.annotations];
                              annotations[id] = { ...annotations[id] };
                              annotations[id].mouse_inside = value;
                              dispatch(setAnnotations(annotations));

                            }} new_anotation={new_anotation} newAnnotation={(e: any) => {
                              //  this.new_anotation=e;
                              console.log(e);
                              dispatch(setNewAnnotation(e));
                              dispatch(setShowModalAspect(true));
                              // this.setState({ show_modal_aspect: true, new_anotation: e });
                            }} />
                          <img
                            style={{ height: 350, minWidth: 900,maxWidth:900 }}
                            src={
                              STORAGE_URL +
                              "/" +
                              correction.answer.dissertative_answer
                            }
                          ></img>
                        </Row>
                      )}
                      <ModalErrors
                        removeAnotation={(annotation:any) => {
                          let correction_: any = { ...correction }
                          correction_.annotations = [...correction_.annotations].filter((item, id) => item.id != annotation.id);
                          dispatch(setAnnotations(correction_.annotations));

                          // let annotations = this.state.annotations.filter((item, id) => item.id != annotation.id);
                          // this.setState({ annotations }, () => {
                          //   this.tamanho_annotation();
                          // });
                        }}
                        annotation_edit={annotation_edit} show_modal_aspect={show_modal_aspect} setState={(e:any) => {
                          dispatch(setShowModalAspect(e.show_modal_aspect));
                          dispatch(setAnnotationEdit(null));
                          dispatch(setNewAnnotation(null));
                        }} onSave={(data:any) => {
                          let correction_: any = { ...correction }
                          let annotations:any = [...correction_.annotations];

                          let label = '';
                          data.aspectos.map((item:any, id:any) => {
                            if (item.value == true) {
                              console.log(item.sigla);
                              if (id == 0) {
                                label = item.sigla;
                              }
                              else {
                                label += ', ' + item.sigla;
                              }
                            }
                          });
                          if (data.annotation_edit == null) {
                            let new_anot:any=new_anotation;
                            let anot:any = {...new_anot};
                            anot.data = data;
                            anot.data.label = label;
                            annotations.push(anot);
                          }
                          else {
                            console.log(data);
                            annotations = [...annotations.map((item2:any, id:any) => {
                              let item:any = {...item2};
                              if (item.id == data.annotation_edit.id) {
                                let item3:any = {...data.annotation_edit};
                                item3.data = {...item3.data};
                                item3.data.aspectos = [...data.aspectos];
                                item3.data.comentario = data.comentario;
                                item3.data.label = data.label;
                                console.log(item3)
                                return item3;
                              }
                              else {
                                return item;
                              }
                            })];
                            console.log(annotations)
                          }
                          dispatch(setAnnotations(annotations))
                          dispatch(setNewAnnotation(null));
                          dispatch(setAnnotationEdit(null));
                          // this.setState({ annotations, new_anotation: null, annotation_edit: null }, () => {
                          //   this.tamanho_annotation();
                          // });
                        }} />
                      {correction.answer.question.type == "objective" &&
                        correction.answer.question.alternatives.map(
                          (alternative: any, i) => (
                            <Container>
                              <Row
                                style={{
                                  color: alternative.correct
                                    ? "green"
                                    : alternative._id ==
                                      correction.answer?.objective_answer
                                    ? "red"
                                    : "black",
                                }}
                              >
                                <Col md="auto">
                                  {String.fromCharCode(i + 97) + ")"}
                                </Col>
                                <Col width="auto">
                                  <div
                                    color="darkorchid"
                                    dangerouslySetInnerHTML={{
                                      __html: alternative.alternative,
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          )
                        )}
                      {correction.answer.question.type == "objective" && (
                        <Row>
                          <Col>
                            {correction.answer?.objective_answer && (
                                <p>
                                  O aluno respondeu:{" "}
                                  {"a)"}
                                  {correction.answer?.objective_answer?.alternative_content}
                                </p>
                              )}
                            {!correction.answer?.objective_answer && (
                              <p>
                                O aluno deixou a questão em branco.
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Justificativa</Form.Label>
                      <Input
                        disabled={true}
                        as="textarea"
                        placeholder="Comente a resposta do aluno"
                        value={correction.answer.question.justify_answer_text}
                        onChange={(e) => dispatch(setComment(e.target.value))}
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Comentário <Attention />
                      </Form.Label>
                      <Input
                        as="textarea"
                        placeholder="Comente a resposta do aluno"
                        value={correction.comment}
                        onChange={(e) => dispatch(setComment(e.target.value))}
                      />
                    </div>
                  </InputGroup>
                  {correction.answer.question.type == "text" && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nota (0-100) <Attention />
                        </Form.Label>
                        <FormControl
                          pattern="[0-9]*"
                          placeholder="Nota"
                          type="number"
                          value={correction.score}
                          onChange={(e) => dispatch(setScore(e.target.value))}
                        // required
                        // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  )}
                  {correction.claim && (
                    <div>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>Questionamento do Aluno</Form.Label>
                          <FormControl
                            as="textarea"
                            disabled={true}
                            value={correction.claim}
                          // required
                          // title="Coloque o nome completo do usuário"
                          />
                        </div>
                      </InputGroup>
                      <InputGroup>
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>Resposta do Professor</Form.Label>
                          <FormControl
                            as="textarea"
                            disabled={!editReply}
                            value={correction.reply}
                            // required
                            // title="Coloque o nome completo do usuário"
                            onChange={(e) => dispatch(setReply(e.target.value))}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  )}
                  {loadingSave == false && (
                    <div className="w100 inline-flex jcsb margin-top-1">
                      <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        onClick={() => {
                          history(-1);
                          return undefined;
                        }}
                      />
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar Correção"
                        disabled={loadingSave}
                        loadingtext="Salvando correção"
                        loading={loadingSave}
                      />
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
