export default function getTeacherDashboard({ from, to, dispatch, addWidget, fetchId }) {
  dispatch(addWidget({ id: "professor-provas-fora-do-prazo-de-correcao" }));
  dispatch(addWidget({ id: "professor-provas-que-ainda-nao-foram-corrigidas" }));
  dispatch(addWidget({ id: "professor-media-de-nota-das-provas-do-professor" }));
  dispatch(addWidget({ id: "professor-alunos-que-ainda-nao-tiveram-resposta-dos-seus-questionamentos" }));
  dispatch(addWidget({ id: "professor-demandas-a-serem-atendidas" }));

  dispatch(
    fetchId({
      force: true,
      id: "professor-provas-fora-do-prazo-de-correcao",
      from,
      to,
    })
  );

    dispatch(
    fetchId({
      force: true,
      id: "totais-da-instituicao",
      from,
      to,
    })
  );

  dispatch(
    fetchId({
      force: true,
      id: "professor-provas-que-ainda-nao-foram-corrigidas",
      from,
      to,
    })
  );

  dispatch(
    fetchId({
      force: true,
      id: "professor-media-de-nota-das-provas-do-professor",
      from,
      to,
    })
  );

  dispatch(
    fetchId({
      force: true,
      id: "professor-alunos-que-ainda-nao-tiveram-resposta-dos-seus-questionamentos",
      from,
      to,
    })
  );

  dispatch(
    fetchId({
      force: true,
      id: "professor-demandas-a-serem-atendidas",
      from,
      to,
    })
  );
}