import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { UsersAPI } from "../../../apis/UsersAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { CoursesAPI } from "../../../apis/CoursesAPI";

export const fetchStudent = createAsyncThunk(
  "layouts/fetch-students",
  async (params: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    // if (
    //   !params.force &&
    //   state.demandsManager.teachers != null &&
    //   state.demandsManager.teachers.length > 0
    // )
    //   return state.demandsManager.teachers;
    try {
      return await UsersAPI.getAllStudentsFiltered(
        token,
        'class='+params.class_id,
        false
      );
    } catch (e: any) {
      console.log(
        `Error fetchTeachers::createAsyncThunk ${e.status} ${e.message}`
      );

      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchStudent = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(
      fetchStudent.pending,
      (state: { loading_student: boolean }, _action: any) => {
        state.loading_student = true;
      }
    )
    .addCase(fetchStudent.fulfilled, (state, action) => {
      let students:any={...state.students};
      action.payload.map((item:any,id:any)=>{
        if(item.student!=null)
          students[item._id]=item;
      })
      state.students = students;
      state.loading_student=false;
    })
    .addCase(fetchStudent.rejected, (state: any, action: any) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: action.payload?.message,
          loading_student: false,
        },
      };
    });
};
