import { createSlice } from "@reduxjs/toolkit";
import { modelInitialState } from "./initialState";
import { DashboardResponse } from "../../../model/app/DashboardResponse";
import { addFetchIdReducers } from "./fetchIdThunk";

export const modelSlice = createSlice({
  name: "dashboard",
  initialState: modelInitialState,
  reducers: {
    addWidget: (state, action) => {
      const widget: DashboardResponse = action.payload;
      widget.loading = true;
      return {
        ...state,
        widgetCount: state.widgetCount + 1,
        widgets: [ ...state.widgets, widget ],
      };
    },
    removeWidget: (state, action) => {
      const idx = state.widgets.findIndex((x) => x.id == action.payload);
      if (idx > 0) {
        const res = [...state.widgets];
        res.splice(idx, 1);
        return {
          ...state,
          widgetCount: state.widgetCount - 1,
          widgets: state.widgets.splice(idx, 1),
        };
      }
    },

    clearWindowState: (_) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchIdReducers(builder);
  },
});
