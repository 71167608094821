import { useEffect } from "react";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { List } from "../../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
} from "../../../services/stores/userAdminStore/actions";
import {
  deleteThunk,
  fetchListThunk,
  uploadFileThunk,
} from "../../../services/stores/userAdminStore/slice";
import Swal from "sweetalert2";

export function UsersAdminView() {
  const users = useAppSelector((store) => store.userAdmin.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.userAdmin.screen
  );

  const { pagination } = useAppSelector((store) => store.userAdmin);
  const { search } = useAppSelector((store) => store.userAdmin.list);

  const onImportClick = async (e: any) => {
    const { value: file } = await Swal.fire({
      showCancelButton: true,
      imageUrl: "import_admin.png",
      title: "Selecione um arquivo no formato CSV",
      text: "Arquivos com extensão CSV (separados por vírgula) com as colunas nome, email e password no formato acima.",
      input: "file",
      confirmButtonText: "Importar",
      cancelButtonText: "Cancelar",
      customClass: {
        title: "sweet-alert-title",
        confirmButton: "sweet-alert-confirm-button",
        cancelButton: "sweet-alert-cancel-button",
      },
      inputAttributes: {
        accept: "*.csv",
        "aria-label": "Procure a lista de administradores",
      },
    });
    if (file) {
      dispatch(uploadFileThunk({ data: file, schema: "admin" })).then(
        (result) => {
          if (result.type.includes("fulfilled")) {
            Swal.fire({
              icon: "success",
              title: `Foram processados ${result.payload.data} registro(s). Veja o resultado no menu Logs.`,
              customClass: {
                title: "sweet-alert-title",
                confirmButton: "sweet-alert-confirm-button",
                cancelButton: "sweet-alert-cancel-button",
              },
            });
            dispatch(fetchListThunk({ force: true })).then((result) => {});
          }
        }
      );
    }
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchListThunk({ force: true })).then((result) => {});
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        hideManager
        // searchbox ---------------------------------------------
        search_input_value={search}
        search_text="Pesquise pelo nome ou email do administrador"
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchListThunk({ force: true }));
        }}
        // searchbox ---------------------------------------------
        additionalButtons={[
          { label: "Importar CSV", eventHandler: (e: any) => onImportClick(e) },
        ]}
        title={"Administradores"}
        not_found_message={"Nenhum administrador encontrado"}
        button_text="Adicionar administrador"
        button_link="/users-admin/create"
        deleting_message={
          "Ao deletar este administrador, ele não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/users-admin/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={users}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "email", type: "text", header: "Email" },
          { name: "createdAt", type: "date", header: "Criado em" },
        ]}
      ></List>
    </div>
  );
}
