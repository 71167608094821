import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addFetchListReducers } from "./async/fetchListThunk";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

export const modelSlice = createSlice({
  name: "teacher-demands",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setDescription: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      return { ...state, model: { ...state.model, keyword: action.payload } };
    },
    setKeywords: (state, action: PayloadAction<any>) => {
      return { ...state, model: { ...state.model, keywords: action.payload } };
    },
    setEndDate: (state, action: PayloadAction<any>) => {
      return { ...state, model: { ...state.model, endDate: action.payload } };
    },
    setEndTime: (state, action: PayloadAction<any>) => {
      return { ...state, model: { ...state.model, endTime: action.payload } };
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,

    clearWindowState: (state) => {
      return modelInitialState;
    },
  },

  extraReducers: (builder) => {
    addFetchListReducers(builder);
  },
});
