import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Question } from "../../../model/Question";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Question,
};
