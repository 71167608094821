import { useEffect, useState } from "react";

// unidades
import { fetchList } from "../../../services/stores/layoutExamStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setId,
  setWarning,
  setSearch,
} from "../../../services/stores/layoutExamStore/actions";
import { deleteItem } from "../../../services/stores/layoutExamStore/async/deleteThunk";


export function LayoutsExamsView({inTest,test_id}:any) {
  const layouts = useAppSelector((store) => store.layouts.data);
  const { search, loading_screen } = useAppSelector((store) => store.layouts);

  const show_warning = useAppSelector((store) => store.layouts.show_warning);
  const { token } = useAppSelector((store) => store.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [loading_screen, setLoadingScreen] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);
  console.log("layouts", layouts);
  useEffect(() => {
    dispatch(fetchList({ force: true,test_id })).then((result) => {
      console.log("result", result);
    });
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
      hide_margin={inTest}
        
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Diagramações"}
        pronoun="a"
        not_found_message={"Nenhuma diagramação encontrada"}
        button_text="Adicionar diagramação"
        button_link={"/layouts_exams/create/"+test_id}
        search_text="Pesquise pelo nome da diagramação"
        deleting_message={
          "Ao deletar esta diagramação, ela não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true }));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem({}));
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/layouts_exams/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          console.log(value);
        }}
        onManagerActionClick={(id) => {
          navigate("/layouts_exams/show/" + id);
        }}
        
        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={layouts}
        pagination={{ last_page: last_page, current_page: current_page }}
        fields={[
          { name: "codeExam", type: "text", header: "Código" },
          { name: "name", type: "text", header: "Nome" },
          { name: "exam.name", type: "text", header: "Prova" },
          { name: "owner.name", type: "text", header: "Cadastrado por" },
          { name: "created_at", type: "date", header: "Criado em" },
        ]}
      ></List>
    </div>
  );
}
