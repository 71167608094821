import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../../utils";
import { AnswersAPI } from "../../../apis/AnswersAPI";

export const deleteItem = createAsyncThunk(
  "student-exams/delete",
  async (_, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    try {
      return await AnswersAPI.delete(token, state.studentExams.list.selectedId);
    } catch (e: any) {
      console.log(
        `Error deleteItem::createAsyncThunk ${e.status} ${e.message}`
      );
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addDeleteItemReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(deleteItem.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(deleteItem.fulfilled, (state, action) => {
      var all = [...state.list.all];
      var id = all.findIndex((x) => x._id === action.payload._id);
      if (id >= 0) all.splice(id, 1);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingScreen: false,
        },
        list: {
          ...state.list,
          all: all,
        },
      };
    })
    .addCase(deleteItem.rejected, (state, action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
