
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionsAPI } from "../../../apis/QuestionsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchId = createAsyncThunk(
  "questions/get",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    console.log('Chamou api')
    try {
      return await QuestionsAPI.getById(
        token,
        state.questions.question_id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchId.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchId.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        state.loading_screen = false;
        state.model=action.payload;
        state.question = action.payload.question;
        if(action.payload.type=='objective'){
          state.alternativa_a = action.payload.alternatives[0].alternative;
          state.alternativa_b = action.payload.alternatives[1]?.alternative ?? '';
          state.alternativa_c = action.payload.alternatives[2]?.alternative ?? '';
          state.alternativa_d = action.payload.alternatives[3]?.alternative ?? '';
          state.alternativa_e = action.payload.alternatives[4]?.alternative ?? '';
        }
       

      }
    )
    .addCase(
      fetchId.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
