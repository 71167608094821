import { useEffect } from "react";
import AsyncSelect from "react-select/async";

// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { Button, Form, FormControl as Input, InputGroup } from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import {
  fetchIdThunk,
  fetchOrganizationListThunk,
  saveThunk,
  updateThunk,
} from "../../../services/stores/unitStore/slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  setName,
  setDescription,
  clearWindowState,
  setErrorMessage,
  setOrganization,
} from "../../../services/stores/unitStore/actions";
import { UserRole } from "../../../constants/enum/UserRole";
import { SaveRounded } from "@material-ui/icons";
const debounce = require("lodash.debounce");

export function UnitForm(props: { editMode?: boolean }) {
  const role = useAppSelector((store) => store.auth.me.role);

  const selectedOrganization = useAppSelector(
    (store) => store.units.model.organization
  );
  const searchOrganizations = (query: any, callback: any) => {
    dispatch(fetchOrganizationListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.docs);
      }
    );
  };

  const { errorMessages, loadingSave, loadingScreen } = useAppSelector(
    (store) => store.units.screen
  );
  const { name, description } = useAppSelector((store) => store.units.model);
  const dispatch = useAppDispatch();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/units");
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/units");
      });
    }
  };

  useEffect(() => {
    if (props.editMode && params.id) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Unidade
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma unidade do seu sistema"
                : "Cadastre as unidades do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen == false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da unidade"
                        value={name}
                        onChange={(e) => dispatch(setName(e.target.value))}
                        // required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição </Form.Label>
                      <Input
                        placeholder="Descrição da unidade"
                        value={description}
                        onChange={(e) =>
                          dispatch(setDescription(e.target.value))
                        }
                        as="textarea"
                      />
                    </div>
                  </InputGroup>

                  {role === UserRole.SUPER_USER && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Organização</Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do administrador..."
                          onChange={(e: any) => {
                            dispatch(setOrganization(e));
                          }}
                          cacheOptions
                          value={selectedOrganization}
                          loadOptions={debounce(searchOrganizations, 250)}
                          defaultOptions
                        />
                      </div>
                    </InputGroup>
                  )}

                  {loadingSave == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/units"
                      /> */}
                      <Button
                        variant="secondary"
                        onClick={() => history("/units")}
                        className="flex align-center jcc"
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar Unidade"
                        disabled={loadingSave}
                        loadingtext="Salvando unidade"
                        loading={loadingSave}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loadingSave}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
