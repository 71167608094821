
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { TestsAPI } from "../../../apis/TestsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";
import moment from "moment";

export const fetchIdRegistration = createAsyncThunk(
  "tests/registrations",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    try {
      return await TestsAPI.getByIdRegistration(
        token,
        state.tests.test_id,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchIdRegistrationReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchIdRegistration.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchIdRegistration.fulfilled,
      (state: any, action: { payload: any }) => {
        clearErrorState(state);
        console.log(state);
        console.log(action.payload)
        state.classes = [...action.payload.docs];

        let students:any={};
        action.payload.docs.map((item: any, id: any) => {
          if (item?.student != null)
            students[item._id] = item;
        })
        console.log(action.payload.docs)
        state.students = students;
        // state.model.name = action.payload.name;
        // state.model.description = action.payload.description;
        // state.model.type = action.payload.type;
        // state.model.init_test = moment(action.payload.init_test);
        // state.model.end_test = moment(action.payload.end_test);

        // state.model.time_init_test = moment(action.payload.time_init_test);
        // state.model.time_end_test = moment(action.payload.time_end_test);

        // state.model.end_test_correction = moment(action.payload.end_test_correction);
        // state.model.time_end_test_correction = moment(action.payload.time_end_test_correction);

        state.loading_screen = false;
      }
    )
    .addCase(
      fetchIdRegistration.rejected,
      (
        state: { data: any[]; error_code: any; loading_screen: boolean },
        action: { payload: any }
      ) => {
        state.data = [];
        const error = action.payload;
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
