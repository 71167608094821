import { useEffect } from "react";

// logs
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setSearch,
  setPage
} from "../../../services/stores/logStore/actions";
import { fetchListThunk } from "../../../services/stores/logStore/slice";
import moment from "moment";

export function LogView() {
  const logs = useAppSelector((store) => store.logs.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.logs.screen
  );
  const { pagination } = useAppSelector((store) => store.logs);
  const { search } = useAppSelector((store) => store.logs.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchListThunk({ force: true }));
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
        hideManager
        hideDelete
        hideEdit
        onChangeSearchInput={(value: string) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Logs de Execução"}
        not_found_message={"Nenhum log encontrado"}
        search_text="Pesquise o log"
        onSearchButtonClick={() => {
          dispatch(fetchListThunk({ force: true }));
        }}
        onChangePagination={function (page: number): void {
          dispatch(setPage(page));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={logs?.map((x: any) => {
          return {
            _id: x._id,
            createdAt:moment(x.createdAt).format('D/M/Y HH:mm:ss'),
            name: x.name,
            log: x.log,
            status: x.status,
            key: x.key,
            operation: x.operation,
            user: x.user.name
          };
        })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          
          { name: "createdAt", type: "text", header: "" },
          { name: "log", type: "text", header: "Log" },
          { name: "status", type: "text", header: "Status" },
          { name: "key", type: "text", header: "Chave" },
          { name: "operation", type: "text", header: "Operação" },
          { name: "user", type: "text", header: "Usuário" },
        ]}
      ></List>
    </div>
  );
}
